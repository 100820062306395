import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import type from './types';

const URL_TRANSPARENCY = `${BASE_API}/transparency`;

export const getDocumentsList = () => {
    return (dispatch) => {
        dispatch({ type: 'PRESTACAO_DE_CONTAS_LOAD', payload: true });

        axios.get(`${URL_TRANSPARENCY}`, {
            headers: {
                Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`
            },
        })
            .then((response) => {
                    dispatch({
                        type: 'PRESTACAO_DE_CONTAS_FETCHED',
                        payload: response.data,
                    });
                    console.log("payload:",response.data )
            })
            .catch((e) => {
                dispatch({ type: 'PRESTACAO_DE_CONTAS_LOAD', payload: false });
            });
    };
}


export const setLoading = (value) => {
    return (dispatch) => {
        dispatch({ type: type.LIST_LOAD, payload: value });
    }
}