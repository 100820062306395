import { Box, Grid } from '@material-ui/core';
import React from 'react';
import TextCard from '../../../../../common/components/cards/textCard';

export default function Health() {
  return (
    <section id="health">
      <p className="body-title mt-4">Odontologia / Psicologia</p>
      <p className="h4 mt-4" style={{ color: '#838383', maxWidth: 500 }}>
        Acesse os benefícios odontológicos e psicológicos ofertados aos filiados
        de todo o país.
      </p>

      <Grid container spacing={3} style={{ marginTop: 64 }}>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/saude/agendamento-odontologia">
              Agendamento de consulta na Clínica Odontolegis
            </TextCard>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/saude/agendamento-psicologia">
              Agendamento de consulta com psicólogo
            </TextCard>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/saude/agendamento-nutricionista">
              Agendamento de consulta com nutricionista
            </TextCard>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
}
