import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialModal from 'common/components/modal/materialModal';
import {
  BASE_S3,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
  LINK_TEXT_COLOR,
  NAV_FONT_FAMILY,
} from 'config/consts';
import moment from 'moment';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR } from '../../../config/consts';
const QRCode = require('qrcode.react');

const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: isMobile ? '100vh' : '95vh',
    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR
  },
}));

export default function VoucherModal({ open, setOpen, item, user }) {
  const classes = useStyles();

  function url(item, code) {
    if (item.action.station_id && item.action.partner_id) {
      if (process.env.REACT_APP_API_KEY === 'production') {
        return `https://parceiro.rilato.com.br/#/qr-code-validator/voucher/${code}`;
      } else if (process.env.REACT_APP_API_KEY === 'test') {
        return `https://sandbox-parceiro.rilato.com.br/#/qr-code-validator/voucher/${code}`;
      }
      return `https://localhost:3000/#/qr-code-validator/voucher/${code}`;
    }
    if (item.action.station_id && !item.action.partner_id) {
      if (process.env.REACT_APP_API_KEY === 'production') {
        return `https://app.rilato.com.br/#/qr-code-validator/voucher/${code}`;
      } else if (process.env.REACT_APP_API_KEY === 'test') {
        return `https://sandbox-gestor.rilato.com.br/#/qr-code-validator/voucher/${code}`;
      }
      return `https://localhost:3000/#/qr-code-validator/voucher/${code}`;
    }
  }
  return (
    <MaterialModal
      open={open}
      onClose={() => setOpen(false)}
      showTitle
      PaperProps={{ classes: { root: classes.paper } }}
      fullScreen={isMobile ? true : false}
    >
      <div
        className="container-fluid d-flex-column justify-content-space-around h-100"
        style={{ backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR }}
      >
        <div className="row justify-content-center mt-5">
          <div className="col-8 col-md-4">
            <img
              src={`${BASE_S3}/${COMPANY_COLORS.homepage.sidebar.logo}`}
              alt="LOGO-EMPRESA"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-auto text-center">
            <Typography variant="h5">{item.presential_course.action.name}</Typography>
          </div>
        </div>
        <div className="row justify-content-center mt-1">
          <div className="col-auto text-center">
            <Typography variant="body2">{user.name}</Typography>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-md-8 col-11">
            <Typography variant="body1" className="text-center">{`Voucher gerado em: ${moment(
              item.createdAt
            ).format('LLLL')}`}</Typography>
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-auto">
            <QRCode value={url(item.presential_course, item.code)} />
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-auto">
            <Typography variant="body1" className="">{item.code}</Typography>
          </div>
        </div>
        {item.presential_course.rules ? (
          <div className="row justify-content-center mt-4">
            <div className="col-md-auto col-11">
              <Typography variant="body1" className="">{item.presential_course.rules}</Typography>
            </div>
          </div>
        ) : null}
                {item.presential_course.local ? (
          <div className="row justify-content-center mt-4">
            <div className="col-md-auto col-11">
              <Typography variant="body1" className="">{item.presential_course.local}</Typography>
            </div>
          </div>
        ) : null}
        {item.validated === '0' ?
          (
            <>
              <div className="row justify-content-center mt-3">
                <div className="col-md-auto col-11">
                  <Typography variant="body1" className="mb-0 text-center">
                    Este voucher deverá ser utilizado até:
                  </Typography>
                </div>
              </div>
              <div className="row justify-content-center mt-0">
                <div className="col-md-auto col-11">
                  <Typography variant="body1" className="text-center">
                    {moment(item.presential_course.date).format('LLLL')}
                  </Typography>
                </div>
              </div>
            </>
          ) :
          <div className="row justify-content-center mt-3">
            <div className="col-md-auto col-11">
              <Typography variant="body1" className="mb-0 text-center">
                <strong>Este voucher já foi utilizado!</strong>
              </Typography>
            </div>
          </div>
        }
        <div className="row justify-content-center mt-5">
          <div className="col-md-auto col-11">
            <div
              className={'py-3 station-font-medium'}
              style={{
                background: BUTTON_BACKGROUND_COLOR,
                color: BUTTON_TEXT_COLOR,
                borderRadius: 8,
                paddingLeft: isMobile ? 40 : 140,
                paddingRight: isMobile ? 40 : 140,
                cursor: 'pointer',
                fontSize: '1rem',
                textAlign: 'center',
                fontFamily: NAV_FONT_FAMILY,
              }}
              onClick={() => window.print()}
            >
              {isMobile ? 'Salvar' : 'Imprimir'}
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-2 mb-5">
          <div className="col-auto">
            <button
              onClick={(event) => {
                event.preventDefault();
                setOpen(false);
                window.open('#/homepage', "_self");
              }}
              style={{
                textDecoration: 'underline',
                color: LINK_TEXT_COLOR,
                fontFamily: NAV_FONT_FAMILY,
              }}
            >
              Página inicial
            </button>
          </div>
        </div>
      </div>
    </MaterialModal>
  );
}
