import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Avatar, Grid, makeStyles, Typography } from '@material-ui/core';
import {
  BASE_S3,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  GENERAL_TEXT_COLOR,
  NAV_FONT_FAMILY,
  SIGNED_IN_NAV_TEXT_COLOR,
} from 'config/consts';
import { Form, Input, Button } from './styles';
import { sendSubCommentPost } from '../activitiesActions';

const useStyles = makeStyles((theme) => ({
  small: {
    width: '2.2em',
    height: '2.2em',
    margin: '1rem 0',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  createCommentComponent: {
    backgroundColor: isMobile ? 'white' : COMPANY_SIGNED_IN_BACKGROUND_COLOR,
    border: `2px solid ${GENERAL_TEXT_COLOR}`,
    borderRadius: '5px',
    padding: '2rem 0',
  },
}));

const CreateCommentAnswer = (props) => {
  const { auth, activity, commentDataId } = props;
  const classes = useStyles();
  const [commentContent, setCommentContent] = useState('');
  const [inputMaxLength] = useState(200);

  function handleSubmit(e) {
    e.preventDefault();
    const values = {
      user: auth.user.id,
      action: activity.detail.id,
      description: commentContent,
      comment_id: commentDataId,
    };
    props.sendSubCommentPost(values, activity.detail.id);
    setCommentContent('');
  }

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="center"
      alignItems="center"
      style={{
        maxWidth: '100vw',
        margin: isMobile ? '.6rem 0' : '2rem 0',
      }}
    >
      <Form onSubmit={handleSubmit}>
        <Grid
          id="createCommentComponent"
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          component="section"
          className={isMobile ? '' : classes.createCommentComponent}
        >
          {/* AVATAR */}
          <Grid
            item
            container
            xs={2}
            sm={1}
            justifyContent="flex-start"
            alignItems="center"
            style={{
              display: isMobile ? 'none' : '',
            }}
          >
            <Avatar
              alt={auth.user.name.split(' ')[0]}
              className={`${isMobile ? classes.small : classes.large}`}
              src={
                props.auth.user.image
                  ? `${BASE_S3}/${auth.user.image}`
                  : 'https://s3.amazonaws.com/rilato.app.files/files/default_profile.png'
              }
            />
          </Grid>
          {/* COMMENT CONTENT INPUT */}
          {/* mobile */}
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-end"
            alignItems="center"
            style={{
              display: isMobile ? '' : 'none',
              paddingRight: '.5rem',
            }}
          >
            <Typography variant="body1" component="span">
              {`${commentContent.length}/${inputMaxLength}`}
            </Typography>
          </Grid>
          {/* web */}
          <Grid
            item
            container
            xs={12}
            sm={10}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            style={{
              border: `2px solid ${GENERAL_TEXT_COLOR}`,
              borderRadius: '5px',
              backgroundColor: isMobile
                ? ''
                : COMPANY_SIGNED_IN_BACKGROUND_COLOR,
              marginLeft: '.5rem',
              color: GENERAL_TEXT_COLOR,
            }}
          >
            <Grid
              item
              container
              xs={8}
              md={8}
              justifyContent="center"
              alignItems="center"
            >
              <Input
                type="text"
                maxLength={inputMaxLength}
                placeholder="Deixe seu comentário"
                value={commentContent}
                onChange={(e) => setCommentContent(e.target.value)}
                companyBackgroundColor={COMPANY_SIGNED_IN_BACKGROUND_COLOR}
                textColor={GENERAL_TEXT_COLOR}
                required
                isMobile={isMobile}
                autoFocus={true}
              />
            </Grid>
            <Grid
              item
              container
              xs={false}
              md={2}
              justifyContent="flex-end"
              alignItems="center"
              style={{ display: isMobile ? 'none' : '' }}
            >
              <Typography variant="body1" component="span">
                {`${commentContent.length}/${inputMaxLength}`}
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={4}
              md={2}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                type="submit"
                style={{
                  fontFamily: NAV_FONT_FAMILY,
                  color: SIGNED_IN_NAV_TEXT_COLOR,
                  backgroundColor: 'transparent',
                }}
              >
                Comentar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activity: state.activities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sendSubCommentPost,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCommentAnswer);
