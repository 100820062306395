import React from 'react';
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Carrousel from 'common/components/carousel/carousel';
import { BASE_S3, COMPANY_COLORS } from '../../../../../config/consts';
import { DefaultCard } from '../../../../../common/components/cards/DefaultCard';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 48,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
}));

function RenderLegalActivities(props) {
  const { categories, history, selectActivity } = props;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const classes = useStyles({ xs, sm });
  const LEGAL_ID = 494;
  const legalsCategories = categories.listCategories.content.filter(
    (categories) => categories.id === LEGAL_ID
  );

  return legalsCategories.length > 0 ? (
    <>
      {legalsCategories.map((category, index) => (
        <div key={index} className={classes.root}>
          <Carrousel
            categoryName={category.name}
            indexStart={0}
            onClickCategory={() =>
              history.push(`/homepage/categoria/${category.id}`)
            }
            itemsDisplay={xs ? 2 : 4}
            color={COMPANY_COLORS.general.general_color}
          >
            {category.category_actions.map((category_action, index) => (
              <div key={index}>
                <DefaultCard
                  onClick={
                    category_action.action.type_id === 13
                      ? () =>
                          (window.location.href =
                            category_action.action.redirect_links[0].url)
                      : () => selectActivity(category_action.action.id)
                  }
                  imgSource={`${BASE_S3}/${category_action.action.image}`}
                  actionName={category_action.action.name}
                />
              </div>
            ))}
          </Carrousel>
        </div>
      ))}
    </>
  ) : null;
}

RenderLegalActivities.propTypes = {
  categories: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.object.isRequired,
  selectActivity: PropTypes.func.isRequired,
};

export default RenderLegalActivities;
