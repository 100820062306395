import { Box, Typography } from '@material-ui/core';
// import { String2Currency } from 'helpers/dataTransformers';
import React from 'react';
import { Container } from './styles';
import Wallet from '../../svg/wallet';
import { customCurrencyChanger } from '../../../../helpers/utils';
import { GENERAL_TEXT_COLOR } from 'config/consts';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR } from '../../../../config/consts';

export default function CashbackCard(props) {
  return (
    <Container
      mgm={props.invitedCashback}
      bgColor={COMPANY_SIGNED_IN_BACKGROUND_COLOR}
      boxShadowColor={GENERAL_TEXT_COLOR}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" style={{ fontSize: 12 }}>Cashback</Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            width: 60,
            height: 60,
            border: `2px solid ${GENERAL_TEXT_COLOR}`,
            background: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
          }}
        >
          <Wallet color={GENERAL_TEXT_COLOR} />
        </div>
      </Box>
      <Box display="flex" flexDirection="column">
        {props.invitedCashback ? (
          <Typography variant="body1" style={{ fontSize: 12 }}>Filiado</Typography>
        ) : null}
        <Typography variant="body1" style={{ fontSize: 24 }}>
          <strong>
            {customCurrencyChanger(props.currencyData, props.cashback)}
          </strong>
          {/* {String2Currency(props.cashback)} */}
        </Typography>
        {props.invitedCashback ? (
          <>
            <Typography variant="body1" style={{ fontSize: 12 }}>Convidado</Typography>
            <strong style={{ fontSize: 24, color: GENERAL_TEXT_COLOR }}>
              {customCurrencyChanger(props.currencyData, props.invitedCashback)}
            </strong>
          </>
        ) : null}
      </Box>
      <Typography variant="body1" style={{ fontSize: 16 }}>
        {props.type === 1 // Member-get-member
          ? 'A pontuação será distribuída quando seu convidado se juntar à plataforma.'
          : props.type === 2 // Cadastre e ganhe
            ? 'Entre na plataforma, confirme o seu cadastro e receba os seus pontos'
            : props.type === 3 // Cupom
              ? 'O cashback será creditado após confirmação da empresa parceira.'
              : props.type === 4 // Atividade presencial
                ? 'O cashback será creditado após confirmação da empresa parceira.'
                : props.type === 5 // Compatilhe e ganhe
                  ? 'O cashback será creditado após o acesso de algum convidado pelo o seu link.'
                  : props.type === 6
                    ? 'O cashback será creditado após o quiz ser respondido, caso você acertar pelo menos 50% das perguntas.'
                    : props.type >= 7 && props.type <= 12 // Pesquisas
                      ? 'O cashback será creditado após a pesquisa ser respondida.'
                      : 'Este é o valor a ser será depositado na sua conta com o conclusão bem sucedida da atividade.'}
      </Typography>
    </Container>
  );
}
