import { Box, Grid } from '@material-ui/core';
import React from 'react';
import TextCard from '../../../../../common/components/cards/textCard';

export default function Accounting() {
  return (
    <section id="accounting">
      <p className="body-title mt-4">Assuntos e Pautas Sindicais</p>
      <p className="h4 mt-4" style={{ color: '#838383', maxWidth: 500 }}>
        Acesse o núcleo responsável por promover e defender interesses dos
        servidores e da sociedade - de forma inteligente e estratégica -
        principalmente na tramitação de proposições legislativas.
      </p>

      <Grid container spacing={3} style={{ marginTop: 64 }}>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/politico-sindical/temas">
              Temas de interesse
            </TextCard>
          </Box>
        </Grid> 
      </Grid>
    </section>
  );
}
