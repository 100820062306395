import React from 'react';
import { CardContainer, CardsWrapper, Date, DatesWrapper } from './styles';

export function VouchersList({ list, onClickItem, image }) {
  return (
    <CardsWrapper>
      {list.map((item, index) => (
        <CardContainer onClick={() => onClickItem(item)} key={index}>
          <img
            src={
              'https://s3.amazonaws.com/rilato.app.files/files/system/card-image-fuels.jpeg'
            }
            alt="imagem_do_vocuher"
          />
          <h1>{item.nomeCampanha}</h1>
          <DatesWrapper>
            <Date>
              <p>Emissão</p>
              <span>{item.dataEmissao}</span>
            </Date>
            <Date>
              <p>Expira em</p>
              <span>{item.dataValidade}</span>
            </Date>
          </DatesWrapper>
        </CardContainer>
      ))}
    </CardsWrapper>
  );
}
