import { Avatar } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Collapse from '@material-ui/core/Collapse';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import { BASE_S3, BRB_FLA_STATION_ID } from 'config/consts';
import React from 'react';
import {
  COMPANY_COLORS,
  COMPANY_STATION_ID,
  USER_TOKEN,
} from '../../../config/consts';
import DownArrow from '../svg/downArrow';
import UpArrow from '../svg/upArrow';
import CloseButton from './closeButton';
import ExploreIcon from './explore';
import MenuButton from './menuButton';
import OwnerIcon from './ownerIcon';
import './persistentDrawer.css';
import ShoppingCart from './shoppingCart';
const fanId =
  process.env.REACT_APP_API_KEY === 'production'
    ? 13
    : process.env.REACT_APP_API_KEY === 'test'
    ? 28
    : 1;

const urlFan =
  process.env.REACT_APP_API_KEY === 'production'
    ? `https://app.rilato.com.br/#/login-token`
    : process.env.REACT_APP_API_KEY === 'test'
    ? `http://sandbox-gestor.rilato.com.br/#/login-token`
    : 'http://localhost:3000/#/login-token';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: { ...theme.mixins.toolbar, minHeight: '2.35em' },
  drawerPaper: {
    width: '100%',
  },
  content: {
    height: '100vh',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  bottomNavigation: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'center',
    letterSpacing: '0.14',
  },
}));

export default function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const route = window.location.hash.split('#')[1];

  function handleFirstStateActive() {
    for (let i in props.navItems) {
      if (props.navItems[i].route === route) {
        return { id: props.navItems[i].id, children: false };
      } else if (props.navItems[i].children) {
        for (let item in props.navItems[i].children) {
          if (props.navItems[i].children[item].route === route) {
            return { id: props.navItems[i].id, children: true };
          }
        }
      } else {
        return { id: 0, children: false };
      }
    }
  }
  const [openCollapse, setOpenCollapse] = React.useState({
    id: 0,
    open: false,
  });

  const handleCollapse = (item) => {
    if (item.children) {
      if (item.id !== openCollapse.id && openCollapse.open) {
        setOpenCollapse({ id: item.id, open: true });
      } else {
        setOpenCollapse({ id: item.id, open: !openCollapse.open });
      }
    } else {
      props.history.push(item.route);
      handleDrawerToggle();
    }
  };

  const handleChildrenClick = (children) => {
    setActive({ id: children.id, children: true });
    props.history.push(children.route);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [active, setActive] = React.useState(handleFirstStateActive());

  const handleActive = (index, item) => {
    setActive({ id: item.id, children: false });
  };

  //Avatar controls

  const [setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const drawer = (
    <div>
      <Toolbar>
        <div className="container-fluid" style={{ background: 'inherit' }}>
          <div className="row justify-content-between align-items-center">
            <div className="col-8">
              <div className="d-flex align-items-center">
                <img
                  src={`${BASE_S3}/${COMPANY_COLORS.homepage.sidebar.logo}`}
                  style={{ height: 50, marginRight: 27, cursor: 'pointer' }}
                  className="img-fluid"
                  alt="station_img"
                  onClick={() => props.history.push('/homepage')}
                />
              </div>
            </div>
            <div
              onClick={handleDrawerToggle}
              className="col-4  d-flex align-items-center justify-content-end"
            >
              <CloseButton></CloseButton>
            </div>
          </div>
        </div>
      </Toolbar>
      <div
        className="d-flex justify-content-start w-100 align-items-center pt-4 pb-4"
        onClick={() => {
          if (COMPANY_STATION_ID !== BRB_FLA_STATION_ID) {
            props.history.push('/homepage/perfil');
          }
          handleDrawerToggle();
        }}
      >
        <div className="p-2">
          <Avatar
            alt="Avatar Perfil"
            src={
              props.user.image
                ? `${BASE_S3}/${props.user.image}`
                : 'https://s3.amazonaws.com/rilato.app.files/files/default_profile.png'
            }
            onClick={handleClick}
            style={{ cursor: 'pointer', marginLeft: 6 }}
            aria-controls="customized-menu"
            aria-haspopup="true"
          />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-start p-2">
          <span
            style={{
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '16px',
              lineHeight: '160%',
              textAlign: 'center',
              letterSpacing: '0.25',
              color: '#282828',
            }}
          >
            {props.user.name.split(' ')[0]}
          </span>
          <span
            style={{
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '17px',
              textAlign: 'center',
              letterSpacing: '0.140385px',
              color: COMPANY_COLORS.general.general_color,
            }}
          >
            {props.title}
          </span>
        </div>
      </div>
      {COMPANY_STATION_ID === BRB_FLA_STATION_ID ? null : (
        <List style={{ padding: 0 }}>
          {props.navItems.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem
                className={classes.listRoot}
                onClick={() => handleCollapse(item)}
                style={{
                  textDecoration: 'none',
                  backgroundColor:
                    active.id === item.id && !active.children && !item.children
                      ? '#f1f2f4'
                      : '#ffffff',
                  cursor: 'pointer',
                }}
              >
                <div
                  className="container-fluid"
                  style={{
                    backgroundColor:
                      active.id === item.id &&
                      !active.children &&
                      !item.children
                        ? '#f1f2f4'
                        : '#ffffff',
                  }}
                >
                  <div
                    key={index}
                    onClick={() => handleActive(`${item.id}`, item)}
                    className={clsx(
                      classes.listItemRoot,
                      active.id === item.id &&
                        !active.children &&
                        !item.children
                        ? classes.navItemActive
                        : undefined,
                      'row py-3 no-gutters'
                    )}
                  >
                    <div
                      className="col-auto pr-3"
                      style={{
                        fill:
                          active.id === item.id &&
                          !active.children &&
                          !item.children
                            ? COMPANY_COLORS.station.sidebar.icon.active
                            : COMPANY_COLORS.station.sidebar.icon.default,
                        marginTop: '-3px',
                      }}
                    >
                      {item.iconType === 'materialIcon' ? (
                        <div
                          className=""
                          style={{
                            color: 'black',
                          }}
                        >
                          {item.icon}
                        </div>
                      ) : (
                        <div className="">{item.icon}</div>
                      )}
                    </div>
                    <div className="col ">
                      <p
                        className={clsx(
                          active.id === item.id &&
                            !active.children &&
                            !item.children
                            ? classes.navItemActive
                            : undefined,
                          'station-font-medium mb-0'
                        )}
                        style={{
                          color: 'black',
                          fontSize: '0.875rem',
                        }}
                      >
                        {item.primaryText}
                      </p>
                    </div>
                    <div className="col-auto ">
                      {item.children ? (
                        openCollapse.id === item.id && openCollapse.open ? (
                          <UpArrow color="#666" />
                        ) : (
                          <DownArrow color="#666" />
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              </ListItem>

              {/* Collapse children render */}
              {item.children ? (
                <Collapse
                  in={openCollapse.open && openCollapse.id === item.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.children.map((childrenItem, childrenIndex) => {
                      return (
                        <ListItem
                          key={childrenIndex}
                          button
                          className={classes.nested}
                          onClick={() => handleChildrenClick(childrenItem)}
                          style={{
                            backgroundColor:
                              active.id === childrenItem.id && active.children
                                ? '#f1f2f4'
                                : '#ffffff',
                          }}
                        >
                          <div
                            className="col-auto pr-3"
                            style={{
                              fill:
                                active.id === childrenItem.id && active.children
                                  ? COMPANY_COLORS.station.sidebar.icon.active
                                  : COMPANY_COLORS.station.sidebar.icon.default,
                              marginTop: '-3px',
                            }}
                          >
                            <div className="align-self-center">
                              {childrenItem.icon}
                            </div>
                          </div>
                          <ListItemText
                            secondary={childrenItem.primaryText}
                            secondaryTypographyProps={{
                              style: { fontSize: '0.875rem' },
                            }}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              ) : null}
              <Divider light={true} style={{ color: '#F7F7F7;' }} />
            </React.Fragment>
          ))}
        </List>
      )}
      <div className="d-flex justify-content-center w-100 py-4">
        <span
          onClick={() => props.history.push('/logout')}
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '24px',
            textAlign: 'center',
            letterSpacing: '0.25',
            color: COMPANY_COLORS.general.general_color,
          }}
        >
          Sair da conta
        </span>
      </div>
    </div>
  );

  /* const currentRoute = props.routes[props.routes.length - 2]?.path; */
  const currentRoute = props.history.location.pathname.split('/')[1];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        elevation={2}
        position="fixed"
        className={classes.appBar}
        style={{ backgroundColor: 'white' }}
      >
        <Toolbar>
          <div className="container-fluid" style={{ background: 'inherit' }}>
            <div className="row justify-content-between align-items-center">
              <div className="col-8">
                <div className="d-flex align-items-center">
                  <img
                    src={`${BASE_S3}/${COMPANY_COLORS.homepage.sidebar.logo}`}
                    style={{ height: 50, marginRight: 27, cursor: 'pointer' }}
                    className="img-fluid"
                    alt="station_img"
                    onClick={() => props.history.push('/homepage')}
                  />
                </div>
              </div>
              <div className="col-4  d-flex align-items-center justify-content-end">
                {COMPANY_STATION_ID !== BRB_FLA_STATION_ID && (
                  <ShoppingCart
                    onClick={() => props.history.push('/homepage/carrinho')}
                    style={{ color: 'black', marginRight: 24 }}
                  />
                )}

                <IconButton
                  color={'inherit'}
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuButton style={{ color: 'black' }} />
                </IconButton>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'right'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
        {COMPANY_STATION_ID === fanId ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              bottom: 0,
              left: 0,
              background: 'white',
              height: 64,
              width: '100%',
              borderTop: '1px solid #DFDFDF',
            }}
          >
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                flexDirection: 'row',
              }}
              onClick={() => props.history.push('/homepage')}
              className="w-25 d-flex flex-column  justify-content-center align-content-center align-items-center"
            >
              <ExploreIcon
                fill={currentRoute !== 'perfil' ? '#5759FB' : '#282828'}
              />
              <span
                className={classes.bottomNavigation}
                style={{
                  color: currentRoute !== 'perfil' ? '#5759FB' : '#282828',
                }}
              >
                Descobrir
              </span>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
              onClick={() => {
                window.open(
                  `${urlFan}/${
                    JSON.parse(localStorage.getItem(USER_TOKEN)).split(' ')[1]
                  }`
                );
              }}
              className="w-25 d-flex flex-column  justify-content-center align-content-center align-items-center"
            >
              <OwnerIcon fill="#282828" />
              <span className={classes.bottomNavigation}>Gerenciar</span>
            </div>
          </div>
        ) : null}
      </main>
    </div>
  );
}
