import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FullButton } from 'common/components/buttons/fullButton';
import {
  InputLabelOutlined,
  InputProfilePicture,
  SelectLabel,
} from 'common/components/fields/fields';
import {
  BASE_S3,
  COMPANY_COLORS,
  COMPANY_STATION_ID,
  IS_FAN,
} from 'config/consts';
import { cpfMask, dateMask, phoneMask } from 'helpers/masks';
import states from 'helpers/states.json';
import { FORM_RULES } from 'helpers/validations';
import { getStationData } from 'modules/website/websiteActions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import {
  ColorPickerInput,
  InputImageWithProcessing,
} from '../../../common/components/fields/fields';
import {
  fileRejected,
  setLoading,
  signup,
  signupCompanyAndStation,
  updateParticipant,
} from '../authActions';
import './signupParticipant.css';

const url =
  process.env.REACT_APP_API_KEY === 'production'
    ? '.rilato.site'
    : process.env.REACT_APP_API_KEY === 'test'
    ? '.fantest.site'
    : '.fantest.site';

states.sort(function (a, b) {
  if (a.sigla < b.sigla) {
    return -1;
  }
  if (a.sigla > b.sigla) {
    return 1;
  }
  return 0;
});

function SignupParticipant(props) {
  const [formStep, setFormStep] = useState(1);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  useEffect(() => {
    props.setLoading(false);
  }, [props]);

  useEffect(() => {
    if (!props.website.stationData.address) {
      props.getStationData(COMPANY_STATION_ID);
    }
  }, [props, props.website.stationData.address]);

  function handleClose() {
    setIsDialogVisible(false);
  }

  function handleColorChange(color) {
    props.change('main_brand_color', color);
  }

  function onSubmit(values) {
    if (formStep === 2) {
      function successCleanup() {
        if (IS_FAN) {
          if (props.auth.user.user_establishments?.length > 0) {
            return props.history.push('/');
          }
        }

        nextStep();
      }
      props.updateParticipant(
        values,
        props.auth.user.id,
        props.history,
        false,
        successCleanup
      );
    } else if (formStep === 5) {
      console.log('stationCreator', values);
      // Validação de CNPJ

      const newValues = {
        ...values,
        subdomain: values.prettyName.toLowerCase().replaceAll(' ', ''),
        country: 'BR',
        email: props.auth.user.email,
        user_id: props.auth.user.id,
        owner: props.auth.user.id,
        type: 1,
      };

      const { history, auth } = props;
      values['type'] = 1;
      values['owner'] = props.auth.user.id;
      props.signupCompanyAndStation(newValues, history, auth, nextStep);
    } else {
      nextStep();
    }
  }

  function nextStep() {
    setFormStep((previousValue) => previousValue + 1);
  }

  function previousStep() {
    setFormStep((previousValue) => previousValue - 1);
  }

  const statesList = states.map((item, index) => ({
    value: item.sigla,
    label: item.nome,
  }));

  const { website } = COMPANY_COLORS;
  const { name } = props.website.stationData;

  return (
    <>
      {formStep !== 6 ? (
        <div
          className="d-flex justify-content-center"
          style={{
            background: `url(${BASE_S3}/${website.header.background_auth_station})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            minHeight: '100vh',
          }}
        >
          <div className="col-sm-8 col-md-6 col-lg-4 align-self-center py-5">
            <div
              style={{
                background: '#ffffff',
                borderRadius: 4,
              }}
            >
              <div className="row justify-content-center">
                <div className="col-7 d-flex justify-content-center mt-5">
                  <img
                    className
                    src={`${BASE_S3}/${website.navbar.station_secondary_logo}`}
                    alt="Logo"
                    style={{
                      maxWidth: '100%',
                      height: 'auto',
                      maxHeight: 85,
                    }}
                  />
                </div>
              </div>
              <form
                className="col-12"
                onSubmit={props.handleSubmit((v) => onSubmit(v))}
                noValidate
              >
                {formStep === 0 ? (
                  <>
                    <div className="d-flex row justify-content-center my-3">
                      <div className="col-9 d-flex py-3 justify-content-center">
                        <p
                          style={{
                            textAlign: 'center',
                            fontSize: 32,
                            fontWeight: 'bold',
                          }}
                        >
                          Que bom que você está aqui!
                        </p>
                      </div>
                    </div>
                    <div className="d-flex row my-5">
                      <div className="d-flex justify-content-center px-5">
                        <p style={{ textAlign: 'center' }}>
                          Estamos muito felizes com o que estamos criando juntos
                          com {name}.
                        </p>
                      </div>
                    </div>
                    <div
                      className="d-flex row justify-content-center  my-5"
                      style={{ marginBottom: 20 }}
                    >
                      <div className="col-10">
                        <div className="d-flex justify-content-center py-5">
                          <FullButton
                            classNameProp="py-3 px-4"
                            type="submit"
                            disabled={props.auth.loadingRegister}
                            progress={parseInt(props.general.submitProgress)}
                          >
                            Vamos começar?
                          </FullButton>
                        </div>
                      </div>
                    </div>
                  </>
                ) : formStep === 1 ? (
                  <>
                    <div className="row justify-content-center mt-5">
                      <div className="col-10">
                        <p
                          style={{
                            textAlign: 'center',
                            fontSize: 18,
                          }}
                        >
                          Você quer cadastrar sua foto?
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-center mt-4">
                      <Field
                        component={InputProfilePicture}
                        name="image"
                        label="Escolha uma foto (máx: 1MB)"
                        margin="normal"
                        cols="12"
                        onDropRejected={props.fileRejected}
                      />
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                      <p style={{ margin: 0, fontSize: 12, color: '#E6E6E6' }}>
                        opcional
                      </p>
                    </div>
                    <div
                      className="d-flex row justify-content-center  mt-3 pt-5"
                      style={{ marginBottom: 20 }}
                    >
                      <div className="col-10">
                        <div className="d-flex justify-content-center py-3">
                          <FullButton
                            classNameProp="py-3 px-4"
                            type="submit"
                            disabled={props.auth.loadingRegister}
                            progress={parseInt(props.general.submitProgress)}
                          >
                            Continuar
                          </FullButton>
                        </div>
                      </div>
                    </div>
                    <div className="container my-3">
                      <div className="row justify-content-center pb-3">
                        <div
                          className="col-10 d-flex justify-content-center align-items-center p-3"
                          style={{
                            backgroundColor: '#fff',
                          }}
                        >
                          <button
                            onClick={previousStep}
                            clbuttonssName="station-font-medium"
                            type="button"
                            style={{
                              color: '#0E5FD6',
                              fontSize: '0.875rem',
                              display: 'inline-block',
                              textDecorationLine: 'underline',
                            }}
                          >
                            Voltar
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : formStep === 2 ? (
                  <>
                    <div className="row justify-content-center mt-5" style={{}}>
                      <div className="col-10">
                        <p
                          style={{
                            textAlign: 'center',
                            fontSize: 18,
                          }}
                        >
                          Coloque por favor seu nome completo, estado de
                          residência e data de nascimento.
                        </p>
                      </div>
                    </div>

                    <div className="row justify-content-center my-5" style={{}}>
                      <div className="col-10">
                        <Field
                          component={InputLabelOutlined}
                          type="tel"
                          label="CPF"
                          helperText="Digite seu CPF"
                          name="cpf"
                          validate={[FORM_RULES.required, FORM_RULES.cpf]}
                          {...cpfMask}
                        />
                      </div>
                      <div className="col-10">
                        <Field
                          component={InputLabelOutlined}
                          label="Nome Completo"
                          name="name"
                          validate={[FORM_RULES.required]}
                          helperText="Digite seu nome completo"
                        />
                      </div>
                      <div className="col-10 mt-3">
                        <Field
                          component={InputLabelOutlined}
                          label="Telefone"
                          type="tel"
                          inputlabelprops={{
                            shrink: true,
                          }}
                          name="tel"
                          validate={[FORM_RULES.required]}
                          helperText="Digite seu Telefone"
                          {...phoneMask}
                        />
                      </div>
                      <div className="col-10 mt-3">
                        <Field
                          component={InputLabelOutlined}
                          type="tel"
                          label="Data de Nascimento"
                          name="birthday"
                          validate={[FORM_RULES.required, FORM_RULES.date]}
                          {...dateMask}
                        />
                      </div>
                      <div className="col-10 mt-3">
                        <Field
                          component={SelectLabel}
                          type="text"
                          label={`Estado de residência`}
                          name={`state`}
                          margin="normal"
                          options={statesList}
                          validate={[FORM_RULES.required]}
                        />
                      </div>
                    </div>

                    <div
                      className="d-flex row justify-content-center  mt-2 pt-2"
                      style={{ marginBottom: 20 }}
                    >
                      <div className="col-10">
                        <div className="d-flex justify-content-center py-3">
                          <FullButton
                            classNameProp="py-3 px-4"
                            type="submit"
                            disabled={
                              props.auth.loadingRegister || props.formValues
                                ? !props.formValues.name ||
                                  !props.formValues.state ||
                                  !props.formValues.birthday
                                : true
                            }
                            progress={parseInt(props.general.submitProgress)}
                          >
                            Continuar
                          </FullButton>
                        </div>
                      </div>
                    </div>
                    <div className="container my-3">
                      <div className="row justify-content-center pb-3">
                        <div
                          className="col-10 d-flex justify-content-center align-items-center p-3"
                          style={{
                            backgroundColor: '#fff',
                          }}
                        >
                          <button
                            onClick={previousStep}
                            clbuttonssName="station-font-medium"
                            type="button"
                            style={{
                              color: '#0E5FD6',
                              fontSize: '0.875rem',
                              display: 'inline-block',
                              textDecorationLine: 'underline',
                            }}
                          >
                            Voltar
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : formStep === 3 ? (
                  <>
                    <div className="row justify-content-center mt-5">
                      <div className="col-10">
                        <p
                          style={{
                            textAlign: 'center',
                            fontSize: 18,
                          }}
                        >
                          Adicione sua logomarca
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-center mt-4">
                      <Field
                        component={InputImageWithProcessing}
                        name="main_brand_image"
                        label="É recomendado formato PNG sem fundo (máx: 1MB)"
                        margin="normal"
                        aspectLabel={'livre'}
                        cols="12"
                      />
                    </div>

                    <div
                      className="d-flex row justify-content-center  mt-3 pt-5"
                      style={{ marginBottom: 20 }}
                    >
                      <div className="col-10">
                        <div className="d-flex justify-content-center py-3">
                          <FullButton
                            classNameProp="py-3 px-4"
                            type="submit"
                            disabled={props.auth.loadingRegister}
                            progress={parseInt(props.general.submitProgress)}
                          >
                            Continuar
                          </FullButton>
                        </div>
                      </div>
                    </div>
                    <div className="container my-3">
                      <div className="row justify-content-center pb-3">
                        <div
                          className="col-10 d-flex justify-content-center align-items-center p-3"
                          style={{
                            backgroundColor: '#fff',
                          }}
                        >
                          <button
                            onClick={previousStep}
                            clbuttonssName="station-font-medium"
                            type="button"
                            style={{
                              color: '#0E5FD6',
                              fontSize: '0.875rem',
                              display: 'inline-block',
                              textDecorationLine: 'underline',
                            }}
                          >
                            Voltar
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : formStep === 4 ? (
                  <>
                    <div className="row justify-content-center mt-5" style={{}}>
                      <p
                        style={{
                          textAlign: 'center',
                          fontSize: 18,
                        }}
                      >
                        Escolha a cor de destaque para seu site.
                      </p>
                    </div>
                    <div className="row justify-content-center my-2" style={{}}>
                      <Field
                        component={ColorPickerInput}
                        label={''}
                        name="main_brand_color"
                        onColorChange={handleColorChange}
                        cols="12 12 6 6"
                      />
                    </div>
                    <div
                      className="d-flex row justify-content-center  mt-2 pt-2"
                      style={{ marginBottom: 20 }}
                    >
                      <div className="col-10">
                        <div className="d-flex justify-content-center py-3">
                          <FullButton
                            classNameProp="py-3 px-4"
                            type="submit"
                            disabled={props.auth.loadingRegister}
                            progress={parseInt(props.general.submitProgress)}
                          >
                            Continuar
                          </FullButton>
                        </div>
                      </div>
                    </div>
                    <div className="container my-3">
                      <div className="row justify-content-center pb-3">
                        <div
                          className="col-10 d-flex justify-content-center align-items-center p-3"
                          style={{
                            backgroundColor: '#fff',
                          }}
                        >
                          <button
                            onClick={previousStep}
                            clbuttonssName="station-font-medium"
                            type="button"
                            style={{
                              color: '#0E5FD6',
                              fontSize: '0.875rem',
                              display: 'inline-block',
                              textDecorationLine: 'underline',
                            }}
                          >
                            Voltar
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : formStep === 5 ? (
                  <>
                    <div className="row justify-content-center mt-5" style={{}}>
                      <p
                        style={{
                          textAlign: 'center',
                          fontSize: 18,
                        }}
                      >
                        Vamos criar o seu site!
                      </p>
                    </div>
                    <div className="row justify-content-center my-5" style={{}}>
                      <div className="col-10">
                        <Field
                          component={InputLabelOutlined}
                          label="Nome"
                          name="prettyName"
                          placeholder="Ex: ADVOCACIA SA"
                          helperText="Digite aqui o nome que vai aparecer na sua url, sem espaço, acento ou caracteres especiais"
                          validate={[FORM_RULES.required, FORM_RULES.subdomain]}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        background: '#F7F7F7',
                        borderRadius: 6,
                        height: 56,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <h5
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '18px',
                          lineHeight: '22px',
                          textAlign: 'center',
                          letterSpacing: '0.25px',
                          color: '#5759FB',
                        }}
                      >
                        {props.formValues?.prettyName
                          ? `${props.formValues?.prettyName
                              .toLowerCase()
                              .replaceAll(' ', '')}` + url
                          : `*` + url}
                      </h5>
                    </div>
                    <div
                      className="d-flex row justify-content-center  mt-2 pt-2"
                      style={{ marginBottom: 20 }}
                    >
                      <div className="col-10">
                        <div className="d-flex justify-content-center py-3">
                          <FullButton
                            classNameProp="py-3 px-4"
                            type="submit"
                            disabled={
                              props.auth.loadingRegister || props.formValues
                                ? !props.formValues.prettyName
                                : true
                            }
                            progress={parseInt(props.general.submitProgress)}
                          >
                            Finalizar
                          </FullButton>
                        </div>
                      </div>
                    </div>
                    <div className="container my-3">
                      <div className="row justify-content-center pb-3">
                        <div
                          className="col-10 d-flex justify-content-center align-items-center p-3"
                          style={{
                            backgroundColor: '#fff',
                          }}
                        >
                          <button
                            onClick={previousStep}
                            clbuttonssName="station-font-medium"
                            type="button"
                            style={{
                              color: '#0E5FD6',
                              fontSize: '0.875rem',
                              display: 'inline-block',
                              textDecorationLine: 'underline',
                            }}
                          >
                            Voltar
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </form>
            </div>
          </div>

          <Dialog
            open={isDialogVisible}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">CNPJ inválido</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Verifique o CNPJ informado e tente novamente.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <>
          <div
            style={{
              backgroundImage: `url("${BASE_S3}/files/system/0top.png")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '50vh',
            }}
          >
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(40, 40, 40,0.6)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h3
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '24px',
                    lineHeight: '29px',
                    textAlign: 'center',
                    letterSpacing: '0.25px',
                    color: '#FFFFFF',
                  }}
                >
                  Criar conteúdo para meus fãs
                </h3>
                <div className="col-10">
                  <div className="d-flex justify-content-center py-3">
                    <FullButton
                      classNameProp="py-3 px-4"
                      type="button"
                      onClick={() =>
                        window.open('https://app.rilato.com.br/')
                      }
                    >
                      Continuar
                    </FullButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url("${BASE_S3}/files/system/1bottom.png")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: '50vh',
            }}
          >
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(40, 40, 40,0.6)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h3
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '24px',
                    lineHeight: '29px',
                    textAlign: 'center',
                    letterSpacing: '0.25px',
                    color: '#FFFFFF',
                  }}
                >
                  Aprender a usar a plataforma Fan
                </h3>
                <div className="col-10">
                  <div className="d-flex justify-content-center py-3">
                    <FullButton
                      classNameProp="py-3 px-4"
                      type="button"
                      onClick={() => props.history.push('/')}
                    >
                      Continuar
                    </FullButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const SignupParticipantForm = reduxForm({ form: 'signupParticipantForm' })(
  SignupParticipant
);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    formValues: getFormValues('signupParticipantForm')(state),
    website: state.website,
    general: state.general,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signup,
      getStationData,
      setLoading,
      updateParticipant,
      signupCompanyAndStation,
      fileRejected,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupParticipantForm);
