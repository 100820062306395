import { Avatar, Fade, Grid, makeStyles, Modal } from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import {
  BASE_S3,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  GENERAL_TEXT_COLOR,
} from '../../../../../../config/consts';
import CommentLike from '../Likes/CommentLike.jsx';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {
  CloseModalButton,
  CommentAnswerButton,
  CommentBody,
  ConfirmationContent,
  DeleteCommentButton,
  ModalConfirmationButton,
} from '../styles';
import { bindActionCreators } from 'redux';
import { sendCommentDeletion } from '../../activitiesActions';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LoadingProgress from '../../../../../../common/components/progress/loading';
import SubComment from './SubComment';
import CreateCommentAnswer from '../CreateCommentAnswer.jsx';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  small: {
    width: '2.2em',
    height: '2.2em',
    margin: '1rem 0',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  accordion: (props) => ({
    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
    width: '100%',
    display: props.noneSubComments ? '' : 'none',
    border: 'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }),
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
    border: 'transparent',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
  },
}));

function Comment(props) {
  const { auth, activity, commentId } = props;
  const [commentData, setCommentData] = useState('');
  const [displaysSubComments, setDisplaySubComments] = useState(false);
  const [noneSubComments, setNoneSubComments] = useState(false);
  const classes = useStyles({ noneSubComments });
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);

  useEffect(() => {
    const data = activity.allDataComments.find(
      (comment) => comment.id === commentId
    );
    setCommentData(data);
  }, [activity.allDataComments, commentId]);

  function handleDeleteCommentClick() {
    props.sendCommentDeletion(commentData.id, activity.detail.id);
    handleConfirmationModalClose();
  }

  function handleOpenCommentsClick() {
    setDisplaySubComments(!displaysSubComments);
    setTimeout(() => {
      setNoneSubComments(!noneSubComments);
    }, 200);
  }

  function handleConfirmationModalOpen() {
    setIsConfirmationModalVisible(true);
  }

  function handleConfirmationModalClose() {
    setIsConfirmationModalVisible(false);
  }

  return (
    <>
      <Grid
        item
        container
        xs={12}
        sm={12}
        justifyContent="center"
        style={{
          margin: '.5rem 0',
          padding: '.6rem 0',
          minWidth: '100%',
          borderRadius: '5px',
        }}
      >
        {/* AVATAR */}
        <Grid
          item
          container
          xs={2}
          sm={1}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Avatar
            alt={commentData && commentData.user.name.split(' ')[0]}
            className={`${isMobile ? classes.small : classes.large}`}
            src={
              commentData && commentData.user.image
                ? `${BASE_S3}/${commentData.user.image}`
                : 'https://s3.amazonaws.com/rilato.app.files/files/default_profile.png'
            }
          />
        </Grid>
        {/* COMMENT BODY */}
        <Grid
          item
          container
          xs={10}
          sm={11}
          justifyContent="center"
          alignItems="flex-start"
          spacing={1}
          style={{
            paddingTop: isMobile ? '.8rem' : '.2rem',
          }}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent={props.lgScreen ? 'flex-start' : 'space-between'}
            alignItems="center"
          >
            {commentData && (
              <Typography variant="body1" component="span">
                {commentData.user.name}
              </Typography>
            )}
            {commentData && (
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '14px',
                  lineHeight: '20px',
                  textAlign: 'right',
                  letterSpacing: '0.25px',
                  color: GENERAL_TEXT_COLOR,
                  paddingLeft: props.lgScreen ? '1rem' : '',
                }}
              >
                {moment(commentData.createdAt).format('DD/MM/YYYY')}
              </span>
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item container xs={11} alignItems="center">
              <CommentBody>
                <Typography variant="body1" component="span">
                  {commentData.description}
                </Typography>
              </CommentBody>
            </Grid>
            <Grid
              item
              container
              xs={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              <DeleteCommentButton
                authId={auth.user.id}
                commentId={commentData.user_id}
                onClick={() => handleConfirmationModalOpen()}
              >
                <DeleteOutlineIcon
                  fontSize="small"
                  style={{ color: GENERAL_TEXT_COLOR }}
                />
              </DeleteCommentButton>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-start"
            alignItems="center"
          >
            <CommentLike commentData={commentData} />
            <CommentAnswerButton onClick={() => handleOpenCommentsClick()}>
              <ChatBubbleOutlineOutlinedIcon
                fontSize="small"
                style={{ color: GENERAL_TEXT_COLOR }}
              />
              <Typography cariant="body1" component="span">
                {commentData.comments ? commentData.comments.length : '...'}
              </Typography>
            </CommentAnswerButton>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{
            paddingTop: '.4rem',
          }}
        >
          <Grid item container sm={1}></Grid>
          <Grid
            item
            container
            xs={12}
            sm={11}
            justifyContent="center"
            alignItems="center"
          >
            <Accordion
              onChange={() => handleOpenCommentsClick()}
              expanded={displaysSubComments}
              className={classes.accordion}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon style={{ color: GENERAL_TEXT_COLOR }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.heading}
                >
                  Respostas
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    {commentData.comments ? (
                      commentData.comments.length > 0 ? (
                        commentData.comments.map((subComment) => (
                          <SubComment
                            subCommentData={subComment}
                            key={subComment.id}
                          />
                        ))
                      ) : (
                        <Grid item container xs={10} sm={11}>
                          <Grid item container xs={1}>
                            <SubdirectoryArrowRightIcon
                              fontSize="medium"
                              style={{ color: GENERAL_TEXT_COLOR }}
                            />
                          </Grid>
                          <Grid item container xs={11}>
                            <Typography vartiant="body1" component="span">
                              Ainda não há respostas aqui.
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    ) : (
                      <LoadingProgress />
                    )}
                  </Grid>
                  <Grid item container xs={12}>
                    <CreateCommentAnswer commentDataId={commentData.id} />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isConfirmationModalVisible}
        onClose={handleConfirmationModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isConfirmationModalVisible}>
          <Grid
            item
            container
            xs={11}
            sm={4}
            justifyContent="center"
            alignItems="center"
            spacing={3}
            className={classes.paper}
          >
            <Grid
              item
              container
              xs={12}
              justifyContent="flex-end"
              alignItems="center"
              style={{ paddingRight: 0 }}
            >
              <CloseModalButton onClick={handleConfirmationModalClose}>
                <CloseIcon />
              </CloseModalButton>
            </Grid>
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <ConfirmationContent id="transition-modal-title">
                Deseja excluir seu comentário?
              </ConfirmationContent>
            </Grid>
            <Grid
              item
              container
              xs={12}
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <ModalConfirmationButton onClick={handleDeleteCommentClick}>
                Excluir
              </ModalConfirmationButton>
              <ModalConfirmationButton
                cancel={true}
                onClick={handleConfirmationModalClose}
              >
                Cancelar
              </ModalConfirmationButton>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activity: state.activities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sendCommentDeletion,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
