import { Box, Grid, Typography } from '@material-ui/core';
import { ReactComponent as CheckIcon } from 'assets/icons/vector-stroke.svg';
import Button from 'common/components/buttons/button';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { BASE_S3, COMPANY_STATION_ID } from 'config/consts';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appColors } from 'styles/color';
import SimpleBlockSkeleton from '../../../../../common/components/skeleton/simpleBlockSkeleton';
import TextBlockSkeleton from '../../../../../common/components/skeleton/textBlockSkeleton';
import {
  BUTTON_BACKGROUND_COLOR,
  COMPANY_ENTERPRISE_PLAN_BENEFITS,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  FANPROJECTS_STATION_ID,
  GENERAL_TEXT_COLOR,
} from '../../../../../config/consts';
import { getList } from './plansActions';
import PlansOptionsCard from './plansOptionsCard';

const {
  v2: { pink, blue, black },
} = appColors;

const PlanDetail = ({
  selectedPlan,
  onClick,
  buttonLabel,
  isButtonDisabled,
  buttonBackgroundColor,
}) => {
  const { name, description, benefits, amount, days, image, color, discount } =
    selectedPlan;
  return (
    <section
      id="plans-detail"
      style={{ marginTop: 32, marginBottom: 125, minHeight: '110vh' }}
    >
      <GeneralGrid container xs={12} justifyContent="center">
        <GeneralGrid item xs={12} sm={6} md={8}>
          {image ? (
            <img
              src={`${BASE_S3}/${image}`}
              alt="Imagem do plano"
              style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
            />
          ) : null}
          <Typography variant="h4" className="h3-bold mt-4">
            {name}
          </Typography>
          <Typography variant="body1" className="h5 mt-4">
            {description}
          </Typography>
          <Box mt={4}>
            {benefits.map((item) => (
              <Typography variant="body1" className="h5 text-left mt-3">
                <CheckIcon
                  stroke={color}
                  fill={color}
                  style={{ marginRight: 8 }}
                />
                {item.benefit}
              </Typography>
            ))}
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{
              backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
              padding: '60px 0px',
              // border: `2px solid ${GENERAL_TEXT_COLOR}`
            }}
            mt={4}
          >
            <Typography
              varaiant="body1"
              component="h5"
              className="h5-bold text-center"
            >
              {name}
            </Typography>
            {amount ? (
              <p
                className="title text-center mt-2"
                style={{ color: GENERAL_TEXT_COLOR }}
              >
                R${' '}
                {(parseFloat(amount) - parseFloat(discount))
                  .toFixed(2)
                  .replace('.', ',')}
                <span
                  className="h3 text-center"
                  style={{ color: GENERAL_TEXT_COLOR }}
                >
                  /{days === 30 ? 'mês' : days === 183 ? 'semestre' : 'ano'}
                </span>
              </p>
            ) : (
              <p
                className="title text-center mt-5"
                style={{ color: GENERAL_TEXT_COLOR }}
              >
                A negociar
              </p>
            )}
            <Button
              label={buttonLabel}
              onClick={onClick}
              buttonStyle={{
                width: '45%',
                minWidth: '270px',
                marginTop: 32,
                backgroundColor:
                  buttonBackgroundColor || BUTTON_BACKGROUND_COLOR,
              }}
              labelStyle={{
                color:
                  buttonBackgroundColor === 'transparent'
                    ? color
                    : GENERAL_TEXT_COLOR,
              }}
              disabled={isButtonDisabled}
            />
          </Box>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
};

function Plans({ plans, history, auth, getList }) {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const { loading } = plans;
  const {
    user: {
      subscription: { plan },
      plans: plansOptions,
    },
  } = auth;

  useEffect(() => {
    getList(COMPANY_STATION_ID);
  }, [getList]);

  useEffect(() => {
    return () => {
      setSelectedPlan(null);
    };
  }, []);

  if (loading) {
    return (
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        xs={12}
        spacing={0}
        style={{
          minHeight: '100vh',
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          xs={12}
        >
          <TextBlockSkeleton numberOfRows={1} rowsHeight={60} width={200} />
        </Grid>
        <Grid item direction="row" spacing={2} container xs={12}>
          {Array.from(new Array(3)).map((el) => (
            <Grid item container xs={12} sm={4}>
              <SimpleBlockSkeleton width="100%" height={750} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  }

  if (selectedPlan) {
    return (
      <PlanDetail
        selectedPlan={selectedPlan}
        onClick={() =>
          plan
            ? history.push(`/homepage/planos/${selectedPlan.id}/mudar`)
            : history.push(`/homepage/planos/${selectedPlan.id}/comprar`)
        }
        isButtonDisabled={plan ? plan.id === selectedPlan.external_id : false}
        buttonLabel={
          plan
            ? plan.id === selectedPlan.external_id
              ? 'Este é seu plano atual'
              : 'Adquirir esse plano'
            : selectedPlan.color
        }
        buttonBackgroundColor={
          plan
            ? plan.id === selectedPlan.external_id
              ? 'transparent'
              : selectedPlan.color
            : selectedPlan.color
        }
      />
      // </>
    );
  }

  return (
    <React.Fragment id="plans-options">
      <Grid alignItems="center" justifyContent="center" container xs={12}>
        <Typography variant="h4">Planos</Typography>
      </Grid>
      <GeneralGrid
        container
        spacing={3}
        alignItems="flex-start"
        justifyContent="center"
      >
        {plansOptions
          .filter((item) => !item.deletedAt)
          .sort(
            (a, b) =>
              parseFloat(a.amount) -
              parseFloat(a.discount) -
              (parseFloat(b.amount) - parseFloat(b.discount))
          )
          .map((item, index) => (
            <GeneralGrid
              item
              xs={12}
              alignItems="flex-start"
              md={4}
              key={item.id}
              style={{ marginTop: 32 }}
            >
              <PlansOptionsCard
                name={item.name}
                amount={(parseFloat(item.amount) - parseFloat(item.discount))
                  .toFixed(2)
                  .replace('.', ',')}
                benefits={item.benefits}
                description={item.description}
                onClick={() =>
                  plan
                    ? history.push(`/homepage/planos/${item.id}/mudar`)
                    : history.push(`/homepage/planos/${item.id}/comprar`)
                }
                buttonStyle={
                  plan
                    ? plan.id === item.external_id
                      ? { backgroundColor: 'white' }
                      : {}
                    : {}
                }
                buttonLabelStyle={
                  plan
                    ? plan.id === item.external_id
                      ? { color: 'gray', textAlign: 'center' }
                      : { textAlign: 'center' }
                    : { textAlign: 'center' }
                }
                buttonLabel={
                  plan
                    ? plan.id === item.external_id
                      ? 'Este é seu plano atual'
                      : parseFloat(item.amount) >= plan.amount
                      ? 'Fazer upgrade'
                      : 'Fazer downgrade'
                    : item.purchase_text
                }
                days={item.days}
                is_highlighted={item.is_highlighted}
                onDetailClick={() =>
                  setSelectedPlan({
                    ...item,
                    color: index === 0 ? blue : index === 1 ? pink : black,
                  })
                }
                buttonDisabled={plan ? plan.id === item.external_id : false}
              />
            </GeneralGrid>
          ))}
        {COMPANY_STATION_ID === FANPROJECTS_STATION_ID ? (
          <>
            <GeneralGrid item xs={12} md={4} style={{ marginTop: 32 }}>
              <PlansOptionsCard
                name={'Enterprise'}
                enterprisePlan={true}
                description={
                  'Sob medida para quem já lida com seus públicos de forma estruturada e quer transformar esse relacionamento em receita'
                }
                benefits={COMPANY_ENTERPRISE_PLAN_BENEFITS}
                onClick={() => history.push(`/homepage/planos/sobconsulta`)}
                buttonStyle={{ backgroundColor: 'black' }}
                buttonLabelStyle={{ textAlign: 'center' }}
                buttonLabel={'Entre em contato'}
              />
            </GeneralGrid>
          </>
        ) : null}
      </GeneralGrid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    plans: state.plans,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
