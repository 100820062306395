/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@material-ui/core';
import ActivitiesCard from 'common/components/cards/activitiesCard';
import CupomModal from 'common/components/cupomModal/cupomModal';
import Spinner from 'common/components/spinner/spinner';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getListByUserId, setLoading } from './userCouponsActions';

export function UserCoupons(props) {
  useEffect(() => {
    props.getListByUserId(props.auth.user.id);
  }, []);

  const [open, setOpen] = React.useState(false);
  const [voucherItem, setItem] = React.useState(undefined);

  function selectItem(item) {
    setOpen(true);
    setItem(item);
  }

  if (props.userCoupons.loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '92%', backgroundColor: 'transparent' }}
      >
        <Spinner />
      </div>
    );
  }

  if (_.isEmpty(props.userCoupons.list.content)) {
    return (
      <section id="userCoupons-list">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '92vh', backgroundColor: 'transparent' }}
        >
          <Typography variant="h6">
            Você ainda não possui compras feitas
          </Typography>
        </div>
      </section>
    );
  }

  return (
    <>
      <section
        id="vouchers-list"
        style={{
          backgroundColor: 'transparent',
          height: '100vh',
        }}
      >
        <div className="container-fluid px-4 pt-4">
          <div className="row">
            {props.userCoupons.list.content.map((item, index) => {
              return (
                <div
                  className="mb-4 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
                  style={{ cursor: 'pointer' }}
                  onClick={() => selectItem(item)}
                >
                  <ActivitiesCard
                    haveImage
                    image={item.cupom.action.image}
                    title={moment(item.cupom.createdAt).format('LLLL')}
                    text={item.cupom.action.name}
                    callToAction="Ver Comprovante"
                    reward={`Ganhe ${parseFloat(item.cupom.action.points)
                      .toFixed(2)
                      .replace('.', ',')} reais`}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* {voucherItem ? (
        <MaterialModal open={open} onClose={() => setOpen(false)}>
          <div className="container-fluid" style={{ overflow: 'hidden' }}>
            <div
              className="row justify-content-center"
              style={{ backgroundColor: COMPANY_COLORS.primary }}
            >
              <div
                className="col-auto py-2"
                style={{ color: COMPANY_COLORS.font.light }}
              >
                {voucherItem.cupom.is_donation
                  ? 'Doação realizada em '
                  : 'Inscrição realizada em'}{' '}
                {moment(voucherItem.createdAt).format('LLLL')}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-10 station-font-lh-2">
                <div className="row justify-content-center">
                  <div className="col-8 my-5">
                    <h2
                      className="station-font-bold"
                      style={{
                        color: COMPANY_COLORS.font.gray,
                        textAlign: 'center',
                      }}
                    >
                      {voucherItem.cupom.action.name}
                    </h2>
                  </div>
                </div>
                {!voucherItem.cupom.is_donation && (
                  <div className="row">
                    <div className="col-6">
                      <p>{voucherItem.cupom.local}</p>
                    </div>
                    <div className="col-12">
                      <div className="d-flex justify-content-center">
                        <QRCode
                          value={`https://parceiro.rilato.com.br/#/owner/validacao-cupom/${voucherItem.code}`}
                        />
                      </div>

                      <p className="text-center mt-4"> {voucherItem.code}</p>
                    </div>
                  </div>
                )}
                <div className="row justify-content-center">
                  <h5>
                    {`Valor do pagamento: ${String2Currency(
                      voucherItem.price
                    )}`}{' '}
                  </h5>
                </div>
                <div className="row justify-content-center py-5">
                  <div
                    className="col-12"
                    style={{ border: '1px solid black' }}
                  />
                </div>
                <div
                  className="row justify-content-between p-3"
                  style={{ background: COMPANY_COLORS.primary }}
                >
                  <div className="col-4">
                    <img
                      src={`${BASE_S3}/${COMPANY_COLORS.website.footer.company_logo}`}
                      alt="LOGO-PROGRAMA"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                  </div>
                  <div className="col-4">
                    <img
                      src={`${BASE_S3}/${COMPANY_COLORS.website.navbar.station_primary_logo}`}
                      alt="LOGO-EMPRESA"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />
                  </div>
                </div>
                <div className="row justify-content-center mt-5 mb-5">
                  <div className="col-auto">
                    <ButtonSecondary onClick={() => window.print()}>
                      Imprimir ou salvar
                    </ButtonSecondary>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MaterialModal>
      ) : null} */}
      {voucherItem ? (
        <CupomModal open={open} setOpen={setOpen} item={voucherItem} />
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userCoupons: state.userCoupons,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListByUserId,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCoupons);
