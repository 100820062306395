/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import CallToActionCard from 'common/components/cards/callToActionCard';
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
} from 'config/consts';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getActionById,
  setDetail,
  subscribe,
  setSubscribed,
  setFeedbackModal,
  getLottieById,
} from './activitiesActions';
import moment from 'moment';
import MaterialModal from 'common/components/modal/materialModal';
import { BASE_S3 } from 'config/consts';
import { getUserPoints } from 'modules/user/pages/homepage/homepageActions';
import { makeStyles } from '@material-ui/core/styles';
import './activity.css';
import { isMobile } from 'react-device-detect';
import DOMPurify from 'dompurify';
import ReactPlayer from 'react-player';
import { Grid, Typography } from '@material-ui/core';
import { DomPurifyContentContainer } from './styles';
import { getVouchersUrl } from 'helpers/urls';

const QRCode = require('qrcode.react');

const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: isMobile ? '100vh' : '95vh',
  },
  reactPlayer: {
    backgroundColor: 'black',
    float: 'left',
  },
}));

function ActivityPresentialCourse(props) {
  const classes = useStyles();

  useEffect(() => {
    props.getLottieById(props.activities.detail.feedback_lottie_id);
  }, []);

  function subscribeToCourse(values) {
    props.subscribe(values, props.auth.user.id);
  }

  return (
    <>
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          style={{
            marginBottom: '1.5rem',
          }}
        >
          <Typography variant="body1">
            <DomPurifyContentContainer
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.activities.detail.description),
              }}
            />
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          {props.activities.detail.action_videos.length > 0 ? (
            <Grid item container xs={12} justyfy="center" alignItems="center">
              <ReactPlayer
                width={'100%'}
                height={isMobile ? '17rem' : '30rem'}
                controls
                className={classes.reactPlayer}
                key={props.activities.detail.action_videos[0].video.url}
                url={props.activities.detail.action_videos[0].video.url}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <CallToActionCard
            public={props.public || false}
            website={props.website}
            history={props.history}
            auth={props.auth}
            activity={props.activities.detail}
            onClick={
              props.callToActionLink
                ? () => props.history.push(props.callToActionLink)
                : (values) => subscribeToCourse(values)
            }
            {...props}
          />
        </Grid>
      </Grid>

      {/* {props.activities.feedbackModal ? (
        <FeedbackModal
          visible={props.activities.feedbackModal}
          onCloseRoute="/homepage"
          history={props.history}
          setFeedbackModal={props.setFeedbackModal}
          secondRoute="/homepage"
          firstText={'Abrir Voucher'}
          secondText="Página inicial"
          activity={props.activities.detail}
          firstActionType="function"
          firstActionFunction={() => props.setSubscribed(true)}
          lottie={props.activities.lottieDetail}
          cleanupFunction={() => props.getUserPoints(props.auth.user)}
        />
      ) : null} */}

      <MaterialModal
        showTitle
        PaperProps={{ classes: { root: classes.paper } }}
        open={props.activities.subscribed}
        onClose={() => props.setSubscribed(false)}
        fullScreen={isMobile ? true : false}
      >
        <div
          className="container-fluid d-flex-column justify-content-space-around h-100"
          style={{ backgroundColor: 'transparent' }}
        >
          <div className="row justify-content-center mt-5">
            <div className="col-8 col-md-4">
              <img
                src={`${BASE_S3}/${COMPANY_COLORS.homepage.sidebar.logo}`}
                alt="LOGO-EMPRESA"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-auto text-center">
              <Typography variant="h4">
                {props.activities.detail.name}
              </Typography>
            </div>
          </div>
          <div className="row justify-content-center mt-1">
            <div className="col-auto text-center">
              <Typography variant="body2">{props.auth.user.name}</Typography>
            </div>
          </div>
          <div className="row justify-content-center mt-3">
            <div className="col-md-8 col-11">
              <h6 className="text-muted text-center">{`Voucher gerado em: ${moment(
                props.activities.createdAt
              ).format('LLLL')}`}</h6>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-auto">
              <QRCode
                value={getVouchersUrl(
                  props.activities.detail,
                  props.activities.code
                )}
              />
            </div>
          </div>
          <div className="row justify-content-center mt-3">
            <div className="col-auto">
              <h5 className="text-muted">{props.activities.code}</h5>
            </div>
          </div>
          {props.activities.detail.presential_courses[0].rules ? (
            <div className="row justify-content-center mt-4">
              <div className="col-md-auto col-11">
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      props.activities.detail.presential_courses[0].rules
                    ),
                  }}
                ></div>
              </div>
            </div>
          ) : null}

          <div className="row justify-content-center mt-3">
            <div className="col-md-auto col-11">
              <h6 className="text-muted mb-0 text-center">
                Este voucher deverá ser utilizado até:
              </h6>
            </div>
          </div>
          <div className="row justify-content-center mt-0">
            <div className="col-md-auto col-11">
              <h6 className="text-muted">
                {moment(
                  props.activities.detail.presential_courses[0].date
                ).format('LLLL')}
              </h6>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-md-auto col-11">
              <div
                className={'py-3 station-font-medium'}
                style={{
                  background: BUTTON_BACKGROUND_COLOR,
                  color: BUTTON_TEXT_COLOR,
                  borderRadius: 8,
                  paddingLeft: isMobile ? 40 : 140,
                  paddingRight: isMobile ? 40 : 140,
                  cursor: 'pointer',
                  fontSize: '1rem',
                  textAlign: 'center',
                }}
                onClick={() => window.print()}
              >
                {isMobile ? 'Salvar' : 'Imprimir'}
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-2 mb-5">
            <div className="col-auto">
              <button
                onClick={(event) => {
                  event.preventDefault();
                  props.setSubscribed(false);
                  window.open('#/homepage', "_self");
                }}
                style={{ textDecoration: 'underline', color: '#505050' }}
              >
                Página inicial
              </button>
            </div>
          </div>
        </div>
      </MaterialModal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionById,
      setDetail,
      subscribe,
      setSubscribed,
      setFeedbackModal,
      getLottieById,
      getUserPoints,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityPresentialCourse);
