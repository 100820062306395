import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment';
import React, { useMemo } from 'react';
import { SimpleButton } from '../buttons/simpleButton';
import MultiSelectDisplayCard from './components/displayMultiSelect';
import RadioSelectDisplayCard from './components/displayRadioSelect';
import ShoppingAbstract from './components/ShoppingAbstract';
import { QRCodeContainer } from './styles';

const QRCode = require('qrcode.react');

function CupomAbstract({ item }) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));

  const url = useMemo(() => {
    if (item.action.station_id && item.action.partner_id) {
      if (process.env.REACT_APP_API_KEY === 'production') {
        return `https://parceiro.rilato.com.br/#/qr-code-validator/product/${item.code}`;
      } else if (process.env.REACT_APP_API_KEY === 'test') {
        return `https://sandbox-parceiro.rilato.com.br/#/qr-code-validator/product/${item.code}`;
      }
      return `https://localhost:3000/#/qr-code-validator/product/${item.code}`;
    }
    if (item.action.station_id && !item.action.partner_id) {
      if (process.env.REACT_APP_API_KEY === 'production') {
        return `https://app.rilato.com.br/#/qr-code-validator/product/${item.code}`;
      } else if (process.env.REACT_APP_API_KEY === 'test') {
        return `https://sandbox-gestor.rilato.com.br/#/qr-code-validator/product/${item.code}`;
      }
      return `https://localhost:3000/#/qr-code-validator/product/${item.code}`;
    }
  }, [item.action.partner_id, item.action.station_id, item.code]);

  const productsBought = useMemo(() => {
    const itemsId = item.single_product_orders.map((i) => i.product_id);
    const uniqueItemsId = Array.from(new Set(itemsId));

    return item.action.products.filter(
      (item) => uniqueItemsId.indexOf(item.id) > -1
    );
  }, [item.action.products, item.single_product_orders]);

  return (
    <>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="flex-start"
        xs={12}
        style={{
          minHeight: '100vh',
        }}
      >
        <Grid item container justifyContent="center" xs={12} sm={10} md={12}>
          {/* Title */}
          <Grid
            item
            container
            direction="column"
            justifyContent={xs ? 'center' : 'flex-start'}
            alignItems="flex-start"
            xs={12}
            md={8}
          >
            <Typography variant="h4" className="mb-4">
              Comprovantes de compra
            </Typography>
            <Typography variant="body1">
              {moment(item.createdAt).format('LLLL')}
            </Typography>
            <Typography variant="body1">{`Id da compra: #${item.id}`}</Typography>
            <Typography variant="body1">{`Valor total da compra: R$ ${item.total_final_value.toFixed(
              2
            )}`}</Typography>
            <Typography variant="body1">
              {`Status: ${item.status}`}
              {item.status === 'Parcialmente estornada' &&
                ` (R$ ${(
                  item.total_final_value *
                  (item.refunded_percentage / 100)
                ).toFixed(2)})`}
            </Typography>
          </Grid>
          {/* QR Code */}
          <Grid
            item
            container
            justifyContent={xs || sm ? 'center' : 'flex-end'}
            alignItems="flex-start"
            xs={12}
            md={4}
          >
            <QRCodeContainer>
              <footer
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <QRCode value={url} />
                <span>{item.code}</span>
              </footer>
            </QRCodeContainer>
          </Grid>
          {productsBought.map((product) =>
            item.action.only_one_product ? (
              <RadioSelectDisplayCard key={product.id} data={product} />
            ) : (
              <MultiSelectDisplayCard
                key={product.id}
                data={product}
                items={item.single_product_orders}
              />
            )
          )}
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="center"
            xs={12}
          >
            <ShoppingAbstract item={item} productsBought={productsBought} />
          </Grid>
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            <SimpleButton
              onClick={() => window.print()}
              type="button"
              width={250}
              height={50}
            >
              Imprimir
            </SimpleButton>
            <a href="#/homepage" className="mt-3">
              <Typography variant="subtitle2">Página inicial</Typography>
            </a>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CupomAbstract;
