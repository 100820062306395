import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Container } from './styles';
import CreatePostForm from './components/createPost';
import Post from './components/post';
import { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAllPosts, getAllPostsByLimit } from './FeedActions';
import LoadingProgress from '../../../../../common/components/progress/loading';
import { Box, Typography } from '@material-ui/core';
import { COMPANY_SIGNED_IN_BACKGROUND_COLOR } from '../../../../../config/consts';

function Feed(props) {
  const [limit, setLimit] = useState(3);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    props.getAllPosts(props.auth.user.station_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auth.user.station_id]);

  useEffect(() => {
    if (limit > props.feed.list.length + 3) {
      setHasMore(false);
    }
  }, [limit, props.feed.list.length]);

  const fetchData = async () => {
    let newLimit = limit + 3;
    setLimit(newLimit);
    await props.getAllPostsByLimit(props.auth.user.station_id, newLimit);
  };

  return (
    <section
      id="downloads-list"
      style={{ backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR, minHeight: '100vh' }}
    >
      <Typography variant="h4">
        Mural
      </Typography>
      <Container>
        <CreatePostForm limit={limit} />
        {
          // carrega enquanto espera o request dos posts
          props.feed.isGettingPostList ? (
            <Box display="flex" justifyContent="center">
              <LoadingProgress>Carregando...</LoadingProgress>
            </Box>
          ) : (
            <>
              {/* Verifica se há postagens */}
              {props.feed.list.length > 0 ? (
                <InfiniteScroll
                  dataLength={props.feed.list.length}
                  next={fetchData}
                  hasMore={hasMore}
                  scrollThreshold={0.5}
                  endMessage={
                    <Typography
                      variant="h6"
                      style={{
                        textAlign: 'center',
                        marginTop: 15,
                        fontSize: 16,
                      }}
                    >
                      Isso é tudo por enquanto!
                    </Typography>
                  }
                >
                  {props.feed.list.map((post) => (
                    <Post info={post} limit={limit} />
                  ))}
                </InfiniteScroll>
              ) : (
                <Typography
                  variant="h6"
                  style={{
                    margin: '20px auto',
                    padding: 20,
                    width: 'fit-content',
                  }}
                >
                  Ainda não há posts no feed. Seja o primeiro e diga o que esta
                  pensando.
                </Typography>
              )}
            </>
          )
        }
      </Container>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    feed: state.feed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getAllPosts, getAllPostsByLimit }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Feed);
