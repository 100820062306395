import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FullButton } from 'common/components/buttons/fullButton';
import { String2Currency } from 'helpers/dataTransformers';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, Form, getFormValues, reduxForm } from 'redux-form';
import { GeneralTitle } from '../../../../../../common/styles/styles';
import { customCurrencyChanger } from '../../../../../../helpers/utils';
import ItemCardMultiSelect from '../../components/ItemCard/multiSelect';
import {
  deleteProductFromCart,
  singleProductOrdersReservation,
  updateShoppingCart,
} from '../../userShoppingCartActions';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '',
    minHeight: '90vh'
  },
  flexForm: {
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paymentInfoItem: {
    margin: '.2rem 0',
    fontWeight: 'bold',
    letterSpacing: '0.25px',
  },
  paymentInfoValue: {
    margin: '.2rem 0',
    letterSpacing: '0.25px',
  },
}));

let Cart = function ({
  setStep,
  formValues,
  deleteProductFromCart,
  handleSubmit,
  singleProductOrdersReservation: createReservation,
  updateShoppingCart,
  userShoppingCart: { stepLoad },
  stationData,
  auth,
}) {
  const paymentInfoItems = ['Entrega', 'Produtos', 'Subtotal', 'Cashback'];
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  // const md = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();
  const currencyData = {
    currency_multiplier: stationData.currency_multiplier,
    currency_name: stationData.currency_name,
    currency_name_position: stationData.currency_name_position,
  };

  const handleForward = useCallback(
    (values) => {
      console.log(values);
      const hasPhysicalProduct = values.products.find(
        (item) => !item.detail.action.digital_product
      );
      console.log(hasPhysicalProduct);
      if (hasPhysicalProduct) {
        setStep((step) => step + 1);
      } else {
        setStep((step) => step + 2);
      }
    },
    [setStep]
  );

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  function remove(productId) {
    deleteProductFromCart(productId);
  }

  function renderProducts(props) {
    return props.fields.map((item, index) => {
      return (
        <Field
          shouldUpdate
          canDelete
          key={indexedDB}
          onPressDelete={remove}
          name={`${item}`}
          component={ItemCardMultiSelect}
        />
      );
    });
  }

  function subtotalProdutos() {
    let subtotal = 0;
    if (formValues && formValues.products) {
      for (let item of formValues.products) {
        if (parseFloat(item.detail.discount_value) > 0) {
          subtotal =
            parseFloat(item.detail.value) * parseInt(item.quantity) -
            parseFloat(item.detail.discount_value) * parseInt(item.quantity) +
            subtotal;
        } else if (parseFloat(item.detail.value) > 0) {
          subtotal =
            parseFloat(item.detail.value) * parseInt(item.quantity) + subtotal;
        }
      }
    }
    return subtotal;
  }

  function subtotalFrete() {
    let subtotal = 0;
    if (formValues && formValues.products) {
      for (let item of formValues.products) {
        if (item.location && item.location.deliveryLocation) {
          const deliveryLocationPrice =
            item.detail.action.delivery_locations.filter(
              (deliveryLocation) =>
                parseInt(deliveryLocation.id) ===
                parseInt(item.location.deliveryLocation)
            )[0].price;

          subtotal = subtotal + parseFloat(deliveryLocationPrice);
        }
      }
    }
    return subtotal;
  }

  function subtotal() {
    return (subtotalFrete() + subtotalProdutos()).toFixed(2);
  }

  function cashback() {
    let cashback = 0;
    if (formValues && formValues.actions) {
      for (let item of formValues.actions) {
        if (parseFloat(item.action.points) > 0) {
          cashback = parseFloat(item.action.points) + cashback;
        }
        if (parseFloat(item.action.additional_cashback) > 0) {
          cashback = parseFloat(item.action.additional_cashback) + cashback;
        }
      }
    }
    return cashback.toFixed(2);
  }

  function submit(values) {
    const body = {
      user_id: auth.user.id,
    };
    for (let product of values.products) {
      body[`product-${product.id}`] = product.quantity;
    }
    updateShoppingCart(values.products, body, () => handleForward(values));
  }

  return (
    <Grid
      className={classes.root}
      item
      container
      justifyContent="center"
      alignItems="flex-start"
      xs={12}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        style={{
          paddingTop: sm || xs ? '1rem' : '',
        }}
      >
        <Form
          role="form"
          noValidate
          onSubmit={handleSubmit(submit)}
          className={classes.flexForm}
        >
          {/* MAIN */}
          <Grid container justifyContent="center" alignItems="center" xs={11} md={12}>
            <Grid
              item
              container
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
            >
              <Typography variant="h1">
                <GeneralTitle
                  xs={xs}
                  sm={sm}
                  marginBottom={xs ? '24px' : '48px'}
                >
                  Meu carrinho
                </GeneralTitle>
              </Typography>
            </Grid>
            <FieldArray
              name="products"
              component={renderProducts}
              formValues={formValues}
            />
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              xs={12}
              style={{
                margin: '.5rem 0',
              }}
            >
              <Grid
                item
                container
                xs={6}
                sm={9}
                md={10}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                {paymentInfoItems.map((item) => (
                  <Typography
                    variant="body1"
                    className={classes.paymentInfoItem}
                  >
                    {item}
                  </Typography>
                ))}
              </Grid>
              <Grid
                item
                container
                xs={6}
                sm={3}
                md={2}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Typography
                  variant="body1"
                  className={classes.paymentInfoValue}
                >
                  {String2Currency(subtotalFrete())}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.paymentInfoValue}
                >
                  {String2Currency(subtotalProdutos())}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.paymentInfoValue}
                >
                  {String2Currency(subtotal())}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.paymentInfoValue}
                >
                  {customCurrencyChanger(currencyData, cashback())}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent={sm || xs ? 'center' : 'space-between'}
              alignItems="center"
              xs={12}
              style={{
                padding: '1.7rem 1.5rem',
                borderRadius: sm || xs ? '10px' : '0 0 10px 10px',
                marginTop: '.6rem',
              }}
            >
              <Grid
                item
                container
                justifyContent={sm || xs ? 'center' : 'flex-start'}
                alignItems="flex-start"
                xs={12}
                md={7}
                style={{
                  marginBottom: sm || xs ? '.5rem' : null,
                  height: sm || xs ? '60px' : '',
                }}
              >
                <Typography
                  gutterBottom={true}
                  component="p"
                  style={{ textAlign: 'start', maxWidth: '80%' }}
                  variant="body1"
                >
                  Na próxima etapa você irá fornecer as informações de entrega.
                </Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent={sm || xs ? 'center' : 'flex-end'}
                alignItems="center"
                spacing={1}
                xs={12}
                md={4}
              >
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  md={9}
                  style={{ marginTop: sm || xs ? '.6rem' : null }}
                >
                  <FullButton
                    progress={stepLoad}
                    disabled={stepLoad}
                    classNameProp="py-3 px-4"
                    type="submit"
                  >
                    Ir para entrega
                  </FullButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Grid>
  );
};

Cart = reduxForm({
  form: 'userShoppingCartForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Cart);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activity: state.activities.detail,
    formValues: getFormValues('userShoppingCartForm')(state),
    userShoppingCart: state.userShoppingCart,
    stationData: state.website.stationData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      deleteProductFromCart,
      singleProductOrdersReservation,
      updateShoppingCart,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
