import {
  Avatar,
  Box,
  Menu,
  MenuItem,
  Typography,
  // makeStyles,
  // useTheme,
  // Grid,
  // Container
} from '@material-ui/core';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { CustomizedInputBase } from 'common/components/fields/fields';
import {
  BASE_S3,
  BRB_FLA_STATION_ID,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_FAMILY,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
  COMPANY_FONT_FAMILY,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
} from 'config/consts';
import { getNameInitials } from 'helpers/utils';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  COMPANY_STATION_ID,
  FANPROJECTS_STATION_ID,
  GENERAL_TEXT_COLOR,
  USER_TOKEN,
} from '../../../../config/consts';
import { hexToRgb } from '../../../../helpers/utils';
import {
  // ChangeStationButton,
  DropdownItem,
  NavigationItem,
  NavigationSubItem,
} from './styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import AccountCircle from '@material-ui/icons/AccountCircle';

// const useStyles = makeStyles(() => ({
//   root: {
//     padding: 0,
//   },
//   headerLogo: {
//     height: 50,
//     cursosr: 'pointer',
//   },
//   discoveryButton: {
//     width: 98,
//     height: 32,
//     borderRadius: 18,
//     marginRight: 27,
//     fontStyle: 'normal',
//     fontWeight: 'normal',
//     fontSize: '16px',
//     lineHeight: '160%',
//     cursor: 'pointer',
//     background: '#E5E5E5',
//   }
// }));

const urlFan =
  process.env.REACT_APP_API_KEY === 'production'
    ? `https://app.rilato.com.br/#/login-token`
    : process.env.REACT_APP_API_KEY === 'test'
    ? `http://sandbox-gestor.rilato.com.br/#/login-token`
    : 'http://localhost:3000/#/login-token';

function OwnerNavigation(props) {
  const split = window.location.hash.split('#')[1];
  let route = split;
  const [value, setValue] = useState(undefined);
  // const classes = useStyles();
  // const theme = useTheme();
  // const xs = useMediaQuery(theme.breakpoints.only('xs'));
  // const sm = useMediaQuery(theme.breakpoints.only('sm'));
  // const md = useMediaQuery(theme.breakpoints.up('md'))

  function handleFirstStateActive() {
    for (let i in props.navBarItems) {
      if (props.navBarItems[i].link === route) {
        return props.navBarItems[i].id;
      } else {
        if (props.navBarItems[i].children) {
          for (let item in props.navBarItems[i].children) {
            if (props.navBarItems[i].children[item].link === route) {
              return props.navBarItems[i].id;
            }
          }
        }
      }
    }
  }

  //Escolha de empresa da plataforma
  /*   function handleEstablishmentChange(establishmentId) {
    let newEstablishmentsList = [...props.auth.user.establishments];
    for (
      let index = 0;
      index < props.auth.user.establishments.length;
      index++
    ) {
      if (props.auth.user.establishments[index].id === establishmentId) {
        [newEstablishmentsList[0], newEstablishmentsList[index]] = [
          newEstablishmentsList[index],
          newEstablishmentsList[0],
        ];
      }
    }
    return props.changeEstablishment(newEstablishmentsList);
  } */

  const [anchorEl, setAnchorEl] = useState(null);
  const [active, setActive] = useState(handleFirstStateActive());
  const openAnchor = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClickChange() {
    if (value) {
      props.history.push(`/homepage/search/${value}`);
    } else {
    }
  }

  function handleChange(e) {
    if (e.key === 'Enter') {
      if (e.target.value) {
        e.preventDefault();
        props.history.push(`/homepage/search/${e.target.value}`);
      } else {
      }
    }
  }

  const handleOpenPerfil = () => {
    props.history.push('/homepage/perfil');
  };

  return (
    <>
      {/* <Container
        maxWidth={false}
        className={classes.root}
        component="main"
        style={{
          borderBottom: '1px solid #D2D2D2',
          boxShadow: '2px 0px 4px  #D2D2D2',
        }}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          xs={12}
        >
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            xs={12}
            md={8}
            style={{
              maxWidth: '1120px',
              minWidth: md ? '960px' : null,
              padding: '.8rem 0',
              backgroundColor: 'pink'
            }}
          >
            <Grid item container justify="center" alignItems="center" xs={6} md={6}>
              <Grid
                item
                container
                xs={3}
                md={4}
              >
                <img
                  src={`${BASE_S3}/${COMPANY_COLORS.homepage.sidebar.logo}`}
                  className={classes.headerLogo}
                  // className="img-fluid"
                  alt="station_img"
                  onClick={() => props.history.push('/homepage')}
                />
              </Grid>
              <Grid
                item
                container
                justify={md ? "flex-start" : sm ? "center" : xs && "flex-end"}
                xs={9}
                md={8}
              >
                {
                  FANPROJECTS_STATION_ID === COMPANY_STATION_ID
                    ? (
                      <Box
                        display="flex"
                        justifyContent={md ? "flex-start" : "center"}
                        alignItems="center"
                      >
                        <ChangeStationButton>
                          Descobrir
                        </ChangeStationButton>
                        <ChangeStationButton
                          onClick={() => {
                            window.location.href = `${urlFan}/${JSON.parse(localStorage.getItem(USER_TOKEN)).split(
                              ' '
                            )[1]
                              }`;
                            return null;
                          }}
                        >
                          Gerenciar
                        </ChangeStationButton>
                      </Box>
                    ) : null
                }
              </Grid>
            </Grid>
            <Grid item container justify="flex-end" alignItems="center" xs={6} md={6}>
              <Grid
                item
                container
                justify="center"
                alignItems="center"
                xs={12}
                sm={5}
                style={{
                  minWidth: '227px',
                  maxWidth: '260px',
                }}
              >
                <CustomizedInputBase
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  onKeyDown={(e) => handleChange(e)}
                  onClick={() => handleClickChange()}
                  meta={{}}
                  label="Pesquisar Atividade"
                  name="search"
                  margin="normal"
                  required
                />
              </Grid>
              <Grid
                item
                container
                justify="flex-end"
                alignItems="center"
                xs={1}
              >
                {
                  props.auth.user.image
                    ? (
                      <img
                        onClick={handleMenu}
                        style={{ borderRadius: 15, width: 30, height: 30 }}
                        src={`${BASE_S3}/${props.auth.user.image}`}
                        alt="station_img"
                      />
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          backgroundColor: BUTTON_BACKGROUND_COLOR,
                          cursor: 'pointer',
                          height: 35,
                          width: 35,
                          borderRadius: '50%',
                          fontSize: 17,
                          color: BUTTON_TEXT_COLOR,
                          fontFamily: BUTTON_FONT_FAMILY,
                        }}
                        onClick={handleMenu}
                      >
                        {getNameInitials(props.auth.user.name)}
                      </Box>
                    )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container> */}

      <div>
        <div
          style={{
            width: '100%',
            backgroundColor: 'transparent',
            height: 100,
            // // boxShadow: '0px 5px 3px -5px #111',
            // '-webkit-box-shadow': headerBoxShadow,  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
            // '-moz-box-shadow': headerBoxShadow,  /* Firefox 3.5 - 3.6 */
            // 'box-shadow': headerBoxShadow,
            boxShadow: `0 1px 30px 0 ${hexToRgb(GENERAL_TEXT_COLOR, '20%')}`,
            position: 'relative',
            zIndex: 100,
            paddingTop: '',
            marginBottom: '2rem',
          }}
        >
          {/* Container do primeiro menu */}
          <div
            className="container h-100"
            style={{ maxWidth: 1180, paddingTop: '.5rem', paddingBottom: '' }}
          >
            <div className="col-12 d-flex justify-content-between align-items-center h-100 flex-wrap">
              {/* Flex da imagem + Botões */}
              <div className="d-flex align-items-center">
                {/* Imagem da estação */}
                <img
                  src={`${BASE_S3}/${COMPANY_COLORS.homepage.sidebar.logo}`}
                  style={{ height: 50, marginRight: 27, cursor: 'pointer' }}
                  className="img-fluid"
                  alt="Imagem da estação"
                  onClick={() => props.history.push('/homepage')}
                />
                {/* Botões */}
                {FANPROJECTS_STATION_ID === COMPANY_STATION_ID ? (
                  <>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        width: 98,
                        height: 32,
                        borderRadius: 18,
                        marginRight: 27,
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '16px',
                        lineHeight: '160%',
                        cursor: 'pointer',
                        background: '#E5E5E5',
                      }}
                    >
                      Descobrir
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        border: '1px solid #282828',
                        width: 98,
                        height: 32,
                        borderRadius: 18,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        window.location.href = `${urlFan}/${
                          JSON.parse(localStorage.getItem(USER_TOKEN)).split(
                            ' '
                          )[1]
                        }`;
                        return null;
                      }}
                    >
                      Gerenciar
                    </div>
                  </>
                ) : null}
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <CustomizedInputBase
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  onKeyDown={(e) => handleChange(e)}
                  onClick={() => handleClickChange()}
                  meta={{}}
                  name="search"
                  margin="normal"
                  required
                  style={{ fontFamily: COMPANY_FONT_FAMILY }}
                />
                <div>
                  <div style={{ paddingLeft: 20, cursor: 'pointer' }}>
                    {props.auth.user.image ? (
                      <>
                        <Avatar
                          onClick={handleMenu}
                          style={{ width: 35, height: 35 }}
                          src={`${BASE_S3}/${props.auth.user.image}`}
                          alt="station_img"
                        />
                      </>
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          backgroundColor: BUTTON_BACKGROUND_COLOR,
                          cursor: 'pointer',
                          height: 35,
                          width: 35,
                          borderRadius: '50%',
                          fontSize: 17,
                          color: BUTTON_TEXT_COLOR,
                          fontFamily: BUTTON_FONT_FAMILY,
                        }}
                        onClick={handleMenu}
                      >
                        {getNameInitials(props.auth.user.name)}
                      </Box>
                    )}
                  </div>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    MenuListProps={{ disablePadding: true }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={openAnchor}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={handleOpenPerfil}
                      style={{
                        backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                        marginTop: 0,
                        display:
                          COMPANY_STATION_ID === BRB_FLA_STATION_ID
                            ? 'none'
                            : '',
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                          color: GENERAL_TEXT_COLOR,
                        }}
                      >
                        Perfil
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => props.history.push('/logout')}
                      style={{
                        backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                        marginBottom: 0,
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                          color: GENERAL_TEXT_COLOR,
                        }}
                      >
                        Sair
                      </Typography>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: 'transparent',
                  height: 56,
                  minWidth: '100%',
                }}
              >
                <div
                  className="container h-100 "
                  style={{ maxWidth: 1180, padding: 0 }}
                >
                  <div
                    className="col-12 d-flex justify-content-between"
                    style={{ padding: 0 }}
                  >
                    <div
                      className="d-flex justify-content-start align-items-center flex-wrap"
                      style={{ height: 56, padding: 0 }}
                    >
                      {props.navBarItems.map((item, index) => (
                        <div
                          key={index}
                          className="dropdown"
                          style={{ backgroundColor: 'transparent' }}
                        >
                          {item.children?.length > 0 ? (
                            <>
                              <NavigationItem
                                // className={`dropdown-toggle`}
                                type="button"
                                data-toggle="dropdown"
                                id={'dropdownMenu' + index}
                                aria-haspopup="true"
                                active={active}
                                item={item}
                                companyColors={COMPANY_COLORS}
                                style={{
                                  // marginBottom: 10,
                                  backgroundColor: 'inherit',
                                  '&button:focus': {
                                    outline:
                                      '0px auto -webkit-focus-ring-color',
                                    outlineOffset: '0px',
                                  },
                                }}
                                // onClick={() => {
                                //   props.history.push(subitem.route);
                                //   setActive(item.id);
                                // }}
                              >
                                <Typography variant="subtitle1">
                                  {item.primaryText}
                                </Typography>
                                {/* <ArrowDropDownIcon /> */}
                                <KeyboardArrowDownIcon />
                              </NavigationItem>

                              {item.children?.length > 0 ? (
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby={'dropdownMenu' + index}
                                  style={{
                                    backgroundColor:
                                      COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                                    paddingTop: '.7rem',
                                    width: '200px',
                                    boxShadow: `0 1px 15px 5px ${hexToRgb(
                                      GENERAL_TEXT_COLOR,
                                      '15%'
                                    )}`,
                                    borderRadius: '0px 0px 6px 6px',
                                  }}
                                >
                                  {item.children.map((subitem, index) => (
                                    <NavigationSubItem
                                      key={index}
                                      className="dropdown-item"
                                      type="button"
                                      companyColors={COMPANY_COLORS}
                                      style={{
                                        marginBottom: 10,
                                        // backgroundColor: 'inherit',
                                        borderRadius: 0,
                                        '&button:focus': {
                                          outline:
                                            '0px auto -webkit-focus-ring-color',
                                          outlineOffset: '0px',
                                          borderRadius: 0,
                                        },
                                      }}
                                      onClick={() => {
                                        props.history.push(subitem.route);
                                        setActive(item.id);
                                      }}
                                    >
                                      <DropdownItem>
                                        <Typography
                                          variant="subtitle1"
                                          style={{
                                            margin: '0 0 0 1rem',
                                            whiteSpace: 'break-spaces',
                                          }}
                                        >
                                          {subitem.primaryText}
                                        </Typography>
                                      </DropdownItem>
                                    </NavigationSubItem>
                                  ))}
                                </div>
                              ) : null}
                            </>
                          ) : (
                            <NavigationItem
                              type="button"
                              onClick={() => {
                                props.history.push(item.route);
                                setActive(item.id);
                              }}
                              active={active}
                              item={item}
                              companyColors={COMPANY_COLORS}
                            >
                              <Typography variant="subtitle1">
                                {item.primaryText}
                              </Typography>
                            </NavigationItem>
                          )}
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        height: 56,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {props.points}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerNavigation);
