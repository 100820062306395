import { Box, Grid } from '@material-ui/core';
import React from 'react';
import TextCard from '../../../../../common/components/cards/textCard';
import RenderLegalActivities from '../components/renderLegalActivities';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getActionsByStationId } from '../../activities/activitiesActions';
import { getActionsByCategory } from '../../categories/categoriesActions';
import { setLoading } from './legalActions';

function Legal(props) {
  function selectActivity(id) {
    props.setLoading(true);
    props.history.push(`/homepage/atividades/${id}`);
  }

  return (
    <section id="legal">
      <p className="body-title mt-4">Processos Judiciais</p>
      <p className="h4 mt-4" style={{ color: '#838383', maxWidth: '100%' }}>
        Os serviços da Consultoria Jurídica do Sindilegis auxiliam e representam
        nossos filiados em ações judiciais individuais e coletivas em diversas
        áreas do direito. Clique nas abas abaixo e veja os serviços oferecidos e
        como utilizá-los.
      </p>
      <Grid container style={{ marginTop: 64 }}>
        <RenderLegalActivities
          categories={props.categories}
          history={props.history}
          selectActivity={selectActivity}
        />
      </Grid>
      <Grid container spacing={3} style={{ marginTop: 30 }}>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/consultoria-juridica/processos">
              Processos e procedimentos
            </TextCard>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/consultoria-juridica/agendamentos">
              Agendar consulta com advogado
            </TextCard>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
    categories: state.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionsByCategory,
      getActionsByStationId,
      setLoading
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Legal);
