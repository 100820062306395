import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreditCards from './methods/creditCards';
import { Container } from './styles';

function PaymentMethods({ shoppingInfo }) {
  return (
    <Container>
      <CreditCards shoppingInfo={shoppingInfo} />
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);
