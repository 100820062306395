import { Grid, Typography } from '@material-ui/core';
import GenericTabs from 'common/components/navigation/actionsTab/genericTabs';
import React from 'react';
import { connect } from 'react-redux';
import ExtractUsedCredits from './extractUsedCredits';
import ExtractUserCredits from './extractUserCredits';

function ExtractGeneral() {
  return (
    <Grid item container xs={12} style={{ minHeight: '100vh' }}>
      <Grid item xs={12} md={9}>
      <Typography variant="h4">
          Extratos
        </Typography>
      </Grid>
      <GenericTabs
        style={{
          padding: 0
        }}
        tabs={[
          {
            label: `Créditos Utilizados`,
            screen: <ExtractUsedCredits></ExtractUsedCredits>,
          },
          {
            label: `Créditos de Premiação`,
            screen: <ExtractUserCredits></ExtractUserCredits>,
          },
        ]}
      />
    </Grid>
  );
}

export default connect()(ExtractGeneral);
