import MomentUtils from '@date-io/moment';
import {
  Avatar,
  Box,
  FormGroup,
  FormHelperText,
  InputLabel,
  ListItemText,
  // Menu,
  MenuItem,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckIcon from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SearchIcon from '@material-ui/icons/Search';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import {
  BASE_S3,
  COMPANY_COLORS,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  GENERAL_TEXT_COLOR,
  SIGNED_IN_NAV_TEXT_COLOR,
} from 'config/consts';
import _ from 'lodash';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import CreditCardInput from 'react-credit-card-input';
import Dropzone from 'react-dropzone';
import ReCAPTCHA from 'react-google-recaptcha';
import { FontIcon } from 'react-md';
import { stylesheets } from 'styles/stylesheets';
import { appColors } from '../../../styles/color';
import Grid from '../layout/grid/grid';
import Row from '../layout/grid/row';
import IF from '../operador/if';
import './fields.css';
import InputEasyCutImage from './inputEasyCutImage';
import InputImage from './inputImage/index';

export class InputAuth extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <IF test={!this.props.hide}>
        <Grid cols={this.props.cols} style={this.props.style}>
          <div className={`form-group ${touched && error && 'has-error'}`}>
            <TextField
              {...this.props.input}
              id={this.props.id}
              label={this.props.label}
              type={this.props.type}
              lineDirection="center"
              name={this.props.name}
              floating={true}
              className={this.props.className}
            />
            {touched && error && <span className="help-block">{error}</span>}
          </div>
        </Grid>
      </IF>
    );
  }
}

export class CreditCardInputLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cvc: '',
      cardNumber: '',
      expiry: '',
    };
  }

  handleCardNumberChange = (e) => {
    this.setState({ ...this.state, cardNumber: e.target.value }, () => {
      this.props.input.onChange(this.state);
    });
  };

  handleCardExpiryChange = (e) => {
    this.setState({ ...this.state, expiry: e.target.value }, () => {
      this.props.input.onChange(this.state);
    });
  };

  handleCardCVCChange = (e) => {
    this.setState({ ...this.state, cvc: e.target.value }, () => {
      this.props.input.onChange(this.state);
    });
  };

  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <div className={`form-group ${touched && error && 'has-error'}`}>
        <CreditCardInput
          customTextLabels={{
            invalidCardNumber: 'O número do cartão é inválido!',
            expiryError: {
              invalidExpiryDate: 'A data de expiração é inválida',
              monthOutOfRange: 'O mês de expiração deve estar entre 01 e 12',
              yearOutOfRange: 'O ano da expiração não pode estar no passado.',
              dateOutOfRange: 'A data de expiração não pode estar no passado',
            },
            invalidCvc: 'o CVC é inválido',
            invalidZipCode: 'O zip está incorreto',
            cardNumberPlaceholder: 'Número do cartão',
            expiryPlaceholder: 'MM/AA',
            cvcPlaceholder: 'CVC',
            zipPlaceholder: 'CEP',
          }}
          cardNumberInputProps={{
            value: this.state.cardNumber,
            onChange: this.handleCardNumberChange,
          }}
          cardExpiryInputProps={{
            value: this.state.expiry,
            onChange: this.handleCardExpiryChange,
          }}
          cardCVCInputProps={{
            value: this.state.cvc,
            onChange: this.handleCardCVCChange,
          }}
          fieldClassName="input"
        />
        {touched && error && <p className="help-block">{error}</p>}
      </div>
    );
  }
}

export class InputCheckbox extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
      options,
    } = this.props;
    return (
      <Grid cols={this.props.cols} style={this.props.style}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">
              <i className="fa fa-times-circle-o" />
              &nbsp;
              {this.props.label}
            </label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          {options.map((item, index) => {
            return (
              <div className="form-check form-control">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={item.value}
                  id={`checkbox-${item.label}`}
                />

                {touched && error ? (
                  <label
                    className="form-check-label"
                    htmlFor={`checkbox-${item.label}`}
                    style={{ marginLeft: 17 }}
                  >
                    <i className="fa fa-times-circle-o" />
                    &nbsp;
                    {item.label}
                  </label>
                ) : (
                  <label
                    className="form-check-label"
                    htmlFor={`checkbox-${item.label}`}
                    style={{ marginLeft: 17 }}
                  >
                    {item.label}
                  </label>
                )}
              </div>
            );
          })}
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

export class DatePickerComponent extends React.Component {
  constructor(props) {
    super(props);
    let dates = new Date(this.props.disabledDates);
    this.state = {
      invalidDates: [dates],
      date: null,
    };
  }

  render() {
    const {
      meta: { touched, error },
    } = this.props;
    const pt = {
      firstDayOfWeek: 1,
      dayNames: [
        'domingo',
        'segunda-feira',
        'terça-feira',
        'quarta-feira',
        'quinta-feira',
        'sexta-feira',
        'sábado',
      ],
      dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
      dayNamesMin: ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'],
      monthNames: [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro',
      ],
      monthNamesShort: [
        'jan',
        'fev',
        'mar',
        'abr',
        'mai',
        'jun',
        'jul',
        'ago',
        'set',
        'out',
        'nov',
        'dez',
      ],
    };
    return (
      <Grid cols={this.props.cols}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">{this.props.label}</label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          <div className="p-col-12 p-md-12">
            <Calendar
              value={this.state.date}
              locale={pt}
              onChange={(e) => {
                this.props.input.onChange(
                  moment(e.value).format('YYYY-MM-DD HH:mm:ss')
                );
                this.setState({ date: e.value });
              }}
              disabledDates={this.props.disabledDates}
              touchUI={true}
              disabledDays={[0]}
              disabled={this.props.readOnly}
              onSelect={this.props.onSelect}
              dateFormat="dd/mm/yy"
              readonlyInput={true}
              monthNavigator={true}
              yearNavigator={true}
              yearRange={
                moment().format('YYYY') +
                ':' +
                moment().add(2, 'year').format('YYYY')
              }
            />
          </div>
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

export class InputLabelNatural extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <Grid cols={this.props.cols} style={this.props.style}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">
              <i className="fa fa-times-circle-o" />
              &nbsp;
              {this.props.label}
            </label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          <div className="md-text-field-container md-full-width md-text-field-container--input md-cell md-cell--bottom">
            <input
              {...this.props.input}
              id={this.props.id}
              className={this.props.className}
              placeholder={this.props.placeholder}
              readOnly={this.props.readOnly}
              type={this.props.type}
              name={this.props.name}
            />
            <hr className="md-divider md-divider--text-field md-divider--expand-from-center" />
          </div>
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

export class InputLabel2 extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
    } = this.props;
    const { cols, id, label, placeholder, type, name, style, className } =
      this.props;
    return (
      <Grid cols={cols}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          <label for={id}>{label}</label>
          <input
            className={`form-control ${
              touched && error && 'is-invalid'
            } ${className}`}
            aria-describedby="emailHelp"
            {...this.props}
            {...this.props.input}
            id={this.props.id}
            placeholder={placeholder}
            readOnly={this.props.readOnly}
            type={type}
            name={name}
            style={style}
          />
          {touched && error && <div className="invalid-feedback">{error}</div>}
        </div>
      </Grid>
    );
  }
}

export class CaptchaInput extends React.Component {
  onChange = (value) => {
    if (!_.isNull(value)) {
      this.props.input.onChange(value);
    } else {
      this.props.input.onChange(undefined);
    }
  };

  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <div className={`form-group ${touched && error && 'has-error'}`}>
        <ReCAPTCHA
          sitekey="6LfLGHwkAAAAAJr7DSFDGvnOe2aE3rqz_e1v19RU"
          onChange={this.onChange}
          hl="pt-BR"
        />
        {touched && error && (
          <span style={{ fontSize: 13, color: 'red' }} className="help-block">
            {error}
          </span>
        )}
      </div>
    );
  }
}

export class InputLabelControl extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <Grid cols={this.props.cols} style={this.props.style}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">
              <i className="fa fa-times-circle-o" />
              &nbsp;
              {this.props.label}
            </label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}

          <input
            {...this.props.input}
            id={this.props.id}
            className="form-control"
            placeholder={this.props.placeholder}
            readOnly={this.props.readOnly}
            type={this.props.type}
            name={this.props.name}
          />
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

export class TextLabel extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <Grid cols={this.props.cols} style={this.props.style}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">
              <i className="fa fa-times-circle-o" />
              &nbsp;
              {this.props.label}
            </label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          <textarea
            {...this.props.input}
            className="form-control"
            rows={this.props.rows}
            readOnly={this.props.readOnly}
            placeholder={this.props.placeholder}
          />
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

const useStylesSelectLabel = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
    '& label': {
      color: GENERAL_TEXT_COLOR,
      backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
    },
    '& label.Mui-focused': {
      color: GENERAL_TEXT_COLOR,
      backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COMPANY_COLORS.general.form.active,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 19.5,
      paddingBottom: 19.5,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: COMPANY_COLORS.general.form.general,
      },
      '&:hover fieldset': {
        borderColor: COMPANY_COLORS.general.form.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: COMPANY_COLORS.general.form.active,
      },
    },
  },
  inputLabel: {
    color: GENERAL_TEXT_COLOR,
    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    color: GENERAL_TEXT_COLOR,
    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  },
  selectEmpty: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: GENERAL_TEXT_COLOR,
    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  select: {
    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
    color: GENERAL_TEXT_COLOR,
    paddingTop: 0,
    paddingBottom: 0,
    '&:after': {
      borderBottomColor: GENERAL_TEXT_COLOR,
    },
    '& .MuiSvgIcon-root': {
      color: GENERAL_TEXT_COLOR,
    },
  },
  outlinedInput: {
    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
    color: GENERAL_TEXT_COLOR,
  },
}));

export function SelectLabel(props) {
  const classes = useStylesSelectLabel();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const { meta, required, helperText, options, isLong, longText, disabled } =
    props;

  function renderOptions() {
    return options.map((option, index) => (
      <MenuItem
        key={index}
        value={option.value}
        style={{
          color: GENERAL_TEXT_COLOR,
          backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
          margin: 0,
        }}
      >
        {option.label}
      </MenuItem>
    ));
  }

  return (
    <div className={classes.root}>
      {isLong ? <Typography className="pb-2">{longText}</Typography> : null}
      <FormControl
        variant="outlined"
        color="primary"
        className={classes.formControl}
        error={meta && meta.touched && meta.error ? true : false}
        required={Boolean(required)}
        disabled={Boolean(disabled)}
      >
        <InputLabel
          ref={inputLabel}
          className={classes.inputLabel}
          htmlFor="select-label"
        >
          {props.label}
        </InputLabel>
        <Select
          {...props.input}
          className={classes.select}
          MenuProps={{
            MenuListProps: {
              disablePadding: true,
            },
          }}
          input={
            <OutlinedInput
              className={classes.outlinedInput}
              name={props.name}
              labelWidth={labelWidth}
              id="select-label"
            />
          }
        >
          {renderOptions()}
        </Select>
        {(meta && meta.touched && meta.error) || helperText ? (
          <FormHelperText>
            {(meta && meta.touched && meta.error) || helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
}

export const InputImageWithProcessing = (props) => {
  return <InputImage {...props} />;
};

export const InputEasyImageWithProcessing = (props) => {
  return <InputEasyCutImage {...props} />;
};

export function MultipleChoices(props) {
  const classes = useStylesSelectLabel();

  const {
    meta: { touched, error },
    input: { onChange, value },
    helperText,
    required,
    disabled,
    step,
    options,
    isLong,
    longText,
  } = props;

  const [state, setState] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');

  useEffect(() => {
    if (value) {
      if (_.isString(value)) {
        setState('other');
        setInputValue(value);
      } else {
        setState(`${value}`);
        setInputValue('');
      }
    } else {
      setState('');
      setInputValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const StyledRadio = withStyles({
    root: {
      color: touched && error ? '#f44336' : COMPANY_COLORS.primary,
      '&$checked': {
        color: COMPANY_COLORS.primary,
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const handleChange = (event) => {
    setState(event.target.value);
    if (event.target.value === 'other') {
      if (inputValue) {
        onChange(inputValue);
      } else {
        onChange(' ');
      }
    } else {
      onChange(parseInt(event.target.value));
    }
  };

  const handleInputChange = (event) => {
    if (state === 'other') {
      if (event) {
        onChange(event);
      } else {
        onChange(' ');
      }
    }
    setInputValue(event);
  };

  function renderOptions() {
    return options.map((option, index) => (
      <FormControlLabel
        key={index}
        value={`${option.value}`}
        control={<StyledRadio />}
        label={option.label}
      />
    ));
  }

  return (
    <>
      {isLong ? <Typography className="pb-2">{longText}</Typography> : null}
      <FormControl
        className={classes.formControl}
        variant="outlined"
        color="primary"
        disabled={disabled}
        required={required}
      >
        <RadioGroup value={state} onChange={handleChange}>
          {renderOptions()}
          {props.otherOption && (
            <Box style={{ display: 'flex' }}>
              <FormControlLabel
                key={options.length}
                value="other"
                control={<StyledRadio />}
                label="Outro:"
              />
              <input
                value={inputValue}
                onChange={(e) => handleInputChange(e.target.value)}
                style={{
                  border: 0,
                  borderBottom: '1px solid black',
                  flex: 1,
                  height: '40px',
                }}
              />
            </Box>
          )}
        </RadioGroup>
        {(touched && error) || helperText ? (
          <FormHelperText style={{ color: '#f44336' }}>
            {(touched && error) || helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </>
  );
}

export function BoxSelect(props) {
  const classes = useStylesSelectLabel();
  const {
    meta: { touched, error },
    input: { onChange, value },
    helperText,
    required,
    disabled,
    step,
    options,
    isLong,
    longText,
  } = props;

  const [inputValue, setInputValue] = React.useState(null);
  const [state, setState] = React.useState({});

  useEffect(() => {
    // Ajustando os botões marcados
    const tempState = { other: false };
    const tempOptions = [...options];

    if (_.isArray(value) && value.find((item) => _.isString(item))) {
      tempOptions.push({ value: value.find((item) => _.isString(item)) });
    }
    for (let option of tempOptions) {
      if (value.indexOf(option.value) > -1 && value.length > 0) {
        if (_.isString(option.value)) {
          tempState[`other`] = true;
        } else {
          tempState[`${option.value}`] = true;
        }
      } else {
        if (_.isString(option.value)) {
          tempState[`other`] = false;
        } else {
          tempState[`${option.value}`] = false;
        }
      }
    }
    setState(tempState);
    //Ajustando o valores do input
    if (_.isArray(value) && value.find((item) => _.isString(item))) {
      setInputValue(value.find((item) => _.isString(item)));
    } else {
      setInputValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    const answers = [];
    for (let i in state) {
      if (state[i] === true) {
        if (i === 'other') {
          answers.push(inputValue);
        } else {
          answers.push(parseInt(i));
        }
      }
    }
    if (answers.length > 0) {
      onChange(answers);
    } else {
      onChange(null);
    }
  }, [inputValue, onChange, state, step]);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const StyledCheckbox = withStyles({
    root: {
      color: touched && error ? '#f44336' : GENERAL_TEXT_COLOR,
      '&$checked': {
        // color: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  function renderOptions() {
    return options.map((option, index) => (
      <FormControlLabel
        style={{ backgroundColor: '' }}
        key={index}
        control={
          <StyledCheckbox
            style={{ backgroundColor: '' }}
            name={`${option.value}`}
            onChange={handleChange}
            checked={state[`${option.value}`]}
          />
        }
        label={option.label}
      />
    ));
  }

  return (
    <>
      {isLong ? <Typography className="pb-2">{longText}</Typography> : null}
      <FormControl
        className={classes.formControl}
        variant="outlined"
        color="primary"
        disabled={disabled}
        required={required}
      >
        <FormGroup style={{ backgroundColor: '' }}>
          {renderOptions()}
          {props.otherOption && (
            <Box style={{ display: 'flex', backgroundColor: '' }}>
              <FormControlLabel
                // style={{ backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR }}
                key={options.length}
                control={
                  <StyledCheckbox
                    name="other"
                    onChange={handleChange}
                    checked={state[`other`]}
                  />
                }
                label={'Outro:'}
              />
              <input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                style={{
                  border: 0,
                  borderBottom: `1px solid ${GENERAL_TEXT_COLOR}`,
                  flex: 1,
                  // background: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                  height: '40px',
                }}
              />
            </Box>
          )}
        </FormGroup>
        {(touched && error) || helperText ? (
          <FormHelperText style={{ color: '#f44336' }}>
            {(touched && error) || helperText}
          </FormHelperText>
        ) : null}
      </FormControl>
    </>
  );
}

export function SelectMultiple(props) {
  const classes = useStylesSelectLabel();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const [option, setOption] = React.useState([]);
  function handleChange(event) {
    setOption(event.target.value);
  }

  const StyledCheckbox = withStyles({
    root: {
      color: COMPANY_COLORS.primary,
      '&$checked': {
        color: COMPANY_COLORS.primary,
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  useEffect(() => {
    if (props.input.value.length > 0) {
      setOption(props.input.value);
    }
  }, [props.input.value]);

  const {
    meta: { touched, error },
    isLong,
    longText,
  } = props;

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR }}
    >
      {isLong ? <Typography className="pb-2">{longText}</Typography> : null}
      <FormControl
        aria-label="Tooltip do Formulário"
        variant="outlined"
        color="primary"
        className={classes.formControl}
        disabled={props.disabled}
        required={props.required}
      >
        <InputLabel
          ref={inputLabel}
          className={classes.inputLabel}
          htmlFor="age-customized-select"
        >
          {props.label}
        </InputLabel>
        <Select
          {...props.input}
          margin={props.margin}
          error={touched && error ? true : false}
          value={option}
          onChange={handleChange}
          MenuProps={{
            MenuListProps: {
              disablePadding: true,
            },
          }}
          multiple
          renderValue={(selected, a) => {
            const selectedLabelsList = props.options
              .filter((i) => selected.includes(i.value))
              .map((i) => i.label);
            return selectedLabelsList.join(', ');
          }}
          input={
            <OutlinedInput
              name={props.name}
              labelWidth={labelWidth}
              id="age-customized-select"
            />
          }
        >
          <MenuItem
            disabled
            value=""
            style={{ backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR }}
          >
            <Typography variant="body1">
              Selecione uma ou mais opções. Clique fora para fechar.
            </Typography>
          </MenuItem>

          {props.options.map((item, index) => (
            <MenuItem
              key={index}
              value={item.value}
              style={{ backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR }}
            >
              <StyledCheckbox
                color="primary"
                checked={option.includes(item.value)}
              />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
        {touched && error ? (
          <FormHelperText style={{ color: '#FF5A52' }}>
            {touched && error}
          </FormHelperText>
        ) : (
          <FormHelperText>
            Você pode selecionar mais de uma opção
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

export function SelectMultipleCheckbox(props) {
  const classes = useStylesSelectLabel();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const [item, setItem] = React.useState([]);

  function handleChange(event) {
    setItem(event.target.value);
  }

  return (
    <div className={classes.root}>
      <FormControl
        variant="outlined"
        color="primary"
        className={classes.formControl}
        disabled={props.disabled}
        required={props.required}
      >
        <InputLabel
          ref={inputLabel}
          className={classes.inputLabel}
          htmlFor="age-customized-select"
        >
          {props.label}
        </InputLabel>
        <Select
          margin={props.margin}
          {...props.input}
          value={item}
          onChange={handleChange}
          multiple
          input={
            <OutlinedInput
              name={props.name}
              labelWidth={labelWidth}
              id="age-customized-select"
            />
          }
        >
          {props.options.map((item, index) => (
            <MenuItem value={item.value} key={index}>
              <Checkbox {...props.input} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export class InputAnyFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: null,
    };
  }

  renderAccepted = (acceptedFiles) => {
    this.setState({ files: acceptedFiles[0] });
    this.props.input.onChange(acceptedFiles[0]);
  };

  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <Grid cols={this.props.cols} style={this.props.style}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">
              <i className="fa fa-times-circle-o" />
              &nbsp;
              {this.props.label}
            </label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          <Dropzone
            onDrop={(acceptedFiles) => this.renderAccepted(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  style={{
                    height: 50,
                    borderWidth: 2,
                    borderColor:
                      touched && error ? '#e20035' : 'rgb(102, 102, 102)',
                    borderStyle: 'dashed',
                    borderRadius: 5,
                    margin: 'auto',
                    cursor: 'pointer',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {this.state.files !== null ? (
                    this.state.files.name
                  ) : (
                    <p>{this.props.placeholder}</p>
                  )}
                </div>
              </section>
            )}
          </Dropzone>
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

export class CheckboxWithoutLabel extends React.PureComponent {
  render() {
    const {
      option,
      input,
      labelStyle,
      meta: { touched, error },
    } = this.props;
    return (
      <Grid cols={this.props.cols}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          <Row style={{ marginLeft: 0 }}>
            <Checkbox
              checkboxClass="icheckbox_square-green"
              name={input.name}
              value={option.value}
              cursor={false}
              checked={input.value}
              onChange={(event) => input.onChange(!event.target.checked)}
            />
            &nbsp;&nbsp;
            <label htmlFor={option.label} style={labelStyle}>
              {option.label}
            </label>
          </Row>
          <Row style={{ marginLeft: 0 }}>
            {touched && error && <span className="help-block">{error}</span>}
          </Row>
        </div>
      </Grid>
    );
  }
}

export class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: false,
      fuelChoosen: this.props.initialValue,
    };
  }

  showDetail = () => {
    this.setState({ detail: true });
  };

  hideDetail = () => {
    this.setState({ detail: false });
  };

  selectOption = (id) => {
    this.setState({ fuelChoosen: id });
    this.props.input.onChange(this.state.fuelChoosen);
  };

  renderOptions = () => {
    const { fuelChoosen } = this.state;
    const { options } = this.props;
    return options.map((item, index) => (
      <div
        style={{
          height: '2em',
          borderRadius: 20,
          background: item.value === fuelChoosen ? '#10b0ae' : '#e5e8ea',
          margin: 10,
          color: item.value === fuelChoosen ? '#ffffff' : 'black',
          display: 'flex',
          textTransform: 'uppercase',
          alignItems: 'center',
        }}
        key={index}
        onClick={() => this.selectOption(item.value)}
      >
        <Grid cols="12 12 12 12">{item.label}</Grid>
      </div>
    ));
  };

  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <Grid cols={this.props.cols} style={this.props.style}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          <div
            style={{
              height: '2em',
              borderRadius: 20,
              background: '#e5e8ea',
              margin: 10,
              display: 'flex',
              ...stylesheets.flexBoxAlignCenter,
            }}
          >
            <Grid cols="8 8 8 8">
              <span
                style={{
                  fontSize: '1em',
                  color: '#acaeaf',
                  textTransform: 'uppercase',
                }}
              >
                SELECIONE
              </span>
            </Grid>
            <Grid
              cols="4 4 4 4"
              style={{
                paddingRight: 0,
              }}
            >
              <FontIcon
                onClick={() => this.showDetail()}
                className="history-icon-arrow"
                style={{
                  position: 'relative',
                  float: 'right',
                  fontSize: '2em',
                  display: !this.state.detail ? 'block' : 'none',
                }}
              >
                arrow_drop_down_circle
              </FontIcon>
              <FontIcon
                onClick={() => this.hideDetail()}
                className="history-icon-arrow"
                style={{
                  position: 'relative',
                  float: 'right',
                  fontSize: '2em',
                  display: this.state.detail ? 'block' : 'none',
                  transform: 'rotate(180deg)',
                }}
              >
                arrow_drop_down_circle
              </FontIcon>
            </Grid>
          </div>
          <div
            style={{
              display: this.state.detail ? 'block' : 'none',
            }}
          >
            {this.renderOptions()}
          </div>
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

const inputFileStyles = makeStyles((theme) => ({
  imagePreview: {
    objectFit: 'cover',
    height: '300px',
    width: '300px',
    overflow: 'hidden',
    borderWidth: 2,
    // borderColor:
    //   touched && error
    //     ? appColors.error
    //     : appColors.dark.level3,
    borderStyle: 'solid',
    borderRadius: 150,
    cursor: 'pointer',
    backgroundColor: appColors.light.level2,
  },
}));

export function InputFile(props) {
  let {
    input,
    label,
    meta: { touched, error },
  } = props;

  const [croppedImgBase64] = useState(undefined);
  const theme = useTheme();
  const classes = inputFileStyles();

  function onDrop(imageFiles) {
    let {
      input: { onChange, onBlur },
    } = props;

    let reader = new FileReader();
    reader.readAsDataURL(imageFiles[0]);
    reader.onload = function () {
      onBlur({ base64: reader.result, name: imageFiles[0] });
      onChange({
        base64: reader.result,
        imagem: imageFiles[0],
        preview: URL.createObjectURL(imageFiles[0]),
      });
    };
    reader.onerror = function (error) {};
  }

  let dropzoneProps = {
    accept: 'image/jpeg, image/png',
    multiple: false,
    onDropAccepted: onDrop,
    maxSize: 1000000,
    onDropRejected: props.onDropRejected,
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={`${touched && error && 'has-error'}`}
    >
      {props.input.value ? (
        <Typography
          variant="caption"
          style={{
            color: touched && error ? appColors.error : GENERAL_TEXT_COLOR,
          }}
        >
          {label}
        </Typography>
      ) : null}
      <input type="hidden" disabled {...input} />
      <Box display="flex">
        <Dropzone {...dropzoneProps}>
          {({ getRootProps, getInputProps }) => (
            <Box
              display="flex"
              flexGrow={1}
              {...getRootProps()}
              style={{
                height: '300px',
                width: '300px',
                borderWidth: 2,
                borderColor:
                  touched && error ? appColors.error : appColors.dark.level3,
                cursor: 'pointer',
                backgroundColor: appColors.light.level2,
              }}
            >
              {props.input.value ? (
                <Box
                  display="flex"
                  style={{
                    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                  }}
                >
                  <input {...getInputProps()} />
                  <Avatar
                    className={classes.imagePreview}
                    src={
                      props.input.value.base64 ||
                      `${BASE_S3}/${props.input.value}`
                    }
                    alt="Preview Imagem"
                    style={{
                      borderColor:
                        touched && error
                          ? appColors.error
                          : appColors.dark.level3,
                    }}
                  />
                  {/* <img
                    style={{
                      objectFit: 'cover',
                      height: '300px',
                      width: '300px',
                      overflow: 'hidden',
                      borderWidth: 2,
                      borderColor:
                        touched && error
                          ? appColors.error
                          : appColors.dark.level3,
                      borderStyle: 'solid',
                      borderRadius: 150,
                      cursor: 'pointer',
                      backgroundColor: appColors.light.level2,
                    }}
                    src={
                      props.input.value.base64 ||
                      `${BASE_S3}/${props.input.value}`
                    }
                    alt="Preview Imagem"
                  /> */}
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                >
                  <input {...getInputProps()} />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      borderRadius: '50%',
                      backgroundColor: `${appColors.light.level1}`,
                      height: 70,
                      width: 70,
                    }}
                  >
                    <CloudUploadIcon
                      style={{
                        fontSize: theme.spacing(4),
                        color:
                          touched && error
                            ? appColors.error
                            : 'rgb(102, 102, 102)',
                      }}
                      className=""
                    />
                  </Box>

                  <Typography
                    variant="caption"
                    style={{
                      color:
                        touched && error
                          ? appColors.error
                          : 'rgb(102, 102, 102)',
                    }}
                  >
                    {label}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Dropzone>
      </Box>
      {touched && error && (
        <Box display="flex" mt={1} ml={1}>
          <Typography variant="caption" style={{ color: appColors.error }}>
            {error}
          </Typography>
        </Box>
      )}
      {croppedImgBase64 && (
        <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImgBase64} />
      )}
    </Box>
  );
}

export class ColorPickerInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      color: '#fff',
    };

    this.toggleColor = this.toggleColor.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
  }

  componentDidMount() {
    if (!_.isUndefined(this.props.input.value)) {
      this.setState({ color: this.props.input.value });
    }
  }

  toggleColor(e) {
    e.preventDefault();

    this.setState({
      showPicker: !this.state.showPicker,
    });
  }

  handleChangeComplete(color, event) {
    const { onColorChange } = this.props;
    this.setState({ color: color.hex });
    onColorChange(color.hex);
  }

  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <div className={`form-group ${touched && error && 'has-error'}`}>
        {touched && error ? (
          <label className="control-label">{this.props.label}</label>
        ) : (
          <label htmlFor={this.props.name}>{this.props.label}</label>
        )}
        <center>
          {
            <div>
              <SketchPicker
                color={this.state.color}
                onChangeComplete={this.handleChangeComplete}
                disableAlpha={true}
                width={250}
              />
            </div>
          }
        </center>
        {touched && error && <span className="help-block">{error}</span>}
      </div>
    );
  }
}

const CssTextField = withStyles({
  root: {
    flexGrow: 1,
    '& label': {
      color: GENERAL_TEXT_COLOR,
      fontSize: 16,
    },
    '& label.Mui-focused': {
      color: GENERAL_TEXT_COLOR,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: GENERAL_TEXT_COLOR,
    },
    '& .MuiOutlinedInput-root': {
      '& textarea': {
        color: GENERAL_TEXT_COLOR,
      },
      '& fieldset': {
        borderColor: GENERAL_TEXT_COLOR,
      },
      '&:hover fieldset': {
        borderColor: GENERAL_TEXT_COLOR,
      },
      '&.Mui-focused fieldset': {
        borderColor: GENERAL_TEXT_COLOR,
      },
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 19.5,
      paddingBottom: 19.5,
      color: GENERAL_TEXT_COLOR,
    },
    '& .MuiOutlinedInput-multiline': {
      paddingTop: 0,
      paddingBottom: 0,
      color: GENERAL_TEXT_COLOR,
    },
  },
})(TextField);

const CssDateTimeField = withStyles({
  root: {
    flexGrow: 1,
    '& label': {
      color: GENERAL_TEXT_COLOR,
    },
    '& label.Mui-focused': {
      color: GENERAL_TEXT_COLOR,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: GENERAL_TEXT_COLOR,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#123456',
      },
      '&:hover fieldset': {
        borderColor: GENERAL_TEXT_COLOR,
      },
      '&.Mui-focused fieldset': {
        borderColor: GENERAL_TEXT_COLOR,
      },
    },
  },
})(DatePicker);

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export function InputLabelOutlined(props) {
  const [selectedDate, setSelectedDate] = React.useState(null);
  const classes = useStyles();

  function handleDateChange(date) {
    setSelectedDate(date);
  }

  const { touched, error, dispatch } = props.meta;
  const { label } = props;
  useEffect(() => {
    if (touched && error) {
      dispatch({
        type: 'SNACKBAR',
        payload: {
          message: `${error}: "${label}"`,
          variant: 'error',
          open: true,
        },
      });
    }
  }, [touched, error, dispatch, label]);

  const {
    input,
    className,
    required,
    type,
    multiline,
    rowsMax,
    rows,
    margin,
    helperText,
    isLong,
    longText,
    inputProps,
  } = props;
  if (type === 'datetime-local') {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'pt-BR'}>
        <div className={classes.container}>
          <CssDateTimeField
            label={label}
            {...input}
            value={selectedDate}
            onChange={handleDateChange}
            inputVariant="outlined"
            disableFuture
            inputProps={inputProps}
            format={'DD/MM/YYYY'}
            ampm={false}
            autoOk
            required={required}
            invalidDateMessage="Inválida"
            margin={margin}
          />
        </div>
      </MuiPickersUtilsProvider>
    );
  }
  return (
    <div className={classes.container}>
      {isLong ? (
        <Typography style={{ width: '100%' }} className="pb-2">
          {longText}
        </Typography>
      ) : null}
      {props.limitChar && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography variant="body1">
            {`${input.value ? input.value.length : 0}/${props.limitChar}`}
          </Typography>
        </div>
      )}

      <CssTextField
        error={touched && error ? true : false}
        label={label}
        className={clsx(className)}
        variant="outlined"
        disabled={props.disabled}
        inputProps={{
          ...inputProps,
          maxLength: props.maxLength || props.limitChar || null,
        }}
        // InputProps={{ endAdornment: props.endAdornment }}
        InputLabelProps={props.inputlabelprops}
        helperText={(touched && error) || helperText}
        type={type}
        multiline={multiline}
        rowsMax={rowsMax}
        rows={rows}
        {...input}
        margin={margin}
      />
      {props.maxLength ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            height: 20,
          }}
        >
          <Typography variant="body1" style={{ fontSize: 15, padding: 0 }}>
            {props.input.value.length}/{props.maxLength}
          </Typography>
        </div>
      ) : null}
    </div>
  );
}

export function MaterialCheckbox({
  input,
  label,
  type,
  meta: { touched, error, warning },
}) {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox style={{ color: COMPANY_COLORS.primary }} {...input} />
        }
        label={label}
      />
      {touched && error ? (
        <Typography
          variant="caption"
          style={{
            color: COMPANY_COLORS.primary,
            display: 'block',
            marginTop: '-18px',
            marginLeft: 48,
          }}
        >
          {error}
        </Typography>
      ) : null}
    </div>
  );
}

const useSearchStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    boxShadow: '0px 0px 0px 0px black',
    border: `1px solid ${SIGNED_IN_NAV_TEXT_COLOR}`,
    borderRadius: 20,
    height: 31,
    backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: SIGNED_IN_NAV_TEXT_COLOR,
    '&::placeholder': {
      color: SIGNED_IN_NAV_TEXT_COLOR,
    },
  },
  iconButton: {
    padding: 10,
    color: SIGNED_IN_NAV_TEXT_COLOR,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export function CustomizedInputBase(props) {
  const classes = useSearchStyles();
  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={
          props.placeholder ? props.placeholder : 'Pesquisar Atividades'
        }
        inputProps={{ formNoValidate: true, className: classes.input }}
        onChange={props.onChange}
        value={props.value}
        onKeyDown={props.onKeyDown}
        required={props.required}
        formNoValidate={true}
      />
      <IconButton
        className={classes.iconButton}
        onClick={props.onClick}
        aria-label="search"
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}

export function InputProfilePicture(props) {
  let {
    input,
    label,
    meta: { touched, error },
  } = props;

  const [croppedImgBase64] = useState(undefined);

  function onDrop(imageFiles) {
    let {
      input: { onChange, onBlur },
    } = props;

    let reader = new FileReader();
    reader.readAsDataURL(imageFiles[0]);
    reader.onload = function () {
      onBlur({ base64: reader.result, name: imageFiles[0] });
      onChange({
        base64: reader.result,
        imagem: imageFiles[0],
        preview: URL.createObjectURL(imageFiles[0]),
      });
    };
    reader.onerror = function (error) {};
  }

  let dropzoneProps = {
    accept: 'image/jpeg, image/png',
    multiple: false,
    onDropAccepted: onDrop,
    maxSize: 3000000,
    onDropRejected: props.onDropRejected,
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={`${touched && error && 'has-error'}`}
    >
      {props.input.value ? (
        <Typography
          variant="body1"
          style={{
            color: touched && error ? appColors.error : 'rgb(102, 102, 102)',
          }}
        >
          {label}
        </Typography>
      ) : null}
      <input type="hidden" disabled {...input} />
      <Box display="flex">
        <Dropzone {...dropzoneProps}>
          {({ getRootProps, getInputProps }) => (
            <Box
              display="flex"
              flexGrow={1}
              {...getRootProps()}
              style={{
                height: '144px',
                width: '144px',
                border: `1px solid ${GENERAL_TEXT_COLOR}`,
                borderRadius: '9px',
                borderColor:
                  touched && error ? appColors.error : GENERAL_TEXT_COLOR,
                cursor: 'pointer',
                backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
              }}
            >
              {props.input.value ? (
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  p={1}
                >
                  <input {...getInputProps()} />
                  <img
                    style={{
                      objectFit: 'cover',
                      height: 70,
                      width: 70,
                      overflow: 'hidden',
                      borderWidth: 1,
                      borderColor:
                        touched && error
                          ? appColors.error
                          : appColors.dark.level3,
                      borderStyle: 'solid',
                      borderRadius: '50%',
                      cursor: 'pointer',
                      backgroundColor: appColors.light.level2,
                    }}
                    src={
                      props.input.value.base64 ||
                      `${BASE_S3}/${props.input.value}`
                    }
                    alt="Preview Imagem"
                  />
                  <CheckIcon
                    style={{ fontSize: 30, color: '#118760', marginTop: 15 }}
                  />
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                >
                  <input {...getInputProps()} />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      borderRadius: '50%',
                      backgroundColor: `${appColors.light.level1}`,
                      height: 70,
                      width: 70,
                    }}
                  >
                    <AccountCircleIcon
                      style={{ fontSize: 70, color: '#666666' }}
                    />
                  </Box>
                  <Typography
                    variant="body1"
                    style={{
                      color:
                        touched && error ? appColors.error : GENERAL_TEXT_COLOR,
                      marginTop: 15,
                      textAlign: 'center',
                      fontSize: 12,
                    }}
                  >
                    {label}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Dropzone>
      </Box>
      {touched && error && (
        <Box display="flex" mt={1} ml={1}>
          <Typography variant="caption" style={{ color: appColors.error }}>
            {error}
          </Typography>
        </Box>
      )}
      {croppedImgBase64 && (
        <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImgBase64} />
      )}
    </Box>
  );
}
