import { Grid } from '@material-ui/core';
import React from 'react';
import AccountingContactsCard from './accountingContactsCard';

const contacts = [
  {
    name: 'Marcos de Lara Ramos',
    tel: '(61) 3214-7309',
    cel: '(61) 99627-6396',
    email: 'marcos.ramos@sindilegis.org.br',
    image:
      'https://s3.amazonaws.com/rilato.app.files/files/system/sindilegis/marcos.png',
    role: 'Gerente da Consultoria Jurídica',
  },
  {
    name: 'Alessandro Santos',
    tel: '(61) 3214-7342',
    cel: '(61) 99148-5498',
    email: 'alessandro@sindilegis.org.br',
    image:
      'https://s3.amazonaws.com/rilato.app.files/files/system/sindilegis/alesasndro.png',
    role: '',
  },
  {
    name: 'Thiego Bittencourt',
    tel: '(61) 3214-7339',
    cel: '(61) 99964-9591',
    email: 'thiego.bittencourt@sindilegis.org.br',
    image:
      'https://s3.amazonaws.com/rilato.app.files/files/system/sindilegis/thiego.png',
    role: '',
  },
  {
    name: 'Danuzi Neres',
    tel: '(61) 3214-7301',
    email: 'danuzi@sindilegis.org.br',
    image:
      'https://s3.amazonaws.com/rilato.app.files/files/system/sindilegis/danuzi.png',
    role: '',
  },
];

export default function AccountingContacts() {
  return (
    <section id="accounting-contacts">
      <p className="body-title mt-4">Núcleo contábil do Sindilegis</p>
      <p className="h7 mt-5">Telefone: (61) 3214-7330</p>
      <p className="h7">Email: consulegis@sindilegis.org.br</p>
      <Grid container spacing={3} style={{ marginTop: 48 }}>
        {contacts.map(({ name, cel, tel, email, image, role }) => (
          <Grid item xs={4}>
            <AccountingContactsCard
              name={name}
              cel={cel}
              tel={tel}
              email={email}
              image={image}
              role={role}
            />
          </Grid>
        ))}
      </Grid>
    </section>
  );
}
