/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ButtonPrimary from 'common/components/buttons/ButtonPrimary';
import Carrousel from 'common/components/carousel/carousel';
import MaterialModal from 'common/components/modal/materialModal';
import LoadingProgress from 'common/components/progress/loading';
import {
  BRB_FLA_STATION_ID,
  COMPANY_COLORS,
  COMPANY_STATION_ID,
  HEADER_TEXT_COLOR,
  SIGNED_IN_NAV_TEXT_COLOR,
} from 'config/consts';
import React, { useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import Carousel from 'react-material-ui-carousel';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as CentsIcon } from '../../../../assets/icons/cents.svg';
import { ReactComponent as LegalHammerIcon } from '../../../../assets/icons/legal-hammer.svg';
import { ReactComponent as LegalPersonIcon } from '../../../../assets/icons/legal-person.svg';
import { ReactComponent as ToothIcon } from '../../../../assets/icons/tooth.svg';
import {ReactComponent as PrestacaoDeContasIcon} from '../../../../assets/icons/Prestacao_de_contas.svg'
import {
  BASE_S3,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  GENERAL_TEXT_COLOR,
  SINDILEGIS_STATION_ID,
} from '../../../../config/consts';
import {
  getActionsByStationId,
  getPrivateFeaturedActivities,
  setFeaturedActivitiesLoad,
  setLoading,
} from '../activities/activitiesActions';
import { getJourneys } from '../activities/journey/journeyActions';
import { getList as getNowPlaying } from '../activities/nowPlaying/nowPlayingActions';
import {
  getActionsByCategory,
  getList as getCategoriesList,
  getParticipantCategory,
} from '../categories/categoriesActions';
import { getCampaignList } from '../legisClubFuels/legisClubFuelsActions';
import RenderActivities from './components/renderActivities';
import { getUserPoints } from './homepageActions';
import { getCouponsByEstablishmentId } from './sections/coupons/couponsActions';
import { getActionsByEstablishmentId } from './sections/userDoesActions/userDoesActionsActions';
import { getList as getTrophies } from './trophies/trophiesActions';
import SimpleBlockSkeleton from '../../../../common/components/skeleton/simpleBlockSkeleton';
import CarouselSkeleton from '../../../../common/components/skeleton/carouselSkeleton';

const useStyles = makeStyles((theme) => ({
  carouselImage: {
    minWidth: '100% !important',
    height: (props) => (props.xs ? '240px' : props.sm ? '430px' : '600px'),
    maxHeight: (props) => (props.xs ? '240px' : props.sm ? '430px' : '600px'),
    aspectRatio: 1,
    objectFit: 'cover',
    cursor: 'pointer',
    borderRadius: '8px',
  },
  carousel: {
    minWidth: '100%',
    height: (props) => (props.xs ? '300px' : props.sm ? '480px' : '650px'),
    maxHeight: (props) => (props.xs ? '300px' : props.sm ? '480px' : '650px'),
  },
}));

function renderSindilegisCategories(name, icon, routeName, history) {
  return (
    <Grid item xs={3}>
      <Box
        style={{
          border: '1px solid #ddd',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          flexDirection: 'column',
          cursor: 'pointer',
          height: 120,
          position: 'relative',
        }}
        onClick={() => history.push(`/homepage/${routeName}`)}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #ddd',
            borderRadius: 40,
            width: 80,
            height: 80,
            margin: 8,
            position: 'absolute',
            top: -47,
            backgroundColor: 'white',
          }}
        >
          {icon}
        </Box>
        <p className="mb-4 text-center" style={{ maxWidth: 120 }}>
          {name}
        </p>
      </Box>
    </Grid>
  );
}

function Dashboard(props) {
  const [userFeaturedActions, setUserFeaturedActions] = useState([]);
  const [featured, setFeatured] = useState(0);
  const [timedOut, setTimedOut] = useState(false);
  const [open, setOpen] = useState(false);
  const [prerequisite, setPrerequisite] = useState([{}]);
  const [journeyOrdered, setJourneyOrdered] = useState([]);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const id = COMPANY_STATION_ID;
  const classes = useStyles({ xs, sm });
  useEffect(() => {
    props.setFeaturedActivitiesLoad(false);
    props.getUserPoints(props.auth.user);
    props.getJourneys();
    props.getNowPlaying();
    props.getTrophies();
    props.getPrivateFeaturedActivities(COMPANY_STATION_ID, props.auth.user.id);
    props.getCouponsByEstablishmentId(id);
    props.getCampaignList();
  }, []);

  useEffect(() => {
    setTimedOut(true);
    setTimeout(() => {
      if (featured >= userFeaturedActions.length - 1) {
        setFeatured(0);
        setTimedOut(false);
      } else {
        setFeatured(featured + 1);
        setTimedOut(false);
      }
    }, 5000);
  }, [userFeaturedActions]);

  useEffect(() => {
    if (!timedOut) {
      setTimedOut(true);
      setTimeout(() => {
        if (featured >= userFeaturedActions.length - 1) {
          setFeatured(0);
          setTimedOut(false);
        } else {
          setFeatured(featured + 1);
          setTimedOut(false);
        }
      }, 5000);
    }
  }, [featured]);

  useEffect(() => {
    if (props.activities.privateActions.length > 0) {
      setUserFeaturedActions(props.activities.privateActions);
      setFeaturedActivitiesLoad(false);
    }
  }, [props.activities.privateActions]);

  useEffect(() => {
    const newJourneys = props.journey.list;

    for (let journey of newJourneys) {
      const actionsIndex = journey.actions.map((item, index) => ({
        ...item,
        index,
      }));
      const actionFilter = actionsIndex.filter((item) => item.hasDone === true);

      for (let item of actionFilter) {
        const index = actionsIndex.findIndex((i) => i.index === item.index);
        actionsIndex.splice(index, 1);
      }
      journey.actions = actionFilter.concat(actionsIndex);
      journey['hasComplete'] = actionFilter.length;
    }
    setJourneyOrdered(newJourneys);
  }, [props.journey]);

  function handleClose() {
    setOpen(false);
  }

  function selectActivity(id) {
    props.setLoading(true);
    props.history.push(`/homepage/atividades/${id}`);
  }

  function redirect2prerequisite(pre) {
    setOpen(true);
    setPrerequisite(pre);
  }

  function renderFeaturedActions() {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center container-fluid mt-2"
        style={{ width: '100%' }}
      >
        <Carousel
          className={classes.carousel}
          animation="slide"
          timeout={350}
          stopAutoPlayOnHover={true}
          style={{ backgroundColor: 'black' }}
          indicatorContainerProps={{
            style: {
              backgroundColor: 'transparent',
              marginTop: '-40px',
              zIndex: 10,
            },
          }}
          indicatorIconButtonProps={{
            style: {
              // padding: '.1rem',
              margin: '.2rem',
              backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: GENERAL_TEXT_COLOR,
              borderCOlor: 'red',
            },
          }}
        >
          {userFeaturedActions.map((action, index) => {
            return (
              <img
                className={classes.carouselImage}
                onClick={
                  action.type_id === 13
                    ? () =>
                        (window.location.href = action.redirect_links[0].url)
                    : action.prerequisites?.action_has_prerequisites
                    ? action.prerequisites?.user_done_prerequisites
                      ? () => selectActivity(action.id)
                      : () =>
                          redirect2prerequisite(
                            action.prerequisites.action_prerequisites
                          )
                    : () => selectActivity(action.id)
                }
                src={BASE_S3 + '/' + (action.featuredImage || action.image)}
                key={action.id} //bad key
                alt="Imagem de atividade"
              />
            );
          })}
        </Carousel>
      </div>
    );
  }

  function renderMainSkeleton() {
    return (
      <Grid
        item
        container
        alignItems="flex-start"
        justifyContent="space-between"
        xs={12}
        spacing={4}
      >
        <Grid item xs={12}>
          <SimpleBlockSkeleton
            width="100%"
            height={500}
            variant="rect"
            animation="pulse"
          />
        </Grid>
        <Grid item xs={12} style={{ margin: '2rem 0' }}>
          <CarouselSkeleton elementsHeight={150} animation={'pulse'} />
        </Grid>
        <Grid item xs={12}>
          <CarouselSkeleton elementsHeight={150} animation={'pulse'} />
        </Grid>
      </Grid>
    );
  }

  if (props.categories.simpleListLoading) {
    return renderMainSkeleton();
  }

  if (props.categories.listCategories.content.length === 0) {
    return (
      <div
        className="container-fluid px-4"
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '93%',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{
            fontSize: 20,
            fontWeight: 500,
            color: HEADER_TEXT_COLOR,
          }}
          element="h6"
        >
          Ainda não existem atividades no ar...
        </Typography>
      </div>
    );
  }

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        paddingBottom: 48,
        width: '100%',
      }}
    >
      <Grid
        item
        xs={12}
        // className={`row ${isMobileOnly ? '' : 'pt-4'} mb-4`}
        // style={{
        //   marginTop: -24,
        // }}
      >
        {props.activities.loadingPrivateActions ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '100%', width: '100%' }}
          >
            <LoadingProgress customColor="lightgray" />
          </div>
        ) : userFeaturedActions.length > 0 ? (
          renderFeaturedActions()
        ) : null}
      </Grid>
      {isMobile && COMPANY_STATION_ID === BRB_FLA_STATION_ID ? (
        <Grid item xs={12}>
          <div
            style={{
              flex: 1,
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 24,
            }}
            onClick={() => props.history.push('/homepage/numeros-da-sorte')}
          >
            <img
              className="img-fluid"
              alt="img-fluid"
              style={{
                marginBottom: 8,
                maxWidth: 267.02,
                maxHeight: 150.31,
                width: 'auto',
                height: 'auto',
                display: 'block',
                objectFit: 'cover',
                objectPosition: '50% 50%',
                borderRadius: '5px',
              }}
              src={`https://s3.amazonaws.com/rilato.app.files/brbflagiveawaynumbers.jpeg`}
            ></img>
            <Typography
              variant="body1"
              style={{
                fontStyle: 'normal',
                fontWeight: 'normal',
                maxWidth: 267.02,
                fontSize: '18px',
                lineHeight: '24px',
                height: 48,
                letterSpacing: '0.25px',
                marginBottom: 8,
                color: '#242424',
              }}
            >
              Informações sobre o número da sorte
            </Typography>
          </div>
        </Grid>
      ) : null}
      {/* Now playing */}
      {props.nowPlaying.list.length > 0 ? (
        <div
          style={{
            marginBottom: 48,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Carrousel
            categoryName={props.nowPlaying.title}
            indexStart={0}
            itemsDisplay={isTablet ? 2 : 3}
            color={COMPANY_COLORS.general.general_color}
          >
            {props.nowPlaying.list.map((item, index) => (
              <div
                style={{
                  flex: 1,
                  cursor: 'pointer',
                  opacity: item.prerequisites.action_has_prerequisites
                    ? item.prerequisites.user_done_prerequisites || item.hasDone
                      ? '1.0'
                      : '0.3'
                    : '1.0',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                onClick={
                  item.type_id === 13
                    ? () => (window.location.href = item.redirect_links[0].url)
                    : item.prerequisites.action_has_prerequisites
                    ? item.prerequisites.user_done_prerequisites || item.hasDone
                      ? () => selectActivity(item.id)
                      : () =>
                          redirect2prerequisite(
                            item.prerequisites.action_prerequisites
                          )
                    : () => selectActivity(item.id)
                }
                key={index}
              >
                <img
                  className="img-fluid"
                  alt="img-fluid"
                  style={{
                    marginBottom: 24,
                    maxWidth: 267.02,
                    maxHeight: 150.31,
                    width: 'auto',
                    height: 'auto',
                    display: 'block',
                    objectFit: 'cover',
                    objectPosition: '50% 50%',
                    borderRadius: '5px',
                  }}
                  src={`${BASE_S3}/${item.image}`}
                ></img>
                <span
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '18px',
                    lineHeight: '24px',
                    height: 48,
                    letterSpacing: '0.25px',
                    marginBottom: 8,
                    color: '#242424',
                  }}
                >
                  {item.name}
                </span>
                {!item.click_me_label || item.click_me_label === '' ? null : (
                  <span
                    style={{
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: '16px',
                      lineHeight: '24px',
                      letterSpacing: '0.25px',
                      color: 'rgb(98, 0, 238)',
                    }}
                  >
                    {`${item.click_me_label}` || 'Participe'}
                  </span>
                )}
              </div>
            ))}
          </Carrousel>
        </div>
      ) : null}
      {/* Categorias Sindilegis */}
      {SINDILEGIS_STATION_ID === COMPANY_STATION_ID && (
        <Grid container spacing={3} style={{ marginTop: 75, marginBottom: 16 }}>
          {renderSindilegisCategories(
            'Assuntos e Pautas Sindicais',
            <LegalPersonIcon style={{ width: 30, height: 'auto' }} />,
            'politico-sindical',
            props.history
          )}
          {renderSindilegisCategories(
            'Processos Judiciais',
            <LegalHammerIcon style={{ width: 30, height: 'auto' }} />,
            'consultoria-juridica',
            props.history
          )}
          {renderSindilegisCategories(
            'Processos Contábeis',
            <CentsIcon style={{ width: 30, height: 'auto' }} />,
            'consultoria-contabil',
            props.history
          )}
          {renderSindilegisCategories(
            'Saúde',
            <ToothIcon style={{ width: 30, height: 'auto' }} />,
            'saude',
            props.history
          )}
          {renderSindilegisCategories(
            'Prestação de Contas',
            <PrestacaoDeContasIcon style={{ width: 30, height: 'auto' }} />,
            'prestacao-de-contas',
            props.history
          )}
        </Grid>
      )}

      {/* Jornadas */}
      {props.journey.list.length > 0 ? (
        <>
          {journeyOrdered.map((item, index) => (
            <div
              style={{
                marginBottom: 48,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Carrousel
                categoryName={item.name}
                key={index}
                journey
                indexStart={item.hasComplete}
                itemsDisplay={isTablet ? 3 : 4}
                color={COMPANY_COLORS.general.general_color}
              >
                {item.actions.map((item, index) => (
                  <div
                    style={{
                      flex: 1,
                      cursor: 'pointer',
                      opacity: item.prerequisites.action_has_prerequisites
                        ? item.prerequisites.user_done_prerequisites ||
                          item.hasDone
                          ? '1.0'
                          : '0.3'
                        : '1.0',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                    onClick={
                      item.type_id === 13
                        ? () =>
                            (window.location.href = item.redirect_links[0].url)
                        : item.prerequisites.action_has_prerequisites
                        ? item.prerequisites.user_done_prerequisites ||
                          item.hasDone
                          ? () => selectActivity(item.id)
                          : () =>
                              redirect2prerequisite(
                                item.prerequisites.action_prerequisites
                              )
                        : () => selectActivity(item.id)
                    }
                    key={index}
                  >
                    <img
                      className="img-fluid"
                      alt="img-fluid"
                      style={{
                        marginBottom: 24,
                        maxWidth: 267.02,
                        maxHeight: 150.31,
                        width: 'auto',
                        height: 'auto',
                        display: 'block',
                        objectFit: 'cover',
                        objectPosition: '50% 50%',
                      }}
                      src={`${BASE_S3}/${item.image}`}
                    ></img>
                    <Typography
                      element="h6"
                      style={{
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '18px',
                        lineHeight: '24px',
                        height: 48,
                        letterSpacing: '0.25px',
                        marginBottom: 24,
                        color: HEADER_TEXT_COLOR,
                      }}
                    >
                      {item.name}
                    </Typography>
                    {!item.click_me_label ||
                    item.click_me_label === '' ? null : (
                      <Typography
                        element="p"
                        style={{
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '16px',
                          lineHeight: '24px',
                          letterSpacing: '0.25px',
                          color: SIGNED_IN_NAV_TEXT_COLOR,
                        }}
                      >
                        {`> ${item.click_me_label}` || '> Participe'}
                      </Typography>
                    )}
                  </div>
                ))}
              </Carrousel>
            </div>
          ))}
        </>
      ) : null}
      {/*  Atividades */}
      <RenderActivities
        categories={props.categories}
        history={props.history}
        selectActivity={selectActivity}
      />
      <Grid item container justifyContent="center">
        <img
          src={`${BASE_S3}/${COMPANY_COLORS.homepage.sidebar.logo}`}
          style={{ height: 50 }}
          className="img-fluid"
          alt="Imagem da estação"
        />
      </Grid>

      <MaterialModal
        open={open}
        onClose={handleClose}
        title={'Quase lá'}
        showTitle
        redirect
        maxWidth={'sm'}
        fullWidth
      >
        <Typography variant="subtitle1">
          Para realizar essa atividade, você precisa primeiro realizar a
          atividade
          <Typography
            variant="subtitle1"
            style={{ textDecoration: 'underline', fontWeight: 530 }}
          >
            {prerequisite.map(
              (item, index) =>
                `${item.name}${index < prerequisite.length - 1 ? ', ' : '.'}`
            )}
          </Typography>
        </Typography>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 10,
          }}
        >
          <ButtonPrimary
            style={{ margin: '8px 0' }}
            type="button"
            onClick={handleClose}
          >
            Ok
          </ButtonPrimary>
        </Box>
      </MaterialModal>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    journey: state.journey,
    activities: state.activities,
    categories: state.categories,
    trophies: state.trophies,
    nowPlaying: state.nowPlaying,
    legisClubFuels: state.legisClubFuels,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionsByEstablishmentId,
      getCouponsByEstablishmentId,
      getActionsByStationId,
      setLoading,
      getPrivateFeaturedActivities,
      getParticipantCategory,
      getActionsByCategory,
      getCategoriesList,
      setFeaturedActivitiesLoad,
      getUserPoints,
      getJourneys,
      getNowPlaying,
      getTrophies,
      getCampaignList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
