import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { parseISO, subMinutes } from 'date-fns';
import { String2Currency } from 'helpers/dataTransformers';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { GeneralTitle } from '../../../../../../common/styles/styles';
import {
  // BASE_S3,
  COMPANY_COLORS,
} from '../../../../../../config/consts';
import { customCurrencyChanger } from '../../../../../../helpers/utils';
import { getListById } from '../../../homepage/productOrders/productOrdersActions';
import { ItemImage, Quantity } from '../../components/ItemCard/styles';
import AsyncConfirmation from '../AsyncConfirmation';
import { OrangeTitle, Receipt, SecSubTitle, Subtitle } from './styles';

const useStyles = makeStyles(() => ({
  paymentInfoItem: {
    margin: '.2rem 0',
    color: '#7E0000',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '22px',
    letterSpacing: '0.25px',
  },
  paymentInfoValue: {
    margin: '.2rem 0',
    color: COMPANY_COLORS.primary,
    fontStyle: 'normal',
    lineHeight: '22px',
    letterSpacing: '0.25px',
  },
  paymentSubInfo: {
    backgroundColor: '',
    margin: '.3rem 0',
    padding: '.3rem 0',
    borderTop: '1px solid rgba(131, 131, 131, 0.5)',
    borderBottom: '1px solid rgba(131, 131, 131, 0.5)',
  },
}));

let SuccessfulPurchase = (props) => {
  const [wasPixConfirmed, setWasPixConfirmed] = useState(false);
  const paymentInfoItems = ['Taxa de entrega', 'Subtotal'];
  const FinalPaymentInfoItems = ['Total do pedido', 'Créditos recebidos'];
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const classes = useStyles();
  const currencyData = {
    currency_multiplier: props.stationData.currency_multiplier,
    currency_name: props.stationData.currency_name,
    currency_name_position: props.stationData.currency_name_position,
  };

  // Receives a array with repeated product items and returns only one
  // with a quantity
  function checkRepeated(repeatedArray) {
    let productIds = [];
    for (let i = 0; i < repeatedArray.length; i += 1) {
      const productIdIndex = productIds.findIndex(
        (item) => item.id === repeatedArray[i].product.id
      );
      if (productIdIndex === -1) {
        productIds.push({ id: repeatedArray[i].product.id, quantity: 1 });
      } else {
        productIds[productIdIndex] = {
          ...productIds[productIdIndex],
          quantity: productIds[productIdIndex].quantity + 1,
        };
      }
    }

    const newArray = productIds.map((item) => {
      const singleProductOrder = repeatedArray.find(
        (secondItem) => item.id === secondItem.product.id
      );
      return {
        ...item,
        ...singleProductOrder,
      };
    });
    return newArray;
  }

  const newArray = props.userShoppingCart.detail.map((item) => ({
    ...item,
    single_product_orders: checkRepeated(item.single_product_orders),
  }));

  function subtotalProducts(productOrders) {
    let subtotal = 0;
    for (let item of productOrders) {
      for (let secondItem of item.single_product_orders) {
        subtotal +=
          (parseFloat(secondItem.product.value) -
            (secondItem.product.discount_value
              ? parseFloat(secondItem.product.discount_value)
              : 0)) *
          parseInt(secondItem.quantity);
      }
    }
    return subtotal;
  }

  function subtotalShipping(productOrders) {
    let subtotal = 0;
    for (let item of productOrders) {
      if (item.delivery_location_id) {
        const deliveryLocationPrice = item.delivery_location.price;
        subtotal += parseFloat(deliveryLocationPrice);
      }
    }
    return subtotal;
  }

  function calculateTotalWalletValue(productOrders) {
    let walletValue = 0;
    for (let item of productOrders) {
      walletValue += parseFloat(item.wallet_value);
    }
    return walletValue;
  }

  function calculateTotalCashbackValue(productOrders) {
    let cashbackValue = 0;
    for (let item of productOrders) {
      cashbackValue +=
        (item.action.additional_cashback
          ? parseFloat(item.action.additional_cashback)
          : 0) + parseFloat(item.action.points);
    }
    return cashbackValue;
  }

  function calculateTotalValue() {
    const totalValue = parseFloat(
      parseFloat(newArray[0].total_credit_card_value) +
        calculateTotalWalletValue(newArray)
    );
    return totalValue;
  }

  if (!wasPixConfirmed && props.formValues.payment?.type === 'pix') {
    return (
      <AsyncConfirmation
        qrCode={props.userShoppingCart.detail[0].gateway_charge.pix_qr_code}
        amount={props.userShoppingCart.detail[0].total_credit_card_value}
        expirationDate={subMinutes(
          parseISO(
            props.userShoppingCart.detail[0].gateway_charge.expiration_date
          ),
          1
        )}
        setPixConfirmation={setWasPixConfirmed}
        productOrderId={props.userShoppingCart.detail[0].id}
        getProductOrder={props.getListById}
        setPurchaseStep={props.setStep}
      />
    );
  }

  return (
    <>
      <Grid item container justifyContent="center" alignItems="center" xs={12}>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          sm={10}
          md={8}
        >
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            xs={12}
            style={{
              marginBottom: '.5rem',
            }}
          >
            <GeneralTitle xs={xs} sm={sm} marginBottom={xs ? '24px' : '48px'}>
              Pedido realizado com sucesso!
            </GeneralTitle>
            <Subtitle>
              Aqui você pode abrir individualmente o comprovante de compra de
              cada produto
            </Subtitle>
          </Grid>
          <Grid item container justifyContent="flex-start" alignItems="center" xs={12}>
            <OrangeTitle>Produtos selecionados</OrangeTitle>
          </Grid>
          {newArray.map((item, index) => (
            <React.Fragment key={item.id}>
              {item.single_product_orders.map((secondItem) => (
                <>
                  <Grid
                    item
                    container
                    xs={12}
                    key={secondItem.id}
                    style={{
                      margin: '.4rem 0',
                    }}
                  >
                    {/* item image */}
                    <Grid
                      item
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      xs={6}
                      // sm={3}
                      md={3}
                    >
                      <ItemImage
                        image={secondItem.product.image}
                        isMobile={sm || xs}
                      ></ItemImage>
                      {/* <img
                        src={ `${BASE_S3}/${secondItem.product.image}` }
                        alt="Imagem do Produto"
                        className="img-fluid"
                        style={{
                          borderRadius: '8px',
                        }}
                      /> */}
                    </Grid>
                    {/* item info */}
                    <Grid
                      item
                      container
                      direction="column"
                      justifyContent={
                        isMobile || xs ? 'space-between' : 'space-between'
                      }
                      alignItems="flex-start"
                      xs={6}
                      md={5}
                      style={{
                        paddingLeft: '.8rem',
                      }}
                    >
                      <div>
                        <p
                          style={{
                            color: '#378587',
                            fontWeight: 500,
                            fontSize: 20,
                          }}
                        >
                          {secondItem.product.name}
                        </p>
                        <p>{secondItem.product.description}</p>
                      </div>
                      <div style={{ display: isMobile || xs ? null : null }}>
                        <Receipt
                          onClick={() =>
                            props.history.push(`/homepage/pedidos/${item.id}`)
                          }
                          // style={{
                          //   color: '#378587',
                          //   fontWeight: 500,
                          // }}
                        >
                          Ver comprovante
                        </Receipt>
                      </div>
                    </Grid>
                    {/* itemSubInfo */}
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                      xs={12}
                      sm={4}
                      style={{
                        margin: (isMobile || xs) && '.3rem 0',
                      }}
                    >
                      {/* quantity + value */}
                      <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        xs={12}
                      >
                        <Quantity>
                          <p>{secondItem.quantity}</p>
                        </Quantity>
                        <div className={classes.paymentInfoValue}>
                          <span className={classes.paymentInfoItem}>
                            Total:{'  '}
                          </span>
                          R${' '}
                          {(
                            (parseFloat(secondItem.product.value) -
                              (secondItem.product.discount_value
                                ? parseFloat(secondItem.product.discount_value)
                                : 0)) *
                            secondItem.quantity
                          )
                            .toFixed(2)
                            .replace('.', ',')}
                        </div>
                      </Grid>
                      {/* button */}
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        xs={8}
                        sm={12}
                        style={{
                          display: isMobile || xs ? 'none' : 'none',
                        }}
                      >
                        <button
                          onClick={() =>
                            props.history.push(`/homepage/pedidos/${item.id}`)
                          }
                          style={{
                            backgroundColor: '#378587',
                            color: '#fff',
                            borderRadius: 7,
                            padding: 12,
                            fontWeight: 700,
                            minWidth: '100%',
                          }}
                        >
                          Ver comprovante
                        </button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ))}
              {item.delivery_location_id ? (
                <>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                  >
                    {/* title */}
                    <Grid
                      item
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      xs={12}
                    >
                      <SecSubTitle>
                        Informações de entrega e retirada
                      </SecSubTitle>
                    </Grid>
                    {/* address */}
                    <Grid
                      item
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                      xs={12}
                      sm={7}
                    >
                      <p>
                        {item.delivery_location.delivery
                          ? item.address
                          : item.delivery_location.street}
                      </p>
                      <br />
                      <p>
                        {item.delivery_location.delivery
                          ? item.reference
                          : item.delivery_location.street_number}
                      </p>
                      <br />
                      <p>
                        {item.delivery_location.delivery
                          ? item.zipcode
                          : item.delivery_location.zipcode}
                      </p>
                    </Grid>
                    {/* info value */}
                    <Grid
                      item
                      container
                      justifyContent="center"
                      alignItems="center"
                      xs={12}
                      sm={5}
                    >
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        style={{
                          margin: '.5rem 0',
                        }}
                      >
                        <Grid
                          item
                          container
                          xs={6}
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-end"
                        >
                          {paymentInfoItems.map((item) => (
                            <p className={classes.paymentInfoItem}>{item}</p>
                          ))}
                        </Grid>
                        <Grid
                          item
                          container
                          xs={6}
                          direction="column"
                          justifyContent="space-between"
                          alignItems="flex-end"
                        >
                          <span className={classes.paymentInfoValue}>
                            {String2Currency(subtotalShipping(newArray))}
                          </span>
                          <span className={classes.paymentInfoValue}>
                            R${' '}
                            {(
                              subtotalProducts(newArray) +
                              subtotalShipping(newArray)
                            )
                              .toFixed(2)
                              .replace('.', ',')}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : null}
              {index !== newArray.length - 1 && (
                <div className="row justifyContent-content-between mt-4">
                  <div className="col-xs-3 col-md-12">
                    <div
                      style={{
                        borderWidth: '0.5px 0 0px 0',
                        borderColor: '#ccccccee',
                        borderStyle: 'solid',
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
          {/* payment subinfo */}
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            xs={12}
            className={classes.paymentSubInfo}
          >
            {parseFloat(newArray[0].total_credit_card_value) > 0 && (
              <>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  xs={12}
                >
                  {newArray[0]?.gateway_charge?.payment_method ===
                  'credit_card' ? (
                    <p>Cartão de crédito</p>
                  ) : (
                    <p>PIX</p>
                  )}
                  <span className={classes.paymentInfoValue}>
                    {String2Currency(newArray[0].total_credit_card_value)}
                  </span>
                </Grid>
              </>
            )}
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              xs={12}
            >
              <p>Carteira virtual</p>
              <span className={classes.paymentInfoValue}>
                {/* R${' '} */}
                {/* {console.log(calculateTotalWalletValue(newArray))} */}
                {customCurrencyChanger(
                  currencyData,
                  calculateTotalWalletValue(newArray)
                )}
                {/* {calculateTotalWalletValue(newArray)
                  .toFixed(2)
                  .replace('.', ',')} */}
              </span>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
            style={{
              margin: '.5rem 0',
            }}
          >
            <Grid
              item
              container
              xs={6}
              sm={9}
              md={10}
              direction="column"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              {FinalPaymentInfoItems.map((item) => (
                <p className={classes.paymentInfoItem}>{item}</p>
              ))}
            </Grid>
            <Grid
              item
              container
              xs={6}
              sm={3}
              md={2}
              direction="column"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <span className={classes.paymentInfoValue}>
                R$ {calculateTotalValue().toFixed(2).replace('.', ',')}
              </span>
              <span className={classes.paymentInfoValue}>
                {/* R${' '} */}
                {customCurrencyChanger(
                  currencyData,
                  calculateTotalCashbackValue(newArray)
                )}
                {/* {calculateTotalCashbackValue(newArray)
                  .toFixed(2)
                  .replace('.', ',')} */}
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

SuccessfulPurchase = reduxForm({
  form: 'userShoppingCartForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(SuccessfulPurchase);

const mapStateToProps = (state) => {
  return {
    userShoppingCart: state.userShoppingCart,
    formValues: getFormValues('userShoppingCartForm')(state),
    stationData: state.website.stationData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getListById }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessfulPurchase);
