import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/styles';
import Backdrop from '@material-ui/core/Backdrop';
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_FAMILY,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
} from '../../../config/consts';
import AlertDialogSlide from '../../../common/components/dialog/dialog';
import UseTerms from '../../../pages/auth/policies/usePolicy';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '100vw',
    backgroundColor: '#ffffff',
    border: 'none',
    padding: '.8rem 0',
    position: 'absolute',
    bottom: 0,
  },
  acceptButton: (props) => ({
    backgroundColor: props.button.background,
    color: props.button.text,
    padding: '.5rem 1.5rem',
    borderRadius: '6px',
    margin: '0 1rem',
  }),
}));

const CookieConsent = (props) => {
  const [open, setOpen] = useState(true);
  const [openModalSec, setOpenModalSec] = useState(false);
  const { navbar } = COMPANY_COLORS.website;
  const classes = useStyles(navbar);

  useEffect(() => {
    if (localStorage.getItem('cookieConsentAcceptance')) {
      setOpen(false);
    }
  }, []);

  function handleClose() {
    localStorage.setItem('cookieConsentAcceptance', true);
    setOpen(false);
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <p id="transition-modal-description">
              Utilizamos cookies para otimizar nosso conteúdo e personalizar sua
              experiência. Confira nossa{' '}
              <a href="/#" onClick={() => setOpenModalSec(true)}>
                Política de Privacidade
              </a>
              .
            </p>
            <button
              className={classes.acceptButton}
              onClick={() => handleClose()}
              style={{
                fontFamily: BUTTON_FONT_FAMILY,
                backgroundColor: BUTTON_BACKGROUND_COLOR,
                color: BUTTON_TEXT_COLOR,
              }}
            >
              Aceito
            </button>
          </div>
        </Fade>
      </Modal>
      <AlertDialogSlide
        onClose={() => {
          setOpenModalSec(false);
        }}
        visible={openModalSec}
        titleBackground={'#FFFF'}
        backgroundColor={'#FFFF'}
      >
        <UseTerms />
      </AlertDialogSlide>
    </>
  );
};

export default CookieConsent;
