/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography,
  // useMediaQuery,
  // useTheme
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import { makeStyles } from '@material-ui/styles';
import GeneralTabs from 'common/components/navigation/actionsTab/tabs';
import { BASE_S3, COMPANY_SIGNED_IN_BACKGROUND_COLOR } from 'config/consts';
import DOMPurify from 'dompurify';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import SimpleBlockSkeleton from '../../../../common/components/skeleton/simpleBlockSkeleton';
import TextBlockSkeleton from '../../../../common/components/skeleton/textBlockSkeleton';
import { GENERAL_TEXT_COLOR } from '../../../../config/consts';
import ActionExpired from '../errors/notFound';
import Shopping from '../shopping';
import {
  getActionById,
  getActionComments,
  setDetail,
  setSubscribed,
  subscribe,
} from './activitiesActions';
import ActivitiesFeed from './activitiesFeed/ActivitiesFeed.jsx';
import ActivityLike from './activitiesFeed/Likes/ActivityLike.jsx';
import { CommentButton } from './activitiesFeed/styles';
import './activity.css';
import ActivityAgreement from './activityAgreement';
import ActivityDonation from './activityDonation';
import ActivityMemberGetMember from './activityMemberGetMember';
import ActivityMemberGetPermission from './activityMemberGetPermission';
import ActivityPresentialCourse from './activityPresentialCourse';
import ActivityProductDelivery from './activityProductDelivery';
import ActivityRawContent from './activityRawContent';
import ActivityRedirectLinkInternal from './activityRedirectLinkInternal';
import ActivityShare from './activityShare';
import ActivitySurvey from './activitySurvey';
import {
  ActivityImageContainer,
  // DomPurifyContentContainer
} from './styles';
import ActivityVideoPlaylist from './videoPlaylist';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: '2rem',
    maxWidth: '100vw',
  },
  likesAndComments: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '.8rem',
  },
}));

function Activity(props) {
  const [expandsComment, setExpandsComment] = useState(false);
  const commentsComponent = useRef(null);
  const { id } = useParams();
  // const theme = useTheme();
  // const xs = useMediaQuery(theme.breakpoints.only('xs'));
  // const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    const flatAction = true;
    props.getActionById(props.auth.user.id, id, flatAction);
  }, []);

  function renderSignupAndWin() {
    return null;
  }

  const executeScroll = () => {
    commentsComponent.current.scrollIntoView({ behavior: 'smooth' });
  };

  function handleCommentButtonClick() {
    setExpandsComment(true);
    setTimeout(() => {
      executeScroll();
    }, 200);
  }

  function renderStart(props) {
    return (
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignContent="center"
        component="main"
        style={{ backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR }}
      >
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          {/* {console.log(props.activities.detail.type_id)} */}
          {props.activities.detail.type_id === 1 && (
            <ActivityMemberGetMember history={props.history} />
          )}
          {props.activities.detail.type_id === 2 && renderSignupAndWin()}
          {props.activities.detail.type_id === 3 && (
            <Shopping history={props.history} />
          )}
          {props.activities.detail.type_id === 4 && (
            <ActivityPresentialCourse history={props.history} />
          )}
          {props.activities.detail.type_id === 5 && (
            <ActivityShare history={props.history} />
          )}
          {(props.activities.detail.type_id === 6 ||
            props.activities.detail.type_id === 7 ||
            props.activities.detail.type_id === 8 ||
            props.activities.detail.type_id === 9 ||
            props.activities.detail.type_id === 10 ||
            props.activities.detail.type_id === 11 ||
            props.activities.detail.type_id === 12) && (
            <ActivitySurvey history={props.history} />
          )}
          {props.activities.detail.type_id === 14 && (
            <ActivityProductDelivery history={props.history} />
          )}
          {props.activities.detail.type_id === 15 && (
            <ActivityAgreement history={props.history} />
          )}
          {props.activities.detail.type_id === 16 && (
            <ActivityRawContent history={props.history} />
          )}
          {props.activities.detail.type_id === 17 && (
            <ActivityDonation history={props.history} />
          )}
          {props.activities.detail.type_id === 18 && (
            <ActivityVideoPlaylist
              handleCommentButtonClick={handleCommentButtonClick}
              history={props.history}
            />
          )}
          {props.activities.detail.type_id === 19 && (
            <ActivityMemberGetPermission history={props.history} />
          )}
          {props.activities.detail.type_id === 20 && (
            <ActivityRedirectLinkInternal history={props.history} />
          )}
        </Grid>
      </Grid>
    );
  }

  function renderRules(props) {
    return (
      <Grid item container component="section" alignItems="center" xs={12}>
        <Typography variant="body1" component="div">
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.activities.detail.rules),
            }}
          ></div>
          {/* <DomPurifyContentContainer
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(props.activities.detail.rules),
              }}
            /> */}
        </Typography>
      </Grid>
    );
  }

  if (!props.activities.loading && _.isEmpty(props.activities.detail)) {
    return <ActionExpired />;
  }

  if (props.activities.loading) {
    return (
      <Grid
        container
        item
        alignItems="flex-start"
        xs={12}
        style={{ minHeight: '100vh', backgroundColor: '' }}
        spacing={0}
      >
        <SimpleBlockSkeleton height={600} width="100%" />
        <TextBlockSkeleton numberOfRows={10} />
      </Grid>
    );
  }

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
      style={{ backgroundColor: '' }}
    >
      <Helmet>
        <meta
          property="og:image"
          content={`${BASE_S3}/${props.activities.detail.image}`}
        />
      </Helmet>
      <Grid
        item
        container
        xs={12}
        justifyContent="center"
        alignItems="center"
        component="section"
      >
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          md={12}
          component="article"
        >
          {props.activities.detail.type_id !== 18 ? (
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              spacing={0}
              style={{
                margin: '.5rem 0',
              }}
            >
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                alignItems="center"
              >
                <ActivityImageContainer
                  BASE_S3={BASE_S3}
                  imagePath={props.activities.detail.image}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                justfy="flex-start"
                alignItems="center"
              >
                {props.activities.detail.comments_and_likes_enabled ? (
                  <>
                    <Box component="div" className={classes.likesAndComments}>
                      <ActivityLike />
                    </Box>
                    <Box component="div" className={classes.likesAndComments}>
                      <CommentButton
                        onClick={() => handleCommentButtonClick()}
                        style={{ color: GENERAL_TEXT_COLOR }}
                      >
                        <ChatBubbleOutlineOutlinedIcon
                          style={{ color: GENERAL_TEXT_COLOR }}
                        />
                        {props.activities.allDataComments === undefined
                          ? '...'
                          : props.activities.allDataComments.length}
                      </CommentButton>
                    </Box>
                  </>
                ) : null}
              </Grid>
            </Grid>
          ) : null}
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            xs={12}
          >
            <Typography variant="h4">{props.activities.detail.name}</Typography>
          </Grid>
          <GeneralTabs
            start={renderStart(props)}
            rules={renderRules(props)}
            hiddenTabs={
              props.activities.detail.type_id === 16 ||
              !props.activities.detail.has_rules
            }
            component="article"
          />
          {props.activities.detail.comments_and_likes_enabled ? (
            <Grid
              item
              container
              xs={12}
              component="section"
              ref={commentsComponent}
            >
              <ActivitiesFeed
                expandsComment={expandsComment}
                setExpandsComment={() => setExpandsComment(!expandsComment)}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
    website: state.website,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionById,
      setDetail,
      subscribe,
      setSubscribed,
      getActionComments,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
