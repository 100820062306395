/* eslint-disable react-hooks/exhaustive-deps */
import AlertDialogSlide from 'common/components/dialog/generalDialog';
import {
  COMPANY_COLORS,
  COMPANY_SIGNED_OFF_BACKGROUND_COLOR,
} from 'config/consts';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import LoadingProgress from '../../../../../common/components/progress/loading';
import { COMPANY_STATION_ID } from '../../../../../config/consts';
import PrivacyPolicy from '../../../../../pages/auth/policies/privacyPolicy';
import UseTerms from '../../../../../pages/auth/policies/usePolicy';
import NavigationBar from '../../../../common/components/Header/navigationBar';
import Footer from '../../../../website/modules/footer';
import '../../../../website/website.css';
import { getAboutList } from '../../../../website/websiteActions';
import PlanDetail from './planDetail';
import { getDetail } from './plansActions';

let PlansLoggedOffDetail = function (props) {
  const { history } = props;
  const { id } = useParams();

  function favicon(href) {
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = `https://s3.amazonaws.com/rilato.app.files/${href}`;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  useEffect(() => {
    if (id) {
      props.getDetail(id);
    }
  }, [id]);

  const [openModal, setOpenModal] = useState(false);
  const [openModalSec, setOpenModalSec] = useState(false);
  favicon(COMPANY_COLORS.general.favicon);

  useEffect(() => {
    props.getAboutList(COMPANY_STATION_ID);
  }, []);

  return (
    <div
      className="container-fluid station-container"
      style={{
        backgroundColor: COMPANY_SIGNED_OFF_BACKGROUND_COLOR,
        minHeight: '100vh',
      }}
    >
      <NavigationBar
        isMainPage={false}
        website={props.website}
        history={history}
      />
      {props.plans.loading ? (
        <LoadingProgress />
      ) : props.plans.detail.id ? (
        <article id="contato">
          <PlanDetail
            selectedPlan={props.plans.detail}
            onClick={() => props.history.push('/cadastrar')}
          />
        </article>
      ) : null}
      <Footer
        setOpenModal={setOpenModal}
        setOpenModalSec={setOpenModalSec}
        website={props.website}
        history={props.history}
      />
      <AlertDialogSlide
        onClose={() => {
          setOpenModal(false);
        }}
        visible={openModal}
        titleBackground={"#FFFF"}
        backgroundColor={"#FFFF"}
      >
        <PrivacyPolicy />
      </AlertDialogSlide>
      <AlertDialogSlide
        onClose={() => {
          setOpenModalSec(false);
        }}
        visible={openModalSec}
        titleBackground={"#FFFF"}
        backgroundColor={"#FFFF"}
      >
        <UseTerms />
      </AlertDialogSlide>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    website: state.website,
    plans: state.plans,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getDetail, getAboutList }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlansLoggedOffDetail);
