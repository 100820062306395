const configConsts = window.configConsts();
//fixo
export const BASE_API =
  process.env.REACT_APP_API_KEY === 'production'
    ? 'https://apihub.rilato.com.br/api'
    : process.env.REACT_APP_API_KEY === 'test'
    ? 'http://localhost:8080/api'
    : 'http://localhost:8080/api';

export const BASE_AUTH =
  process.env.REACT_APP_API_KEY === 'production'
    ? 'https://apihub.rilato.com.br/api/auth'
    : process.env.REACT_APP_API_KEY === 'test'
    ? 'http://localhost:8080/api/auth'
    : 'http://localhost:8080/api/auth';

export const BASE_SOCKET =
  process.env.REACT_APP_API_KEY === 'production'
    ? 'http://18.228.25.41:5000/'
    : process.env.REACT_APP_API_KEY === 'test'
    ? 'http://52.67.160.89:5000/'
    : 'http://52.67.160.89:5000/';

export const BASE_S3 = 'https://s3.amazonaws.com/rilato.app.files';

export const USER_KEY = '_fermentoLabParticipantUser';
export const USER_TOKEN = '_fermentoLabParticipantUserToken';
//variavel

const newItems = {
  ...configConsts.objectNewItemsBody,
};

export const COMPANY_NAME = configConsts.COMPANY_NAME;
export const COMPANY_DARK_COLOR = configConsts.COMPANY_DARK_COLOR;
export const COMPANY_ID = configConsts.COMPANY_ID;
export const COMPANY_SUBDOMAIN = configConsts.COMPANY_SUBDOMAIN;
// export const COMPANY_STATION_ID = configConsts.station_id;
export const COMPANY_STATION_ID = parseInt(configConsts.station_id);
export const COMPANY_FONT_FAMILY = newItems.text_font_family || 'Lato';
export const NAV_FONT_FAMILY = newItems.nav_font_family || 'Lato';
export const HEADER_FONT_FAMILY = newItems.header_font_family || 'Lato';
export const COMPANY_SIGNED_IN_BACKGROUND_IMAGE =
  newItems.signed_in_background_image;
export const COMPANY_SIGNED_IN_BACKGROUND_COLOR =
  newItems.signed_in_background_color || '#fff';
export const COMPANY_SIGNED_OFF_BACKGROUND_COLOR =
  newItems.signed_off_background_color || '#fff';
export const HEADER_TEXT_COLOR = newItems.general_color;
export const SIGNED_IN_NAV_TEXT_COLOR =
  newItems.signed_in_nav_text_color || '#000';
export const SIGNED_OFF_NAV_TEXT_COLOR =
  newItems.signed_off_nav_text_color || '#fff';
export const GENERAL_TEXT_COLOR = newItems.body_dark_font_color;
export const BUTTON_BACKGROUND_COLOR = newItems.body_button_background_color;
export const BUTTON_BORDER_COLOR = newItems.body_button_border_color;
export const BUTTON_TEXT_COLOR = newItems.body_button_font_color;
export const BUTTON_FONT_FAMILY = NAV_FONT_FAMILY;
export const LINK_TEXT_COLOR = newItems.body_link_color;
export const LINK_FONT_FAMILY = NAV_FONT_FAMILY;

//outras parametrizacoes

export const COMPANY_LOGO = `${BASE_S3}/${configConsts.company_logo}`;
export const COMPANY_LOGO_SECONDARY = `${BASE_S3}/${configConsts.company_logo_secondary}`;

export const COMPANY_ENTERPRISE_PLAN_BENEFITS = [
  {
    benefit: 'Grande número de participantes',
  },
  {
    benefit: 'Integrações de sistema',
  },
  {
    benefit: 'Mais armazenamento',
  },
  {
    benefit: 'Suporte 24/7',
  },
  {
    benefit: 'Especialistas de gestão',
  },
  {
    benefit: 'Criação de campanhas',
  },
];

export const COMPANY_COLORS = {
  primary: newItems.general_color,
  dark: configConsts.COMPANY_DARK_COLOR,
  gray: '#525252',
  secondary: '#525252',
  footer: '#525252',
  font: {
    gray: '#525252',
    light: configConsts.COMPANY_LIGHT_FONT_COLOR,
    dark: configConsts.COMPANY_DARK_FONT_COLOR,
  },
  error: '#FF5A52',
  success: '#52C22C',
  activityCard: {
    muted: '#999999',
    text: newItems.body_dark_font_color,
    callToAction: newItems.body_link_color || `#006ce6`,
    reward: '#666666',
    chevron: '#cccccc',
  },
  aboutCard: {
    title: newItems.body_dark_font_color,
    text: newItems.body_dark_font_color,
  },
  website: {
    header: {
      image: newItems.header_background_image,
      mobile_image: newItems.header_background_mobile_image,
      background_back_header: newItems.background_back_header,
      background_auth_station: newItems.background_auth_station,
    },
    desktop_headers: [
      newItems.header_background_image,
      newItems.header_background_image_1,
      newItems.header_background_image_2,
      newItems.header_background_image_3,
      newItems.header_background_image_4,
    ],
    mobile_headers: [
      newItems.header_background_mobile_image,
      newItems.header_background_mobile_image_1,
      newItems.header_background_mobile_image_2,
      newItems.header_background_mobile_image_3,
      newItems.header_background_mobile_image_4,
    ],
    body: {
      title: newItems.body_dark_font_color,
      text: newItems.body_dark_font_color,
      firstBackground: newItems.body_light_color,
      secondBackground: newItems.body_dark_color,
      thirdBackground: newItems.body_light_color || `#f1f2f4`,
    },

    footer: {
      background: newItems.footer_background_color || `#f69376`,
      text: newItems.footer_font_color,
      company_logo: newItems.footer_company_logo || configConsts.company_logo,
    },
    copyright: {
      background: '#FFFFFFs',
      text: '#666666',
    },
    navbar: {
      burger: '#333333',
      text: newItems.navbar_font_color,
      button: {
        background: newItems.navbar_button_background_color,
        text: newItems.navbar_button_font_color,
        border: newItems.navbar_button_border_color,
      },
      station_primary_logo:
        newItems.navbar_station_primary_logo || configConsts.station_logo,
      station_secondary_logo:
        newItems.navbar_station_secondary_logo || configConsts.station_logo,
    },
  },
  homepage: {
    navbar: {
      background: newItems.navbar_background_color || 'black',
      button: newItems.navbar_button_color || 'white',
      title: newItems.navbar_title_color || 'white',
    },
    sidebar: {
      detail_color: newItems.body_link_color,
      logo: newItems.navbar_station_secondary_logo,
    },
  },
  station: {
    sidebar: {
      icon: {
        active: '#666666',
        default: newItems.general_color,
      },
      text: {
        active: '#333333',
        default: newItems.general_color,
      },
    },
    topbar: {
      background: newItems.general_color,
      text: newItems.body_light_color,
    },
  },
  buttons: {
    primaryButton: {
      background: newItems.navbar_button_background_color,
      text: newItems.navbar_button_font_color,
      border: newItems.navbar_button_border_color,
    },
    secondaryButton: {
      background: newItems.body_button_background_color || `#0284fe`,
      text: newItems.body_button_font_color || `#ffffff`,
      border: newItems.body_button_border_color,
    },
  },
  link: newItems.body_link_color || `#006ce6`,
  form: {
    general: '#999999',
    active: '#666666',
    hover: '#666666',
  },
  general: {
    favicon: newItems.general_favicon_image,
    general_color: newItems.general_color,
    primaryButton: {
      background: newItems.navbar_button_background_color,
      text: newItems.navbar_button_font_color,
      border: newItems.navbar_button_border_color,
    },
    secondaryButton: {
      background: newItems.body_button_background_color || `#0284fe`,
      text: newItems.body_button_font_color || `#ffffff`,
      border: newItems.body_button_border_color,
    },
    link: newItems.body_link_color || `#006ce6`,
    text: newItems.body_dark_font_color || `#333333`,
    form: {
      general: '#999999',
      active: '#666666',
      hover: '#666666',
    },
  },
};

export const COMPANY_INFO = {
  address: configConsts.address,
  phone: configConsts.phone,
  mail: configConsts.mail,
};

export const COMPANY_WEBSITE_SECTIONS = {
  first_section_title: newItems.first_section_title,
  first_section_subtitle: newItems.first_section_subtitle,
  first_section_text: newItems.first_section_text,
  second_section_title: newItems.second_section_title || 'Baixe o app',
  second_section_subtitle: newItems.second_section_subtitle,
  third_section_title: newItems.third_section_title,
  third_section_subtitle: newItems.third_section_subtitle,
  third_section_text: newItems.third_section_text,
};

export const COMPANY_WEBSITE_IMAGES = {
  background_back_header: newItems.background_back_header,
  header_background_image: newItems.header_background_image,
  header_background_mobile_image: newItems.header_background_mobile_image,
  image_download_app: newItems.image_download_app,
  image_download_app_mobile: newItems.image_download_app_mobile,
  link_download_app: newItems.download_app_link,
};

export const SINDILEGIS_STATION_ID =
  process.env.REACT_APP_API_KEY === 'production'
    ? 1
    : process.env.REACT_APP_API_KEY === 'test'
    ? 1
    : 1;

export const ASTCU_STATION_ID =
  process.env.REACT_APP_API_KEY === 'production'
    ? 148
    : process.env.REACT_APP_API_KEY === 'test'
    ? 31
    : 5;

export const FANPROJECTS_STATION_ID =
  process.env.REACT_APP_API_KEY === 'production'
    ? 13
    : process.env.REACT_APP_API_KEY === 'test'
    ? 28
    : 2;

export const BRB_FLA_STATION_ID =
  process.env.REACT_APP_API_KEY === 'production'
    ? 171
    : process.env.REACT_APP_API_KEY === 'test'
    ? 3
    : 2;

export const CLUBS_STATION_ID =
  process.env.REACT_APP_API_KEY === 'production'
    ? 174
    : process.env.REACT_APP_API_KEY === 'test'
    ? 6
    : 5;

export const SINDJUSCLUB_STATION_ID =
  process.env.REACT_APP_API_KEY === 'production'
    ? 178
    : process.env.REACT_APP_API_KEY === 'test'
    ? 6
    : 5;


export const IS_FAN = COMPANY_STATION_ID === FANPROJECTS_STATION_ID;
export const IS_SINDILEGIS = COMPANY_STATION_ID === SINDILEGIS_STATION_ID;
export const IS_ASTCU = COMPANY_STATION_ID === ASTCU_STATION_ID;
export const IS_BRB_FLA = COMPANY_STATION_ID === BRB_FLA_STATION_ID;
export const IS_CLUBS = COMPANY_STATION_ID === CLUBS_STATION_ID;
export const IS_SINDJUSCLUB = COMPANY_STATION_ID === SINDJUSCLUB_STATION_ID;
export const STATIONS_CPF_LOGIN = [SINDILEGIS_STATION_ID,CLUBS_STATION_ID,SINDJUSCLUB_STATION_ID];

