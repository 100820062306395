import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { InputLabelOutlined, SelectLabel } from '../../fields/fields';
import { phoneMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import { handleWhiteSpaces } from 'helpers/dataTransformers';
import { bindActionCreators } from 'redux';
import { sendPreRegistrationChangeRequest } from './preRegistrationChangeActions';

const PreRegistrationChangeForm = ({
  handleSubmit,
  reset,
  sendPreRegistrationChangeRequest,
}) => {
  const [telefonefixo, setTelefone] = useState(null);
  const [celular, setCelular] = useState(null);
  const [email, setEmail] = useState(null);
  const [situacao, setSituacao] = useState(null);
  const [endereco, setEndereco] = useState(null);

  const onSubmit = (values) => {
    function cleanup() {
      reset();
    }
    sendPreRegistrationChangeRequest(values, cleanup);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6}>
          <Field
            name="telefoneFixo"
            component={InputLabelOutlined}
            label="Telefone Fixo"
            type="text"
            onChange={(e) => setTelefone(e.target.value)}
            {...phoneMask}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Field
            name="celular"
            component={InputLabelOutlined}
            label="Celular"
            type="text"
            onChange={(e) => setCelular(e.target.value)}
            {...phoneMask}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name="email"
            component={InputLabelOutlined}
            label="E-mail"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            required
            normalize={handleWhiteSpaces}
            validate={[FORM_RULES.email]}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Field
            name="situacao"
            component={SelectLabel}
            label="Situação"
            options={[
              { value: 'Ativo', label: 'Ativo' },
              { value: 'Aposentado', label: 'Aposentado' },
              { value: 'Inativo', label: 'Inativo' },
              { value: 'Pensionista', label: 'Pensionista' },
            ]}
            onChange={(e) => setSituacao(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="endereco"
            component={InputLabelOutlined}
            label="Endereço"
            type="text"
            onChange={(e) => setEndereco(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <button
            className="mt-5"
            style={{
              backgroundColor: '#38817f',
              padding: '16px 48px',
              color: '#fff',
            }}
            onClick={() =>
              onSubmit({ telefonefixo, celular, email, situacao, endereco })
            }
          >
            Enviar
          </button>
        </Grid>
      </Grid>
    </form>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  general: state.general,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendPreRegistrationChangeRequest,
    },
    dispatch
  );

const ConnectedPreRegistrationChangeForm = reduxForm({
  form: 'preRegistrationChangeForm',
})(PreRegistrationChangeForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedPreRegistrationChangeForm);
