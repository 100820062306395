import { Box } from '@material-ui/core';
import React from 'react';

// import { Container } from './styles';

function PoliticalContactsCard({
  name,
  cel,
  tel,
  email,
  image = 'https://s3.amazonaws.com/rilato.app.files/files/system/sindilegis/default.png',
  role,
}) {
  return (
    <Box display="flex">
      <Box style={{ width: '30%' }}>
        <img
          src={image}
          alt="Imagem do contato"
          className="img-fluid"
          style={{ borderRadius: '50%' }}
        />
      </Box>
      <Box ml={2} style={{ width: '60%' }}>
        <p className="h6-bold" style={{ color: '#282828' }}>
          {name}
        </p>
        <p className="h7" style={{ color: '#838383' }}>
          {role}
        </p>
        {/*  {tel && (
          <p className="h7" style={{ color: '#838383' }}>
            {tel}
          </p>
        )}

        {cel && (
          <p className="h7" style={{ color: '#838383' }}>
            {cel}
          </p>
        )}

        <p className="h7" style={{ color: '#282828' }}>
          {email}
        </p> */}
      </Box>
    </Box>
  );
}

export default PoliticalContactsCard;
