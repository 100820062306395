import { Box, Grid } from '@material-ui/core';
import React from 'react';
import TextCard from '../../../../../common/components/cards/textCard';

export default function Accounting() {
  return (
    <section id="accounting">
      <p className="body-title mt-4">Processos contábeis</p>
      <p className="h4 mt-4" style={{ color: '#838383', maxWidth: 500 }}>
        Acesse os serviços da Consultoria Contábil, que tem o objetivo de
        auxiliar os filiados na resolução de problemas na área contábil:
      </p>

      <Grid container spacing={3} style={{ marginTop: 64 }}>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/consultoria-contabil/processos">
              Processos e procedimentos
            </TextCard>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <TextCard href="#/homepage/consultoria-contabil/agendamentos">
              Agendar consulta com contador
            </TextCard>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
}
