import { Grid } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from '../../../../common/components/spinner/spinner';
import { getActionById } from '../activities/activitiesActions';
import { getListByUserId as getUserCreditCards } from '../homepage/creditCards/creditCardsActions';
import Cart from './pages/Cart';
import CartSurvey from './pages/CartSurvey';
import {
  destroyForm,
  getProductStock,
  getUserAddresses,
} from './shoppingActions';
import { Container } from './styles';

function Shopping(props) {
  useEffect(() => {
    // props.getActionById(props.auth.user.id, props.match.params?.id);
    props.getUserAddresses(props.auth.user.id);
    props.getUserCreditCards(props.auth.user.id);
    props.destroyForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (props.activities.loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '92%', backgroundColor: 'transparent' }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <Container>
      <Grid item container justifyContent="center" alignItems="center" xs={12}>
      {_.isEmpty(props.activities.detail.researches[0]?.questions)
        ? <Cart
            activity={props.activity}
            props={props}
            products={props.activity.products}
            history={props.history}
          />
        : <CartSurvey
            activity={props.activity}
            props={props}
            products={props.activity.products}
            history={props.history}
          />
      }
      </Grid>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activity: state.activities.detail,
    activities: state.activities,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionById,
      getUserAddresses,
      getUserCreditCards,
      destroyForm,
      getProductStock,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Shopping);
