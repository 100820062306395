/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { BASE_S3, COMPANY_COLORS } from '../../../../../../../config/consts';
import {
  getCommentsByPost,
  postComment,
  deleteComment,
} from './commentsActions';
import moment from 'moment';

import { Container, Form, CommentLine } from './styles';

class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: '',
      comments: [],
    };
  }

  setComments = (comments) => {
    this.setState({ comments });
  };

  componentDidUpdate(props, state) {
    if (props.visible !== this.props.visible) {
      this.props.getCommentsByPost(props.postId, this.setComments);
    }
  }

  handleDelete = async (id, postId, setComment) => {
    await this.props.deleteComment(id, postId, setComment);
    // const newComments = this.state.comments.filter((item) => item.id !== id);
    // this.setState({ comments: newComments });
  };

  handleEnter = async (event) => {
    if (event.keyCode === 13 && this.state.description) {
      const values = {
        description: this.state.description,
        user: this.props.auth.user.id,
        post: this.props.postId,
      };
      event.preventDefault();
      await this.props.postComment(values, this.setComments);
      this.setState({ description: '' });
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleEnter, false);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEnter, false);
  }

  render() {
    return (
      <Container visible={this.props.visible}>
        {this.state.comments.map((comment) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              width: 'fit-content',
            }}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}
            >
              <CommentLine>
                <img
                  src={
                    comment.user.image
                      ? `${BASE_S3}/${comment.user.image}`
                      : 'https://s3.amazonaws.com/rilato.app.files/files/default_profile.png'
                  }
                  alt="profile_image"
                />
                <section>
                  <p>{`${comment.user.name.split(' ')[0]} - ${moment(
                    comment.createdAt
                  ).fromNow()}`}</p>
                  <span>{comment.description}</span>
                </section>
              </CommentLine>
              {parseInt(comment.user_id) ===
                parseInt(this.props.auth.user.id) && (
                <DeleteIcon
                  style={{
                    color: COMPANY_COLORS.primary,
                    marginLeft: 10,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    this.handleDelete(
                      comment.id,
                      this.props.postId,
                      this.setComments
                    )
                  }
                />
              )}
            </div>

            <p style={{ margin: 0, width: 'fit-content', fontSize: 11 }}>
              {moment(comment.createdAt).fromNow()}
            </p>
          </div>
        ))}

        <Form>
          <header>
            <img
              src={
                this.props.auth.user.image
                  ? `${BASE_S3}/${this.props.auth.user.image}`
                  : 'https://s3.amazonaws.com/rilato.app.files/files/default_profile.png'
              }
              alt="profile_image"
            />
            <input
              id="myInput"
              type="text"
              placeholder="Escreva um comentário..."
              value={this.state.description}
              onChange={(e) => this.setState({ description: e.target.value })}
            />
          </header>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    comments: state.comments,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getCommentsByPost, postComment, deleteComment },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
