import axios from "axios";
import { BASE_API, USER_TOKEN } from "config/consts";
import { toastr } from "react-redux-toastr";

const URL_PRE_REGISTRATION_CHANGE =`${BASE_API}/pre-registration-change`

export const sendPreRegistrationChangeRequest = (values, cleanup)=>{
    return (dispatch) =>{
        dispatch({type: 'PRE_REGISTRATION_CHANGE_LOAD', payload: true});
        axios
        .post(`${URL_PRE_REGISTRATION_CHANGE}`, values, 
            {
            headers: {
                Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`
            },
        }
    )
        .then((response)=>{
            dispatch([{type: 'PRE_REGISTRATION_CHANGE_LOAD', payload: false}])
            cleanup();
            toastr.success('Sucesso', 'Solicitação enviada com sucesso!')
        })
        .catch((e)=>{
            dispatch([{type: 'PRE_REGISTRATION_CHANGE_LOAD', payload: false}])
            try{
                toastr.error('Erro', e.response.data.messages[0])
            } catch {
                toastr.error('Erro')
            }
        })
    }
}