import React, { Component } from 'react';
import './usePolicyTextStyle.css';
import moment from 'moment';

export default class UsePolicyText extends Component {
  render() {
    const {
      socialName,
      cnpj,
      address,
      stationName,
      siteAddress,
      createdAt,
    } = this.props;
    if (address && cnpj) {
      return (
        <div
          className="use-policy-text"
          style={{
            backgroundColor: '#FFFF',
            color: '#000'
          }}
        >
          <h2>Termos e condições de uso</h2>
          <p style={{ textAlign: 'right', marginTop: 32 }}>
            Última atualização:{' '}
            {moment(createdAt).format('Sexta-feira, 05 [de] maio [de] 2023')}
          </p>
          <h5>O que é</h5>
          <p>
            O {stationName} é um programa de relacionamento criado e mantido por{' '}
            {socialName}, pessoa jurídica de direito privado, inscrita no CNPJ
            sob o nº{' '}
            {`${cnpj[0]}${cnpj[1]}.${cnpj[2]}${cnpj[3]}${cnpj[4]}.${cnpj[5]}${cnpj[6]}${cnpj[7]}/${cnpj[8]}${cnpj[9]}${cnpj[10]}${cnpj[11]}-${cnpj[12]}${cnpj[13]}`}{' '}
            com sede e foro na cidade de {address.city} - {address.line_1},{' '}
            {address.line_2} - que busca recompensar e reconhecer os
            participantes por meio de ações mantidas por {socialName} e por
            parceiros anunciantes.
          </p>
          <h5>Conta de Acesso</h5>
          <p>
            A adesão ao {stationName} é voluntária, gratuita e aberta a todos os
            filiados ao {socialName}, inscritos no CPF (Cadastro de Pessoa
            Física) e com idade superior a 18 (dezoito) anos. A conta de acesso
            ao {stationName} deverá ser criada pelo próprio participante no
            ambiente digital do programa de relacionamento (aplicativo ou
            website). Os participantes que não possuírem CPF ficarão
            impossibilitados de aderir ao programa de relacionamento{' '}
            {stationName}.
          </p>
          <p>
            Compete unicamente ao participante a responsabilidade integral de
            preencher seus dados cadastrais com informações claras e precisas,
            inclusive de pessoas por ele adicionadas, assumindo, ainda, o
            compromisso de atualizar os referidos dados sempre que neles ocorrer
            alguma alteração. Essas atualizações deverão ser realizadas sempre
            no ambiente digital do Programa de Relacionamento {stationName}.
          </p>
          <p>
            Caso detectada alguma conta de acesso criada a partir de dados,
            conteúdos ou informações falsas, a conta de acesso do Participante
            será imediata e automaticamente bloqueada, de forma que ele não mais
            terá acesso ao Programa de Relacionamento {stationName} e a qualquer
            Serviço ou conteúdo, não assistindo ao participante, por esse
            motivo, qualquer indenização ou ressarcimento.
          </p>
          <p>
            Todas as informações fornecidas pelos participantes são
            confidenciais e estão sujeitas aos termos da Política de
            Privacidade.
          </p>
          <p>
            O participante reconhece e concorda, desde já, que o Programa de
            Relacionamento {stationName} irá obter, tratar e armazenar dados por
            meio de utilização do ambiente digital (aplicativo e site) com o
            objetivo de identificar, segmentar e sugerir ações direcionadas pelo
            {stationName} e por parceiros anunciantes.
          </p>
          <p>
            Caso os parceiros anunciantes recebam informações do participante,
            deverão, igualmente, tratar com confidencialidade e cumprir com os
            termos da Política de Privacidade do Programa de Relacionamento{' '}
            {stationName}.
          </p>
          <h5>Filiados</h5>
          <p>
            A indicação dos participantes que são filiados é de responsabilidade
            do {socialName} e se dará por meio da integração dos sistemas de
            gestão do programa de relacionamento e de controle da entidade
          </p>
          <h5>Recompensas e reconhecimento</h5>
          <p>
            O objetivo do Programa de Relacionamento {stationName} é recompensar
            e reconhecer os filiados que participam ativamente e impulsionam o
            {socialName}.
          </p>
          <p>
            A participação no Programa de Relacionamento poderá ser recompensada
            pelo mantenedor das ações veiculadas no {stationName}.
          </p>
          <h5>Ações</h5>
          <p>
            O {stationName}, por meio do seu ambiente digital, veicula ações
            (conteúdos, atividades e oportunidades) criadas e mantidas pelo
            próprio {socialName} e/ou por parceiros anunciantes.
          </p>
          <p>
            A participação em ações do Programa de Relacionamento {stationName}{' '}
            é voluntária. As regras e condições de participação serão
            estabelecidas em cada ação, considerando todas as premissas da
            legislação vigente, deste termo de uso e da Política de Privacidade.
          </p>
          <p>
            Nenhum participante poderá utilizar softwares spider, ou de
            mineração de dados, de qualquer tipo ou espécie, além de outro aqui
            não tipificado, mas que atue de modo automatizado, tanto para
            realizar operações massificadas como para quaisquer outras
            finalidades.
          </p>
          <p>
            Os dados de participação em ações do Programa de Relacionamento{' '}
            {stationName} poderão ser utilizados pelo
            {socialName} e por parceiros anunciantes para envio de ações
            direcionadas, considerando a Política de Privacidade.
          </p>
          <h5>Carteira {stationName}</h5>
          <p>
            A Carteira {stationName} é uma conta destinada à execução de
            transações em moeda eletrônica, dentro do ambiente digital do
            {stationName}.{' '}
          </p>
          <p>
            A Carteira {stationName} controlará os valores recebidos pelo
            participante que interagir com as ações do {stationName} e também
            poderá receber crédito para realização de pagamentos específicos
            dentro do ambiente digital do {stationName}.
          </p>
          <p>
            O controle da Carteira {stationName} será efetuado pelo próprio
            participante por meio de extrato de transações dentro dos canais
            digitais do
            {stationName}.
          </p>
          <h5>Crédito na Carteira {stationName}</h5>
          <p>
            O depósito de valores na Carteira {stationName} poderá ser efetuado
            por meio de Cartão de Crédito, Débito e Boleto Bancário.{' '}
          </p>
          <p>
            Depósitos de valores na Carteira {stationName} utilizando Cartão de
            Crédito ou Débito poderão ser estornados para o meio de pagamento
            utilizado, considerando a disponibilidade da integralidade do valor,
            dos prazos e limites dos meios de pagamento em questão.
          </p>
          <h5>Resgate de Saldo da Carteira {stationName}</h5>
          <p>
            Ao realizar a adesão ao {stationName} o participante garante possuir
            Conta Corrente de sua titularidade. Esta conta corrente deverá ser
            utilizada para pedidos de resgate. É vedada a utilização de
            conta-salário para pedidos de resgate.
          </p>
          <p>
            O resgate do saldo da Carteira
            {stationName} poderá ser solicitado pelos ambientes digitais do
            programa de relacionamento (Site e Aplicativo).
          </p>
          <p>
            Para solicitar Resgate de Saldo o usuário deverá ter atingido R$
            200,00 em recompensas por participação em ações do {stationName} ou
            ter efetuado depósito de valores na Carteira {stationName} por meio
            de cartão de crédito, débito ou boleto.
          </p>
          <p>
            O resgate dos valores acumulados ou não utilizados pelo participante
            deverá responder aos princípios da razoabilidade e boa-fé.
          </p>
          <p>
            À Sumday Serviços em Software é reservado o direito de bloquear o resgate de
            valores disponíveis na Carteira {stationName} quando identificado
            algum tipo de transação que fuja dos padrões e dos objetivos do
            programa de relacionamento. No caso de bloqueio, será solicitado ao
            participante o envio de cópias autenticadas de documentos que
            permitam a avaliação da transação.
          </p>
          <span>
            O valor solicitado será creditado na conta bancária em nome do
            participante desde que não haja erros nos dados bancários, conforme
            as seguintes condições:
            <table>
              <tbody>
                <tr>
                  <th>Tipo de depósito</th>
                  <th>Saldo mínimo, taxas e prazo</th>
                </tr>
                <tr>
                  <td>Recompensa por participação</td>
                  <td>
                    Saldo mínimo de R$ 200,00 em até 7 dias úteis a partir da
                    data de solicitação de resgate.
                  </td>
                </tr>
                <tr>
                  <td>Boleto Bancário</td>
                  <td>
                    Saldo mínimo de R$ 200,00 em até 7 dias úteis a partir da
                    data de solicitação de resgate.
                  </td>
                </tr>
                <tr>
                  <td>Cartão de débito</td>
                  <td>
                    Saldo mínimo de R$ 200,00 em até 7 dias úteis a partir da
                    data de solicitação de resgate, com taxa de 3% sobre o valor
                    que será resgatado.
                  </td>
                </tr>
                <tr>
                  <td>Cartão de crédito</td>
                  <td>
                    Saldo mínimo de R$ 200,00 em até 7 dias úteis a partir da
                    data de solicitação de resgate, com taxa de 5% sobre o valor
                    que será resgatado.
                  </td>
                </tr>
              </tbody>
            </table>
          </span>
          <h5>Vigência do Programa de Relacionamento "{stationName}"</h5>
          <p>
            O Programa de Relacionamento {stationName} será mantido por prazo
            indeterminado, e poderá ser suspendido ou cancelado a qualquer
            momento, sem necessidade de notificação prévia. O participante terá
            prazo de 30 (trinta) dias contados da data de cancelamento do
            programa para solicitar o resgate do saldo de sua carteira. Após
            esse prazo os participantes não poderão mais adquirir o direito de
            resgate do seu saldo residual, e o "{stationName}" não poderá ser
            responsabilizado por nenhum valor de reembolso indicado na conta dos
            participantes, cujo resgate não houver sido solicitado dentro do
            prazo
          </p>
          <h5>Ambiente digital do Programa: Portal virtual e aplicativo</h5>
          <p>
            O ambiente digital do {stationName}, a saber, portal virtual
            (Portal) e aplicativo ({siteAddress}) utilizam a tecnologia digital
            Rilato.app desenvolvida e mantida por Sumday Serviços em Software
            pessoa jurídica de direito privado, inscrita no CNPJ sob o
            nº 30.246.347/0001-00, sediada na Quadra SEPS 707/907 Conjunto E 10Sala 214 -Parte I-12, Bairro Asa Sul,
            Brasília, DF, CEP: 70390-078.
          </p>
          <h5>Quais são os direitos da Sumday Serviços em Software</h5>
          <p style={{ marginBottom: 16 }}>
            Todos os direitos de propriedade intelectual relativos ao ambiente
            digital do programa de relacionamento {stationName}, bem como todas
            as suas funcionalidades, são de titularidade exclusiva da Sumday Serviços em Software,
            inclusive no que diz respeito aos seus textos,
            imagens, layouts, software, códigos, gráficos, artigos, fotografias
            e demais conteúdos produzidos pela Sumday Serviços em Software. É proibido
            usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir,
            distribuir, executar, fazer o upload, exibir, licenciar, vender ou
            explorar e fazer engenharia reversa, para qualquer finalidade, sem o
            consentimento prévio e expresso da Sumday Serviços em Software. Todo e qualquer
            uso não autorizado de qualquer elemento vinculado ao {stationName}{' '}
            será considerado violação dos direitos autorais e da propriedade
            intelectual da Sumday Serviços em Software. À Sumday Serviços em Software reserva-se o
            direito de, a seu critério e a qualquer tempo:
          </p>
          <ol>
            <li>
              alterar ou remover funcionalidades do programa de relacionamento
              que não estejam alinhadas com seus interesses, bem como de
              adicionar novas que tragam benefícios à utilização do programa de
              relacionamento, sem comunicação prévia ao usuário e sem que lhe
              seja devida qualquer indenização;
            </li>
            <li>
              descontinuar de forma definitiva ou temporária os serviços
              disponibilizados por meio do aplicativo, sem qualquer comunicação
              prévia ao Usuário e sem que lhe seja devida qualquer indenização.
            </li>
            <li>
              suspender ou cancelar o cadastro dos usuários, bem como qualquer
              acesso e uso do programa de relacionamento, em caso de suspeita de
              fraude, obtenção de benefício ou vantagem de forma ilícita, ou
              pelo não cumprimento de quaisquer condições previstas neste Termo
              de Uso ou na legislação aplicável. Nesses casos, não será devida
              qualquer indenização ao participante e a Sumday Serviços em Software poderá
              promover a competente ação de regresso, caso julgue necessário ou
              oportuno, bem como tomar quaisquer outras medidas necessárias para
              perseguir e resguardar seus interesses.
            </li>
          </ol>
          <h5>Limitações de responsabilidade Sumday Serviços em Software</h5>
          <p>
            A RESPONSABILIDADE PELO CONTEÚDO DAS INFORMAÇÕES PESSOAIS É
            EXCLUSIVA DO PARTICIPANTE. O PARTICIPANTE ENTENDE QUE A UTILIZAÇÃO
            DO PROGRAMA DE RELACIONAMENTO É VOLTADA APENAS PARA FINS LÍCITOS. À
            SUMDAY SERVIÇOS EM SOFTWARE RESERVA-SE O DIREITO DE, IMEDIATAMENTE, INTERROMPER
            O ACESSO DO PARTICIPANTE AO PROGRAMA DE RELACIONAMENTO CASO
            IDENTIFIQUE A FALSIDADE NO CONTEÚDO DAS INFORMAÇÕES PESSOAIS, BEM
            COMO A MÁ UTILIZAÇÃO OU USO INADEQUADO DO PROGRAMA DE
            RELACIONAMENTO.
          </p>
          <p>
            Cabe única e exclusivamente ao usuário manter seu sistema protegido
            contra vírus e outros malwares. Dessa forma, a Sumday Serviços em Software não
            se responsabiliza por qualquer dano causado por vírus e malwares em
            decorrência de acesso, utilização ou navegação no site na internet
            ou como consequência da transferência de dados, arquivos, imagens,
            textos ou áudio contidos no mesmo. É de responsabilidade do usuário
            a certificação de acessar sua conta em redes seguras.
          </p>
        </div>
      );
    } else return null;
  }
}
