import React from 'react';
import {
  // Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Carrousel from 'common/components/carousel/carousel';
// import Carousel from 'react-material-ui-carousel';
import { BASE_S3, COMPANY_COLORS } from '../../../../../config/consts';
import { DefaultCard } from '../../../../../common/components/cards/DefaultCard';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 48,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
}));

function RenderActivities(props) {
  const { categories, history, selectActivity } = props;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const classes = useStyles({ xs, sm });

  return categories.listCategories.content.length > 0 ? (
    <>
      {categories?.listCategories?.content?.map((category, index) => (
        <div key={index} className={classes.root}>
          <Carrousel
            categoryName={category.name}
            indexStart={0}
            onClickCategory={() =>
              history.push(`/homepage/categoria/${category.id}`)
            }
            itemsDisplay={xs ? 2 : 4}
            color={COMPANY_COLORS.general.general_color}
          >
            {category.category_actions
              .sort((a, b) => a.action?.priority - b.action?.priority)
              .map((category_action, index) => (
                <div key={index}>
                  <DefaultCard
                    onClick={
                      category_action.action.type_id === 13
                        ? () =>
                            (window.location.href =
                              category_action.action.redirect_links[0].url)
                        : () => selectActivity(category_action.action.id)
                    }
                    imgSource={`${BASE_S3}/${category_action.action.image}`}
                    actionName={category_action.action.name}
                  />
                </div>
              ))}
          </Carrousel>
        </div>
      ))}
    </>
  ) : null;
}

RenderActivities.propTypes = {
  categories: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.object.isRequired,
  selectActivity: PropTypes.func.isRequired,
};

export default RenderActivities;
