import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import PropTypes from 'prop-types';
import React from 'react';
import {
  BASE_S3,
  COMPANY_ENTERPRISE_PLAN_BENEFITS,
  COMPANY_STATION_ID,
  FANPROJECTS_STATION_ID,
} from '../../../config/consts';
import PlansOptionsCard from '../../user/pages/homepage/plans/plansOptionsCard';

const useStyles = makeStyles({
  plansCardWidthController: {
    maxWidth: '410px',
    minWidth: '376px',
  },
  planSectionBackground: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: `center`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '4.5rem 0 5.5rem 0',
  },
});

function Plans(props) {
  const { item, website, history } = props;
  const classes = useStyles();

  return (
    <Box
      className={classes.planSectionBackground}
      style={{
        background:
          item.background_type === 'image'
            ? `url(${BASE_S3}/${item.background_image})`
            : item.background_type === 'color'
            ? item.background_color
            : '#fff',
      }}
    >
      <Grid
        item
        id="plans"
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
      >
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={10}
          style={{
            marginBottom: '4rem',
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            align="center"
            gutterBottom={true}
            style={{ fontWeight: '700' }}
          >
            {item.title || null}
          </Typography>
          <Typography variant="h5" align="center">
            {item.subtitle ||
              'Transforme seus seguidores em fãs e monetize sua paixão.'}
          </Typography>
        </Grid>
        <Grid
          item
          container
          spacing={3}
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          {website.stationData.plans.map((item, index) => (
            <Grid
              item
              xs
              md={4}
              key={item.id}
              className={classes.plansCardWidthController}
            >
              <PlansOptionsCard
                name={item.name}
                amount={item.amount.replace('.', ',')}
                benefits={item.benefits}
                description={item.description}
                onClick={() => history.push(`/cadastrar`)}
                buttonLabelStyle={{ textAlign: 'center' }}
                buttonLabel={item.purchase_text}
                days={item.days}
                is_highlighted={item.is_highlighted}
                onDetailClick={() => history.push(`/planos/${item.id}`)}
              />
            </Grid>
          ))}
          {COMPANY_STATION_ID === FANPROJECTS_STATION_ID ? (
            <GeneralGrid
              item
              xs
              md={4}
              className={classes.plansCardWidthController}
            >
              <PlansOptionsCard
                enterprisePlan={true}
                name={'Enterprise'}
                description={
                  'Sob medida para quem já lida com seus públicos de forma estruturada e quer transformar esse relacionamento em receita'
                }
                benefits={COMPANY_ENTERPRISE_PLAN_BENEFITS}
                onClick={() => history.push('/suporte-enterprise')}
                buttonStyle={{ backgroundColor: 'black' }}
                buttonLabelStyle={{ textAlign: 'center' }}
                buttonLabel={'Entre em contato'}
              />
            </GeneralGrid>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}

Plans.propTypes = {
  item: PropTypes.object.isRequired,
  website: PropTypes.object.isRequired,
};

export default Plans;
