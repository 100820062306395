import type from './types';

const INITIAL_STATE = {
  loading: true,
  list: [],
};


/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.LIST_FETCHED:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case type.LIST_LOAD:
      return { ...state, loading: action.payload };
    default:
      return state;
  }

};
