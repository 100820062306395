import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Health from './health';
import DentalAppointmentsForm from './dentalAppointmentsForm';
import PsychologistAppointmentsForm from './psychologistAppointmentsForm';
import NutritionistAppointmentsForm from './nutritionistAppointmentsForm'

export default (
  <Route path="/homepage/saude">
    <Switch>
      <Route exact path="/homepage/saude" component={Health} />

      <Route
        path="/homepage/saude/agendamento-odontologia"
        component={DentalAppointmentsForm}
      />
      <Route
        path="/homepage/saude/agendamento-psicologia"
        component={PsychologistAppointmentsForm}
      />
      <Route
        path="/homepage/saude/agendamento-nutricionista"
        component={NutritionistAppointmentsForm}
      />
    </Switch>
  </Route>
);
