import React, { useState, useEffect } from 'react';
import { isAndroid, isMobile } from 'react-device-detect';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { COMPANY_STATION_ID } from 'config/consts';

const appLinks = {
  1: 'intent://#Intent;scheme=programamais;package=com.fermento.programamais;end',
  174: 'intent://#Intent;scheme=clubs;package=br.com.sumdaysoftware.clubs;end',
  178: 'intent://#Intent;scheme=sindjusclub;package=br.com.sumdaysoftware.sindjusclub;end',
  148: 'intent://#Intent;scheme=astcu;package=com.fermento.astcu;end',
  179: 'intent://#Intent;scheme=duzentoseoitosul;package=br.com.sumdaysoftware.duzentoseoitosul;end',
  181: 'intent://#Intent;scheme=ejfrc;package=br.com.sumdaysoftware.ejfrc;end',
};

const downloadLinks = {
  1: 'https://play.google.com/store/apps/details?id=com.fermento.programamais',
  174: 'https://play.google.com/store/apps/details?id=br.com.sumdaysoftware.clubs',
  178: 'https://play.google.com/store/apps/details?id=br.com.sumdaysoftware.sindjusclub',
  148: 'https://play.google.com/store/apps/details?id=com.fermento.astcu',
  179: 'https://play.google.com/store/apps/details?id=br.com.sumdaysoftware.duzentoseoitosul',
  181: 'https://play.google.com/store/apps/details?id=br.com.sumdaysoftware.ejfrc',
};

const DeviceDetector = () => {
  const baseAppLink = appLinks[COMPANY_STATION_ID];
  const downloadLink = downloadLinks[COMPANY_STATION_ID];

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isMobile && isAndroid) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenApp = () => {
    const currentPath = window.location.hash.substring(1);
    const appLink = `${baseAppLink}${currentPath}`;

    window.location.href = appLink;

    const startTime = Date.now();
    const timer = setTimeout(() => {
      if (Date.now() - startTime < 1500) {
        window.location.href = downloadLink;
      }
      setOpen(false);
    }, 2000);

    return () => clearTimeout(timer);
  };

  const handleDownloadApp = () => {
    window.location.href = downloadLink;
    setOpen(false);
  };

  const handleStayOnWeb = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="dialog-title">Deseja abrir o app?</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="dialog-description"
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
        >
          Você pode abrir o app, baixar caso ainda não tenha ou{' '}
          <span
            onClick={handleStayOnWeb}
            style={{
              color: '#38817f',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            role="button"
            tabIndex={0}
            aria-label="Permanecer no navegador"
          >
            permanecer no navegador.
          </span>
          .
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOpenApp} color="primary">
          Abrir o App
        </Button>
        <Button onClick={handleDownloadApp} style={{ color: '#38817f' }}>
          Baixar o App
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceDetector;
