/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getActionById,
  setDetail,
  saveCheckVideo,
  updateCheckVideo,
  videoPlaylistParticipation,
  videoYoutubeParticipation,
} from '../activitiesActions';
import { CustomPlaylistPlayer } from './videoPlaylistComponents';
import { BASE_S3 } from 'config/consts';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';

const backgroundColorStyle = { background: 'transparent' };
const messageMargins = { margin: '32 0' };

function ActivityVideoPlaylist(props) {
  const [updatedData, setUpdatedData] = useState([]);
  const [hasYoutubeVideo, setHasYoutubeVideo] = useState(false);

  //regular participation
  useEffect(() => {
    if (!hasYoutubeVideo) {
      const filteredViewed = updatedData.filter(
        (item) => item.has_viewed === true
      );

      if (
        filteredViewed.length > 0 &&
        filteredViewed.length === updatedData.length
      ) {
        props.videoPlaylistParticipation({
          action_id: props.activities.detail.id,
        });
      }
    }
  }, [updatedData]);

  //youtube participation
  useEffect(() => {
    if (hasYoutubeVideo) {
      props.videoYoutubeParticipation({
        action_id: props.activities?.detail?.id,
      });
    }
  }, [hasYoutubeVideo]);

  const actionId = props.activities.detail.id;

  const findViewedOnce = (array, actionId, videoId) => {
    const video = array.find(
      (item) => item.action_id === actionId && item.video_id === videoId
    );
    if (video) return video.viewed_once;
    return false;
  };

  const findViewed = (array, actionId, videoId) => {
    const video = array.find(
      (item) => item.action_id === actionId && item.video_id === videoId
    );
    if (video) return video.has_viewed;
    return false;
  };

  const hasYoutubeVideos = (array) => {
    const arrayCustom = array.filter((item) => item.video['custom'] === true);
    if (arrayCustom.length > 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setHasYoutubeVideo(hasYoutubeVideos(props.activities.detail.action_videos));
  }, [props.activities.detail]);

  const data = props.activities.detail.action_videos.map((item, index) => {
    return {
      id: item.video_id,
      url: item.video['url'],
      custom: item.video['custom'],
      name: item.name,
      description: item.description,
      viewed_once:
        props.activities.detail.user_videos.length > 0
          ? findViewedOnce(
              props.activities.detail.user_videos,
              actionId,
              item.video_id
            )
          : false,
      has_viewed:
        props.activities.detail.user_videos.length > 0
          ? findViewed(
              props.activities.detail.user_videos,
              actionId,
              item.video_id
            )
          : false,
      required: item.required,
    };
  });

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent="center"
      alignItems="center"
      component="main"
      style={backgroundColorStyle}
    >
      <Grid item container xs={12} component="section">
        {props.activities.detail.participationStart &&
        props.activities.detail.participationEnd ? (
          moment().isBetween(
            props.activities.detail.participationStart,
            props.activities.detail.participationEnd
          ) ? (
            props.activities.detail.action_videos.length > 0 ? (
              <CustomPlaylistPlayer
                actionId={props.activities.detail.id}
                thumbnail={`${BASE_S3}/${props.activities.detail.image}`}
                hasYoutubeVideo={hasYoutubeVideo}
                setUpdatedData={setUpdatedData}
                data={data}
                handleCommentButtonClick={props.handleCommentButtonClick}
                {...props}
              />
            ) : null
          ) : (
            <Typography variant="body1" style={messageMargins}>
              Você pode verificar os vídeos desta atividade entre os dias{' '}
              {moment(props.activities.detail.participationStart).format(
                'DD/MM/YYYY'
              )}{' '}
              às{' '}
              {moment(props.activities.detail.participationStart).format(
                'HH:mm'
              )}{' '}
              e{' '}
              {moment(props.activities.detail.participationEnd).format(
                'DD/MM/YYYY'
              )}{' '}
              às{' '}
              {moment(props.activities.detail.participationEnd).format('HH:mm')}
              .
            </Typography>
          )
        ) : (
          <Typography variant="body1" style={messageMargins}>
            Houve um problema com a data de publicação desta atividade.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activities: state.activities,
    website: state.website,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getActionById,
      setDetail,
      saveCheckVideo,
      updateCheckVideo,
      videoPlaylistParticipation,
      videoYoutubeParticipation,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityVideoPlaylist);
