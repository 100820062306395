import { Box, Grid, Typography } from '@material-ui/core';
import { FullButton } from 'common/components/buttons/fullButton';
import { COMPANY_COLORS } from 'config/consts';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';

const QRCode = require('qrcode.react');

function calculateTimeLeft(date = new Date()) {
  const difference = date - new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  } else {
    timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  return { timeLeft, difference };
}

export default function AsyncConfirmation({
  setPixConfirmation,
  qrCode,
  expirationDate,
  getProductOrder,
  productOrderId,
  setPurchaseStep,
  amount,
}) {
  const [wasCopied, setWasCopied] = useState(false);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft().timeLeft);
  const [difference, setDifference] = useState(calculateTimeLeft().difference);

  useEffect(() => {
    const timer = setTimeout(() => {
      const timeLeftObject = calculateTimeLeft(expirationDate);
      setTimeLeft(timeLeftObject.timeLeft);
      setDifference(timeLeftObject.difference);
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (calculateTimeLeft(expirationDate).difference > 0) {
    } else {
      toastr.error(
        'Tempo expirado',
        'O tempo máximo para pagamento do PIX expirou. Para comprar novamente, recomece o processo.'
      );
      setPurchaseStep(0);
    }
  }, [difference, expirationDate, setPixConfirmation, setPurchaseStep]);

  function verifyPIXPayment() {
    function callback(productOrder) {
      if (productOrder.status === 'Pagamento confirmado') {
        setPixConfirmation(true);
      } else {
        toastr.error(
          'Pagamento não confirmado',
          'Seu pagamento ainda não foi confirmado pelo nosso sistema de pagamentos. Se já tiver pago, o processo pode demorar alguns segundos.'
        );
      }
    }
    getProductOrder(productOrderId, callback);
  }

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography variant="h4">
            Pague R$ {parseFloat(amount).toFixed(2).replace('.', ',')} via PIX
            para garantir a compra
          </Typography>
          <Typography
            variant="body1"
            style={{ marginTop: 24 }}
          >
            Copie o código para pagar ou escaneie o QR Code abaixo
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
            <Typography variant="body1" style={{ textAlign: 'center', marginTop: 16, marginBottom: 8 }}>
              QR Code do PIX
            </Typography>
            <QRCode value={qrCode} />
          </Box>

          <Box
            display="flex"
            style={{
              backgroundColor: '#f6f6f6',
              borderRadius: 6,
              padding: '10px 15px 10px 0',
            }}
            mt={3}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <p
              className="h5-bold"
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                marginLeft: 12,
              }}
            >
              {qrCode}
            </p>
            <button
              onClick={() => {
                setWasCopied(true);
                navigator.clipboard.writeText(qrCode);
              }}
              style={{
                backgroundColor: wasCopied ? '#f6f6f6' : COMPANY_COLORS.primary,
                color: wasCopied ? '#aaa' : '#000',
                padding: '10px 20px',
              }}
            >
              {wasCopied ? 'Copiado' : 'Copiar código'}
            </button>
          </Box>
          <Typography
            variant="body1"
            className="h5-bold"
            style={{ marginTop: 24, textAlign: 'left', width: '100%' }}
          >
            Instruções de pagamento
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            mt={2}
            width="100%"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={40}
              width={40}
              borderRadius={20}
              style={{ backgroundColor: COMPANY_COLORS.primary }}
            >
              1
            </Box>
            <Typography variant="body1" style={{ marginLeft: 8 }}>
              Acesse o aplicativo do seu banco ou app de pagamentos
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            mt={1}
            width="100%"
            alignItems="center"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                width: 40,
                height: 40,
                borderRadius: 20,
                backgroundColor: COMPANY_COLORS.primary,
              }}
            >
              2
            </Box>
            <Typography variant="body1" style={{ marginLeft: 8 }}>Escolha pagar via PIX</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            mt={1}
            width="100%"
            alignItems="center"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                width: 40,
                height: 40,
                borderRadius: 20,
                backgroundColor: COMPANY_COLORS.primary,
              }}
            >
              3
            </Box>
            <Typography variant="body1" style={{ marginLeft: 8 }}>
              Escolha a opção com QR Code ou "PIX Copia e Cola"
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            mt={1}
            width="100%"
            alignItems="center"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                width: 40,
                height: 40,
                borderRadius: 20,
                backgroundColor: COMPANY_COLORS.primary,
              }}
            >
              4
            </Box>
            <Typography variant="body1" style={{ marginLeft: 8 }}>
              Escaneie o QR Code ou cole o código para realizar a transação
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            mt={1}
            width="100%"
            alignItems="center"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                width: 40,
                height: 40,
                borderRadius: 20,
                backgroundColor: COMPANY_COLORS.primary,
              }}
            >
              5
            </Box>
            <Typography variant="body1" style={{ marginLeft: 8 }}>
              Após a confirmação, volte aqui e clique em verificar
            </Typography>
          </Box>

          <Box mt={3}>
            <FullButton
              classNameProp="py-3 px-4"
              onClick={verifyPIXPayment}
              type="button"
            >
              Verificar pagamento
            </FullButton>
          </Box>

          <Typography variant="body1" style={{ textAlign: 'center', marginTop: 8 }}>
            Você ainda tem{' '}
            <span style={{ fontWeight: '700' }}>
              {timeLeft.minutes}:{timeLeft.seconds}s
            </span>{' '}
            para realizar o pagamento deste PIX.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
