import React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import {
  BASE_S3,
  BRB_FLA_STATION_ID,
  COMPANY_COLORS,
  COMPANY_STATION_ID,
} from '../../../config/consts';
import { makeStyles } from '@material-ui/styles';
import { formatCNPJ } from '../../../helpers/dataTransformers';
import { truncateString } from '../../../helpers/utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  footerLogo: {
    maxWidth: '90%',
    height: 'auto',
    maxHeight: 100,
  },
});

function Footer(props) {
  const { setOpenModal, setOpenModalSec, website, history } = props;
  const { footer } = COMPANY_COLORS.website;
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const classes = useStyles();

  return (
    <>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        style={{
          backgroundColor: footer.background,
          minHeight: xs || sm ? '250px' : '200px',
        }}
      >
        <Grid
          item
          container
          justifyContent="space-between"
          xs={11}
          sm={10}
          md={12}
          style={{
            maxWidth: '1250px',
            padding: xs || sm ? '.8rem 0' : '',
          }}
        >
          <Grid
            item
            container
            justifyContent={xs || sm ? 'center' : 'flex-start'}
            alignItems="center"
            xs={12}
            sm={2}
            style={{
              margin: xs || sm ? '.3rem 0' : null,
            }}
          >
            <img
              className={classes.footerLogo}
              src={`${BASE_S3}/${footer.company_logo}`}
              alt="LOGO-EMPRESA"
            />
          </Grid>
          <Grid
            item
            container
            justifyContent={xs || sm ? 'center' : 'flex-end'}
            alignItems="center"
            xs={12}
            sm={8}
          >
            <Grid
              item
              container
              direction="column"
              justifyContent="space-between"
              alignItems={xs || sm ? 'center' : 'flex-start'}
              xs={12}
              sm={5}
              style={{
                minHeight: xs || sm ? '100px' : '100%',
                padding: xs || sm ? '.2rem 0' : '',
                minWidth: '213px',
              }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  color: footer.text,
                  overflowWrap: 'break-word',
                  overflow: 'auto',
                }}
              >
                {website.stationData
                  ? website.stationData.establishment
                    ? `Cnpj: ${formatCNPJ(
                        website.stationData.establishment.cnpj
                      )}`
                    : null
                  : null}
              </Typography>
              <Typography
                variant="subtitle1"
                style={{
                  color: footer.text,
                  overflowWrap: 'break-word',
                  overflow: 'auto',
                  maxWidth: '180px',
                  textAlign: xs || sm ? 'center' : null,
                }}
              >
                {website.stationData.address &&
                  truncateString(website.stationData.address, 45)}
              </Typography>
              {COMPANY_STATION_ID !== BRB_FLA_STATION_ID ? (
                <Typography
                  variant="subtitle1"
                  style={{
                    color: footer.text,
                    overflowWrap: 'break-word',
                    overflow: 'auto',
                  }}
                >
                  {website.stationData.contact_mail}
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  style={{
                    color: footer.text,
                    overflowWrap: 'break-word',
                    overflow: 'auto',
                  }}
                >
                  sac@melevanacaobrb.com.br
                </Typography>
              )}
            </Grid>
            <Grid
              item
              container
              direction="column"
              justifyContent={xs || sm ? 'flex-start' : 'flex-start'}
              alignItems={xs || sm ? 'center' : 'flex-start'}
              xs={12}
              sm={3}
              style={{
                minHeight: xs || sm ? '100px' : '100%',
                padding: xs || sm ? '.2rem 0' : '',
                minWidth: '162px',
              }}
            >
              <Typography
                variant="subtitle1"
                onClick={() => {
                  setOpenModal(true);
                }}
                style={{
                  cursor: 'pointer',
                  color: footer.text,
                  overflowWrap: 'break-word',
                  overflow: 'auto',
                }}
              >
                Política de privacidade
              </Typography>
              <Typography
                variant="subtitle1"
                onClick={() => {
                  setOpenModalSec(true);
                }}
                style={{
                  cursor: 'pointer',
                  color: footer.text,
                  overflowWrap: 'break-word',
                  overflow: 'auto',
                }}
              >
                Termos de uso
              </Typography>
              <Typography
                variant="subtitle1"
                onClick={() => history.push('/suporte')}
                style={{
                  cursor: 'pointer',
                  color: footer.text,
                  overflowWrap: 'break-word',
                  overflow: 'auto',
                }}
              >
                Fale Conosco
              </Typography>
              {COMPANY_STATION_ID === BRB_FLA_STATION_ID && (
                <a
                  target="_blank"
                  href="https://s3.amazonaws.com/rilato.app.files/files/regulamento.pdf"
                  rel="noopener noreferrer"
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: footer.text,
                      overflowWrap: 'break-word',
                      overflow: 'auto',
                    }}
                  >
                    Regulamento{' '}
                  </Typography>
                </a>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

Footer.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  setOpenModalSec: PropTypes.func.isRequired,
  website: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Footer;
