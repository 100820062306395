import { Grid } from '@material-ui/core';
import React from 'react';
import PoliticalContactsCard from './politicalContactsCard';

const contacts = [
  {
    name: 'Carolina Augusta',
    tel: '(61) 3214-7300',
    email: 'carol@sindilegis.org.br',
    image:
      'https://s3.amazonaws.com/rilato.app.files/files/system/sindilegis/carolina.png',
    role: '',
  },
  {
    name: 'Luiz Barcelos',
    email: 'luiz@sindilegis.org.br',
    image:
      'https://s3.amazonaws.com/rilato.app.files/files/system/sindilegis/luiz.png',
    role: '',
  },
  {
    name: 'Luisa Dantas',
    email: 'luisa@sindilegis.org.br',
    image:
      'https://s3.amazonaws.com/rilato.app.files/files/system/sindilegis/luisa.png',
    role: '',
  },
  {
    name: 'Alessandra Galvão',
    email: 'alessandra@sindilegis.org.br',
    image:
      'https://s3.amazonaws.com/rilato.app.files/files/system/sindilegis/alessandra.png',
    role: '',
  },
  {
    name: 'Raissa Povoa',
    email: 'raissa@sindilegis.org.br',
    image:
      'https://s3.amazonaws.com/rilato.app.files/files/system/sindilegis/raissa.png',
    role: '',
  },
];

export default function PoliticalContacts() {
  return (
    <section id="political-contacts">
      <p className="body-title mt-4">
        Núcleo de articulação política do Sindilegis
      </p>
      <p className="h7 mt-5">Telefone: (61) 3214-7300</p>
      <p className="h7">Email: articulacao@sindilegis.org.br</p>
      <Grid container spacing={3} style={{ marginTop: 48 }}>
        {contacts.map(({ name, cel, tel, email, image, role }) => (
          <Grid item xs={4}>
            <PoliticalContactsCard
              name={name}
              cel={cel}
              tel={tel}
              email={email}
              image={image}
              role={role}
            />
          </Grid>
        ))}
      </Grid>
    </section>
  );
}
