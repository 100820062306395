import {
  Avatar,
  Backdrop,
  Fade,
  Grid,
  makeStyles,
  Modal,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  BASE_S3,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  GENERAL_TEXT_COLOR,
} from '../../../../../../config/consts';
import {
  CloseModalButton,
  CommentBody,
  ConfirmationContent,
  DeleteCommentButton,
  ModalConfirmationButton,
} from '../styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { connect } from 'react-redux';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import { bindActionCreators } from 'redux';
import { sendCommentDeletion } from '../../activitiesActions';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  small: {
    width: '2.2em',
    height: '2.2em',
    margin: '1rem 0',
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  accordion: (props) => ({
    width: '100%',
    display: props.noneSubComments ? '' : 'none',
    border: 'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }),
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 'transparent',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '10px',
  },
}));

const SubComment = (props) => {
  const { subCommentData, auth, activity } = props;
  const classes = useStyles();
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false);

  function handleDeleteSubCommentClick() {
    props.sendCommentDeletion(subCommentData.id, activity.detail.id);
  }

  function handleConfirmationModalOpen() {
    setIsConfirmationModalVisible(true);
  }

  function handleConfirmationModalClose() {
    setIsConfirmationModalVisible(false);
  }

  return (
    <>
      <Grid item container xs={12} alignItems="center">
        <Grid
          item
          container
          xs={0}
          sm={1}
          justfy=""
          alignItems="flex-start"
          style={{ display: isMobile ? 'none' : '' }}
        >
          <SubdirectoryArrowRightIcon
            fontSize="medium"
            style={{ color: COMPANY_SIGNED_IN_BACKGROUND_COLOR }}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={11}
          justifyContent="center"
          style={{
            margin: '.5rem 0',
            padding: '.6rem 0',
          }}
        >
          {/* AVATAR */}
          <Grid
            item
            container
            xs={2}
            sm={1}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Avatar
              alt={subCommentData && subCommentData.user.name.split(' ')[0]}
              className={`${isMobile ? classes.small : classes.large}`}
              src={
                subCommentData && subCommentData.user.image
                  ? `${BASE_S3}/${subCommentData.user.image}`
                  : 'https://s3.amazonaws.com/rilato.app.files/files/default_profile.png'
              }
            />
          </Grid>
          {/* COMMENT BODY */}
          <Grid
            item
            container
            xs={10}
            sm={11}
            justifyContent="center"
            alignItems="flex-start"
            spacing={1}
            style={{
              paddingTop: isMobile ? '.8rem' : '.2rem',
              paddingLeft: '.7rem',
            }}
          >
            <Grid
              item
              container
              xs={12}
              justifyContent={props.lgScreen ? 'flex-start' : 'space-between'}
              alignItems="center"
            >
              {subCommentData && (
                <Typography
                  variant="body1"
                  component="span"
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '20px',
                    textAlign: 'right',
                    letterSpacing: '0.25px',
                    color: GENERAL_TEXT_COLOR,
                    paddingLeft: props.lgScreen ? '1rem' : '',
                  }}
                >
                  {moment(subCommentData.createdAt).format('DD/MM/YYYY')}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                item
                container
                xs={11}
                alignItems="center"
                component="span"
                style={{ maxWidth: '672px' }}
              >
                <CommentBody>
                  <Typography variant="body1" component="span">
                    {subCommentData.description}
                  </Typography>
                </CommentBody>
              </Grid>
              <Grid
                item
                container
                xs={1}
                justifyContent="flex-end"
                alignItems="center"
              >
                <DeleteCommentButton
                  authId={auth.user.id}
                  commentId={subCommentData.user_id}
                  onClick={() => handleConfirmationModalOpen()}
                >
                  <DeleteOutlineIcon
                    fontSize="small"
                    style={{ color: GENERAL_TEXT_COLOR }}
                  />
                </DeleteCommentButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isConfirmationModalVisible}
        onClose={handleConfirmationModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isConfirmationModalVisible}>
          <Grid
            item
            container
            xs={11}
            sm={4}
            justifyContent="center"
            alignItems="center"
            spacing={3}
            className={classes.paper}
          >
            <Grid
              item
              container
              xs={12}
              justifyContent="flex-end"
              alignItems="center"
              style={{ paddingRight: 0 }}
            >
              <CloseModalButton onClick={handleConfirmationModalClose}>
                <CloseIcon />
              </CloseModalButton>
            </Grid>
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <ConfirmationContent id="transition-modal-title">
                Deseja excluir seu comentário?
              </ConfirmationContent>
            </Grid>
            <Grid
              item
              container
              xs={12}
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <ModalConfirmationButton onClick={handleDeleteSubCommentClick}>
                Excluir
              </ModalConfirmationButton>
              <ModalConfirmationButton
                cancel={true}
                onClick={handleConfirmationModalClose}
              >
                Cancelar
              </ModalConfirmationButton>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activity: state.activities,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sendCommentDeletion,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubComment);
