/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import React, { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActivityLike from '../activitiesFeed/Likes/ActivityLike.jsx';
import { CommentButton } from '../activitiesFeed/styles';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import {
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  GENERAL_TEXT_COLOR,
  BUTTON_BACKGROUND_COLOR,
} from 'config/consts.js';

function mainStyles() {
  return {
    likesAndComments: {
      margin: '1rem 0',
    },
    likesAndCommentsBtns: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '1rem',
    },
    textColor: {
      color: GENERAL_TEXT_COLOR,
    },
    player: {
      width: '100%',
      height: isMobileOnly ? '11.5rem' : 600,
      margin: '1rem 0 2rem 0',
      backgroundColor: 'black',
    },
    videoInfo: {
      padding: '1rem',
    },
    videoTitle: {
      marginBottom: '1rem',
    },
    videoDescription: {},
    infos: {
      marginBottom: '2rem',
    },
    playlist: {
      padding: isMobileOnly ? '0 1rem' : '0 0 0 5rem',
    },
  };
}

function rowStyles() {
  return {
    row: {
      height: '5rem',
      padding: isMobileOnly ? 0 : 10,
      cursor: 'pointer',
    },
    lockIcon: {
      fontSize: 18,
    },
    checkbox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    playIcon: {
      color: BUTTON_BACKGROUND_COLOR,
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
    },
    videoIndexDiv: {
      width: '2rem',
      height: '100%',
      backgroundColor: BUTTON_BACKGROUND_COLOR,
      borderRadius: '50%',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    videoIndexTypography: {
      height: '100%',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
    },
    indexGrid: {
      height: '2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
}

const RowVideoInfo = ({
  video,
  currentIndex,
  length,
  setCurrentVideo,
  data,
  playing,
  currentVideoId,
  currentDuration,
  setLocalData,
  actionId,
  hasYoutubeVideo,
  ...props
}) => {
  const LIMIT = 30;
  const classes = makeStyles(rowStyles)();

  const handleChange = (index) => {
    setLocalData((prevState) => {
      const arrayData = [...prevState];
      if (!arrayData[index].viewed_once) {
        props.saveCheckVideo(video.id, {
          action_id: actionId,
        });
        arrayData[index].viewed_once = true;
        arrayData[index].has_viewed = true;

        return arrayData;
      }
      props.updateCheckVideo(video.id, {
        action_id: actionId,
        has_viewed: !arrayData[index].has_viewed,
      });
      arrayData[index].has_viewed = !arrayData[index].has_viewed;
      return arrayData;
    });
  };

  const handleSelectVideo = (index) => {
    // if (hasLock(index)) {
    //   return;
    // }
    setCurrentVideo(() => {
      const newCurrent = data[index];
      if (newCurrent) return newCurrent;
    });
  };

  const findItemIndex = (array, id) => {
    const index = array.findIndex((item) => item.id === id);
    if (index) return index;
    return null;
  };

  const hasLock = (index) => {
    const array = [...data];
    const filteredWithLock = array.filter(
      (video) =>
        video.required &&
        !video.viewed_once &&
        findItemIndex(array, video.id) < index
    );
    if (filteredWithLock.length > 0) return true;
    if (array[index].required && !array[index].viewed_once) return true;
    return false;
  };

  const borderBottom = {
    borderBottom:
      currentIndex === length - 1 ? null : `1px solid ${GENERAL_TEXT_COLOR}`,
  };

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.row}
      style={borderBottom}
      onClick={() => handleSelectVideo(currentIndex)}
    >
      {/* video index */}
      <Grid item xs={2} className={classes.indexGrid}>
        <div className={classes.videoIndexDiv}>
          <Typography variant="body1" className={classes.videoIndexTypography}>
            {currentIndex + 1}
          </Typography>
        </div>
      </Grid>

      {/* video title */}
      <Grid item xs={hasYoutubeVideo ? 8 : 6}>
        <Typography
          style={{
            verticalAlign: 'center',
          }}
        >
          {video.name.length <= LIMIT
            ? video.name
            : `${video.name.substring(0, LIMIT)}...`}
        </Typography>
      </Grid>

      {/* video progress */}
      <Grid
        item
        container
        xs={hasYoutubeVideo ? 2 : 4}
        justifyContent="flex-end"
        alignItems="center"
      >
        {/* video time */}
        <Grid item xs={4}>
          {currentVideoId === video.id && currentDuration ? (
            <Typography variant="body2">{currentDuration}</Typography>
          ) : null}
        </Grid>

        {/* play pause icon */}
        <Grid item xs={2}>
          {currentVideoId === video.id && currentDuration ? (
            playing ? (
              <PauseIcon className={classes.playIcon} />
            ) : (
              <PlayArrowIcon className={classes.playIcon} />
            )
          ) : null}
        </Grid>

        {/* checkbox */}
        <Grid
          item
          xs={hasYoutubeVideo ? false : 3}
          className={classes.checkbox}
        >
          {hasYoutubeVideo ? null : hasLock(currentIndex) &&
            currentVideoId !== video.id ? (
            <LockOutlinedIcon className={classes.lockIcon} />
          ) : (
            <Checkbox
              style={{ transform: 'scale(0.8)' }}
              color="primary"
              checked={video.has_viewed}
              onChange={() => handleChange(currentIndex)}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const BoxVideoData = ({
  data,
  setCurrentVideo,
  currentVideoId,
  playing,
  currentDuration,
  setLocalData,
  actionId,
  hasYoutubeVideo,
  ...props
}) => {
  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      xs={12}
    >
      {data.map((video, index) => {
        return (
          <RowVideoInfo
            data={data}
            key={index.toString()}
            currentIndex={index}
            video={video}
            length={data.length}
            setCurrentVideo={setCurrentVideo}
            playing={playing}
            currentVideoId={currentVideoId}
            currentDuration={currentDuration}
            setLocalData={setLocalData}
            hasYoutubeVideo={hasYoutubeVideo}
            actionId={actionId}
            {...props}
          />
        );
      })}
    </Grid>
  );
};

export const CustomPlaylistPlayer = ({
  data,
  thumbnail,
  actionId,
  setUpdatedData,
  hasYoutubeVideo,
  ...props
}) => {
  const [localData, setLocalData] = useState(data);
  const [currentVideo, setCurrentVideo] = useState(localData[0]);
  const [playing, setPlaying] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);
  const [firstPlay, setFirstPlay] = useState(true);
  const [currentDuration, setCurrentDuration] = useState(null);
  const [finished, setFinished] = useState(false);
  const classes = makeStyles(mainStyles)();

  useEffect(() => {
    setUpdatedData(localData);
  }, [localData]);

  const convertSeconds = (giveSeconds) => {
    const hours = Math.floor(giveSeconds / 3600);
    const minutes = Math.floor((giveSeconds - hours * 3600) / 60);
    const seconds = giveSeconds - hours * 3600 - minutes * 60;

    const timeString =
      minutes.toString().padStart(2, '0') +
      ':' +
      seconds.toFixed().toString().padStart(2, '0');
    return timeString;
  };

  const currentVideoId = currentVideo.id;
  useEffect(() => {
    const goToNextVideo = () => {
      const index = localData.findIndex((item) => item.id === currentVideoId);
      const nextVideo = localData[index + 1];
      if (nextVideo) setCurrentVideo(nextVideo);
    };
    if (finished) {
      goToNextVideo();
    }
  }, [localData, finished, currentVideoId]);

  useEffect(() => {
    setFinished(false);
    setPlaying(false);
    setCurrentDuration(null);
  }, [currentVideo]);

  useEffect(() => {
    const showThumbnail = (id) => {
      const array = [...localData];
      const index = array.findIndex((item) => item.id === id);
      if (index > 0 || !firstPlay) {
        return false;
      }
      return true;
    };
    setAutoPlay(!showThumbnail(currentVideoId));
  }, [currentVideoId, localData]);

  useEffect(() => {
    const handleOnFinishVideo = (id) => {
      const array = [...localData];

      setLocalData(array);
    };
    if (finished) handleOnFinishVideo(currentVideo.id);
  }, [finished, currentVideo]);

  const handleVideoFinished = () => {
    if (hasYoutubeVideo) return;
    setFinished(true);
  };

  const handlePlay = () => {
    setFirstPlay(false);
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleOnDuration = (duration) => {
    const time = convertSeconds(duration);
    setCurrentDuration(time);
  };

  const handleCompleteProgress = ({ played }) => {
    if (hasYoutubeVideo) return;
    const array = [...localData];
    if (played >= 0.8) {
      const index = array.findIndex((item) => item.id === currentVideo.id);
      if (!array[index].viewed_once) {
        props.saveCheckVideo(currentVideo.id, {
          action_id: actionId,
        });
        array[index].viewed_once = true;
        array[index].has_viewed = true;
        return setLocalData(array);
      }
      if (array[index].viewed_once && !array[index].has_viewed) {
        props.updateCheckVideo(currentVideo.id, {
          action_id: actionId,
          has_viewed: true,
        });
        array[index].has_viewed = true;
        return setLocalData(array);
      }
      if (array[index].viewed_once && array[index].has_viewed) {
        return;
      }
    }
  };

  return (
    <Grid item container xs={12} component="main" alignItems="center">
      {/* like and comment btns */}
      <Grid
        item
        container
        xs={12}
        justifyContent="flex-start"
        alignItems="center"
        className={classes.likesAndComments}
      >
        {props.activities.detail.comments_and_likes_enabled ? (
          <>
            <Box component="div" className={classes.likesAndCommentsBtns}>
              <ActivityLike />
            </Box>
            <Box component="div" className={classes.likesAndCommentsBtns}>
              <CommentButton
                className={classes.textColor}
                onClick={() => props.handleCommentButtonClick()}
              >
                <ChatBubbleOutlineOutlinedIcon />
                {props.activities.allDataComments === undefined
                  ? '...'
                  : props.activities.allDataComments.length}
              </CommentButton>
            </Box>
          </>
        ) : null}
      </Grid>
      {/* React player */}
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        component="section"
        id="react-player"
        className={classes.player}
      >
        <ReactPlayer
          height={'100%'}
          width={'100%'}
          playing={true}
          light={autoPlay ? '' : thumbnail}
          controls
          config={{
            file: { attributes: { controlsList: 'nodownload' } },
          }}
          key={currentVideo.url}
          url={currentVideo.url}
          onEnded={handleVideoFinished}
          onProgress={handleCompleteProgress}
          onPlay={handlePlay}
          onPause={handlePause}
          onDuration={handleOnDuration}
        />
      </Grid>
      {/* description & playlist */}
      <Grid item container xs={12} component="div" className={classes.infos}>
        {/* current video info */}
        <Grid
          item
          container
          alignItems="flex-start"
          xs={12}
          md={7}
          component="section"
        >
          <Grid
            item
            container
            alignItems="flex-start"
            xs={12}
            className={classes.videoInfo}
          >
            <Grid item xs={12} className={classes.videoTitle}>
              <Typography variant="h4">{currentVideo.name}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.videoDescription}>
              <Typography variant="body1">
                {currentVideo.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* playlist */}
        <Grid
          item
          container
          direction="column"
          xs={12}
          md={5}
          component="section"
          className={classes.playlist}
        >
          <BoxVideoData
            data={localData}
            currentVideoId={currentVideo.id}
            setCurrentVideo={setCurrentVideo}
            setLocalData={setLocalData}
            playing={playing}
            currentDuration={currentDuration}
            actionId={actionId}
            hasYoutubeVideo={hasYoutubeVideo}
            {...props}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} component="section" id="posts"></Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    activities: state.activities,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomPlaylistPlayer);
