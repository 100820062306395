export const getVouchersUrl = (action, code) => {
  if (action.station_id && action.partner_id) {
    if (process.env.REACT_APP_API_KEY === 'production') {
      return `https://parceiro.rilato.com.br/#/qr-code-validator/voucher/${code}`;
    } else if (process.env.REACT_APP_API_KEY === 'test') {
      return `https://sandbox-parceiro.rilato.com.br/#/qr-code-validator/voucher/${code}`;
    }
    return `https://localhost:3000/#/qr-code-validator/voucher/${code}`;
  }
  if (action.station_id && !action.partner_id) {
    if (process.env.REACT_APP_API_KEY === 'production') {
      return `https://app.rilato.com.br/#/qr-code-validator/voucher/${code}`;
    } else if (process.env.REACT_APP_API_KEY === 'test') {
      return `https://sandbox-gestor.rilato.com.br/#/qr-code-validator/voucher/${code}`;
    }
    return `https://localhost:3000/#/qr-code-validator/voucher/${code}`;
  }
}
