import { Grid, Typography } from '@material-ui/core';
import { FullButton } from 'common/components/buttons/fullButton';
import moment from 'moment';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { Alert } from '@material-ui/lab';
import { FORM_RULES } from 'helpers/validations';
import ButtonPrimary from 'common/components/buttons/ButtonPrimary';
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  COMPANY_COLORS,
  COMPANY_SIGNED_IN_BACKGROUND_COLOR,
  SIGNED_OFF_NAV_TEXT_COLOR,
} from 'config/consts';
import ActivityAbstract from '../../components/ActivityAbstract';
import ItemCardMultiSelect from '../../components/ItemCard/multiSelect';
import ItemCardRadioSelect from '../../components/ItemCard/radioSelect';
import { addProductToCart } from '../../shoppingActions';
import { InputLabelOutlined } from 'common/components/fields/fields';
import { submitSurvey } from 'modules/user/pages/activities/activitiesActions';


let CartSurvey = function (props) {
  const [myProductId, setmyProductId] = useState(null);
  const [step, setStep] = useState(0);
  const {
    products,
    activity,
    handleSubmit,
    addProductToCart,
    history,
  } = props;

  const checkLimitPerUser = useMemo(() => {
    if (parseInt(activity.limitPerUser) <= activity.userActions.length) {
      return false;
    } else return true;
  }, [activity.limitPerUser, activity.userActions.length]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const productsWithStock = useMemo(
    () =>
      products.filter((item) => item.single_product_orders.length < item.stock),
    [products]
  );

  const questions = useMemo(() => {
    return props.questions?.filter(it => it.type_id === 3)?.map((item, index) => {
      return (
        <div className="row justify-content-center mb-2" key={index}>
          <div className="col-12">
            <Field
              component={InputLabelOutlined}
              type="text"
              label={`Responda aqui`}
              step={step}
              multiline={true}
              limitChar={600}
              name={`cartSurveyAnswer-${item.id}`}
              validate={[/*FORM_RULES.required,*/ FORM_RULES.max(600)]}
              isLong
              longText={item.question}
            />
          </div>
        </div>
      );
    });
  }, [props.questions, step]);

  async function onSubmit(values) {
    const products = [];
    for (let product in values) {
      const productPrefix = 'product-';
      if (product?.includes(productPrefix)) {
        const id = product.split(productPrefix)[1];
        const quantity = values[product];
        products.push({ id, quantity });
      }
    }
    if (!_.isEmpty(products)) {
      onClickSaveCartSurveyForm(values)
    }
    addProductToCart(products, history);
  }

  const getProductId = (id) => {
    setmyProductId(id);
  };

  const onSubmitOnlyOneProduct = (values) => {
    const id = myProductId;
    const quantity = 1;
    onClickSaveCartSurveyForm(values)
    addProductToCart([{ id, quantity }], history);
  };

  function onClickSaveCartSurveyForm(values) {
    // console.log({values, step, questions})

    const { auth } = props;
    const newValues = { ...values };
    // adicionar estabelecimento
    newValues.author = 'user';
    newValues.user = auth.user.id;
    newValues.action = props.activities.detail.id;
    newValues.establishment = auth.user.establishment_participant.id;
    newValues.research = props.activities.detail.researches[0].id;
    let arr = [];

    for (let prop in newValues) {
      if (prop.indexOf('cartSurveyAnswer') > -1) {
        if (
          (!_.isArray(newValues[prop]) && prop.split('-').length < 3) ||
          _.isString(newValues[prop])
        ) {
          arr.push({
            answer: newValues[prop],
            question: prop.split('-')[1],
          });
        } else if (prop.split('-').length > 2) {
          arr.push({
            options: [newValues[prop]],
            question: prop.split('-')[1],
          });
        } else {
          arr.push({
            options: newValues[prop],
            question: prop.split('-')[1],
          });
        }
        delete newValues[`${prop}`];
      }
    }

    newValues.answers = arr;
    props.submitSurvey(newValues, props.history);
  }

  function onClickNextStep(values) {
    // console.log({values, step, questions, fn: 'onClickNextStep'})
    if (step < questions.length - 1) {
      return setStep((step) => step < questions.length - 1 && step + 1);
    }
  }

  return (
    <Grid item container justifyContent="center" alignItems="center" xs={12}>
      <Form
        role="form"
        noValidate
        onSubmit={
          activity.only_one_product
            ? handleSubmit((v) => onSubmitOnlyOneProduct(v))
            : handleSubmit((v) => onSubmit(v))
        }
        style={{
          minWidth: '100%',
        }}
      >
        <Grid container justifyContent="center" alignItems="center" xs={12}>
          <Grid item container justifyContent="center" alignItems="center" xs={12}>
            <ActivityAbstract />
          </Grid>
          {activity.participationStart && activity.participationEnd ? (
            moment().isBetween(
              activity.participationStart,
              activity.participationEnd
            ) ? (
              checkLimitPerUser ? (
                productsWithStock.length > 0 ? (
                  productsWithStock.map((product) => (
                    <Field
                      key={product.id}
                      name={`product-${product.id}`}
                      data={product}
                      callBack={getProductId}
                      component={
                        activity.only_one_product
                          ? ItemCardRadioSelect
                          : ItemCardMultiSelect
                      }
                    />
                  ))
                ) : (
                  <Typography variant="body1" style={{ marginTop: 10 }}>
                    O estoque desse(s) produto(s) está esgotado no momento.
                  </Typography>
                )
              ) : (
                  <Typography variant="body1" style={{ marginTop: 10 }}>
                  Olá, você já ultrapassou o limite de participações nessa
                  atividade!
                </Typography>
              )
            ) : (
              <>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  md={4}
                >
                  <Typography variant="body1" style={{ marginBottom: 32 }}>
                    Você pode verificar os produtos desta atividade entre os dias{' '}
                    {moment(activity.participationStart).format('DD/MM/YYYY')} às{' '}
                    {moment(activity.participationStart).format('HH:mm')} e{' '}
                    {moment(activity.participationEnd).format('DD/MM/YYYY')} às{' '}
                    {moment(activity.participationEnd).format('HH:mm')}.
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  xs={12}
                  md={4}
                >
                  <FullButton
                    classNameProp="py-3 px-4"
                    type="button"
                    onClick={() => history.push('/homepage')}
                    style={{
                      height: 52,
                      minWidth: '100%',
                    }}
                    isSecondary
                  >
                    Voltar
                  </FullButton>
                </Grid>
              </>
            )
          ) : (
            'Houve um problema com a data de publicação desta atividade.'
          )}
        </Grid>

        {checkLimitPerUser ? (
          productsWithStock.length > 0 ? (
            activity.participationStart && activity.participationEnd ? (
              moment().isBetween(
                activity.participationStart,
                activity.participationEnd
              ) ? (
                  <div>
                    <Alert
                      variant="outlined"
                      color='warning'
                      severity="info"
                      style={{color: SIGNED_OFF_NAV_TEXT_COLOR}}
                    >
                      Escolha os produtos, responda as perguntas e adicione ao carrinho de compras
                    </Alert>

                    <div style={{  marginBottom: 12, marginTop: 12 }}>
                      {questions[step]}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: 10,
                        backgroundColor: COMPANY_SIGNED_IN_BACKGROUND_COLOR,
                      }}
                    >
                      <Typography variant="body1">
                        {`${step + 1} de ${questions.length}`}
                      </Typography>
                    </div>
                    <div
                      className="row justify-content-center"
                      style={{ padding: '0px 15px 30px' }}
                    >
                      {step > 0 && (
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              color: COMPANY_COLORS.primary,
                              textDecoration: 'underline',
                              cursor: 'pointer',
                              textAlign: 'center',
                            }}
                            onClick={() => setStep((step) => step > 0 && step - 1)}
                          >
                            Anterior
                          </span>
                        </div>
                      )}
                      {step < questions.length - 1 ? (
                        <span
                          style={{
                            color: BUTTON_TEXT_COLOR,
                            backgroundColor: BUTTON_BACKGROUND_COLOR,
                            cursor: 'pointer',
                            role: 'button',
                            padding: '8px',
                            borderRadius: 8,
                            textAlign: 'center',
                            width: '100%',
                          }}
                          onClick={() => onClickNextStep(props.formValues)}
                        >
                          Próximo
                        </span>
                      ) : (
                        <ButtonPrimary
                          disabled={props.activities.buttonLoading}
                          progress={parseInt(props.general.submitProgress)}
                          style={{ display: 'flex', flex: 1, margin: 0 }}
                          type="submit"
                        >
                          {props.activities.buttonLoading ? 'Enviando' : 'Adicionar ao carrinho'}
                        </ButtonPrimary>
                      )}
                    </div>
                  </div>
              ) : null
            ) : null
          ) : null
        ) : null}
      </Form>
    </Grid>
  );
};

CartSurvey = reduxForm({
  form: 'shoppingForm',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(CartSurvey);

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    activity: state.activities.detail,
    activities: state.activities,
    questions: state.activities.detail.researches[0].questions,
    formValues: getFormValues('shoppingForm')(state),
    shopping: state.shopping,
    general: state.general,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ addProductToCart, submitSurvey }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CartSurvey);
